import { getItemTimeToDeliver, UiItemBase } from '@pwiz/entity/ts';
import { ItemTimeToDeliver } from '../type/item-time-to-deliver';
import { SettingsTimeToDeliver } from '@pwiz/entity/ts';

export function toItemTimeToDeliver<T extends UiItemBase>(
  item: T,
  settings: SettingsTimeToDeliver,
): ItemTimeToDeliver {
  return {
    timeToDeliver: getItemTimeToDeliver(item, settings),
  };
}
