export const environment = {
  production: false,
  fronteggUrl: 'https://pwiz-dev.frontegg.com',
  fronteggClientId: 'fae62677-193a-4f04-a06f-160ef3c47dfb',
  backendUrl: 'https://api.dev.pwiz.ai',
  auth0: {
    domain: 'pwiz.eu.auth0.com',
    clientId: '8dQyJxCdq75I2XiXugY08ks0acAeaZ8T',
    authorizationParams: {
      redirect_uri: 'https://app.dev.pwiz.ai/on-login',
    },
  },
};
