@if ($optionsWithoutPicklistValues().length) {
  <ng-container [formGroup]="form">
    <div class="fields">
      <span>Account: </span>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapName"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>First Won Date</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapFirstWonDate"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Renewal Date</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapRenewalDate"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
    </div>
    <div class="fields">
      <span>Revenue: </span>
      <mat-form-field>
        <mat-label>Annual </mat-label>
        <pw-basic-autocomplete
          [options]="$optionsWithoutPicklistValues()"
          [formControl]="form.controls.fieldMapArr"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Monthly</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapMrr"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
    </div>
    <div class="fields">
      <span>Users : </span>
      <mat-form-field>
        <mat-label>Owner</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapOwner"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Assigned Cs</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapAssignedCs"
          [options]="$optionsWithoutPicklistValues()"
        />
      </mat-form-field>
    </div>
    <div class="fields">
      <span>Account Status: </span>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <pw-basic-autocomplete
          [formControl]="form.controls.fieldMapAccountStatus"
          [options]="$optionsWithPicklistValues()"
        />
      </mat-form-field>
    </div>
    <div class="fields" [formGroup]="form.controls.valueMapAccountStatus">
      <span>Account Status Options:</span>
      @if ($accountStatsValue()) {
        @for (nameField of $accountStatsValue(); track nameField) {
          <mat-form-field>
            <mat-label>{{ nameField }}</mat-label>
            <mat-select
              [formControlName]="nameField"
              placeholder="Active Customer"
            >
              @for (TypeStatus of $listTypeAccountStatus(); track TypeStatus) {
                <mat-option [value]="TypeStatus">
                  {{ TypeStatus | pwEnumToReadableString }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      }
    </div>
  </ng-container>
} @else {
  <div class="fields">
    <span>Account: </span>
    <pw-rectangle-skeleton />
    <pw-rectangle-skeleton />
  </div>
  <div class="fields">
    <span>Revenu: </span>
    <pw-rectangle-skeleton />
    <pw-rectangle-skeleton />
    <pw-rectangle-skeleton />
  </div>
  <div class="fields">
    <span>Users : </span>
    <pw-rectangle-skeleton />
    <pw-rectangle-skeleton />
  </div>
  <div class="fields">
    <span>Account Status: </span>
    <pw-rectangle-skeleton />
  </div>
  <div class="fields">
    <span>Account Status Options: </span>
  </div>
}
