import { Route } from '@angular/router';

export const STRATEGY_ROUTES: Route[] = [
  {
    path: 'strategy',
    loadComponent: () =>
      import('./strategy-page-layout/strategy-page-layout.component'),
    loadChildren: () => [
      {
        path: 'goals',
        title: 'Business Goals',
        loadComponent: () =>
          import('./business-goal-page/business-goal-page.component'),
      },
      {
        path: 'key-results',
        title: 'Key Results',
        loadComponent: () =>
          import('./key-results-page/key-results-page.component'),
      },
      {
        path: 'strategic-initiatives',
        title: 'Strategic Initiatives',
        loadComponent: () =>
          import(
            './strategic-initiatives/strategic-initiatives.page.component'
          ),
      },
      {
        path: '**',
        redirectTo: 'strategic-initiatives',
        pathMatch: 'full',
      },
    ],
  },
];
