import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pw-sprint-icon',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./private/shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      --pw-icon-color: inherit;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <mask
        id="a"
        width="20"
        height="11"
        x="4"
        y="1"
        maskUnits="userSpaceOnUse"
        style="mask-type:luminance"
      >
        <path
          fill="var(--pw-background-color)"
          d="M4.88 1.926H24v9.93H4.88v-9.93Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="var(--pw-icon-color)"
          fill-rule="evenodd"
          d="M14.797 11.83s-1.095-6.164-3.588-7.356C8.716 3.28 4.783 5.777 6.897 9.99c-5.07-3.209.588-10.915 6.994-6.909 3.03 1.895 7.213 8.11 10.045 3.896 0 0 .053 3.191-2.364 3.47-2.418.278-4.018-1.084-5.038-1.084-1.02 0-1.737 2.465-1.737 2.465Z"
          clip-rule="evenodd"
        />
      </g>
      <mask
        id="b"
        width="9"
        height="5"
        x="15"
        y="6"
        maskUnits="userSpaceOnUse"
        style="mask-type:luminance"
      >
        <path
          fill="var(--pw-background-color)"
          d="M15.92 6.975H24v3.535h-8.08V6.975Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="var(--pw-icon-color)"
          fill-rule="evenodd"
          d="M20.147 8.021c1.37.658 2.682.604 3.79-1.043 0 0 .052 3.191-2.365 3.47-2.418.278-4.018-1.084-5.038-1.084-.186 0-.362.082-.525.216h-.001s1.817-2.12 4.14-1.559Z"
          clip-rule="evenodd"
        />
      </g>
      <path
        fill="var(--pw-icon-color)"
        fill-rule="evenodd"
        d="M12.548 6s-1.482 6.228 2.184 6.996c2.293.48 3.67.71 4.486 1.179 2.92 1.681-3.183 8.277-4.446 9.825 0 0 2.652-6.867 1.872-7.55-.56-.49-3.294.296-5.065-.74-3.117-1.822-.985-6.945.97-9.71Z"
        clip-rule="evenodd"
      />
      <mask
        id="c"
        width="13"
        height="11"
        x="0"
        y="13"
        maskUnits="userSpaceOnUse"
        style="mask-type:luminance"
      >
        <path
          fill="var(--pw-background-color)"
          d="M0 13.707h12.24v10.109H0V13.707Z"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill="var(--pw-icon-color)"
          fill-rule="evenodd"
          d="M9.63 13.803c-.844 1.809-2.49 5.084-3.803 6.157C3.976 21.47.011 23.816.011 23.816s7.062-1.51 9.29-3.499c1.198-1.069 2.233-2.437 2.934-3.474a4.02 4.02 0 0 1-.817-.343 3.42 3.42 0 0 1-1.474-1.583c-.16-.35-.26-.724-.313-1.114Z"
          clip-rule="evenodd"
        />
      </g>
      <mask
        id="d"
        width="11"
        height="11"
        x="0"
        y="13"
        maskUnits="userSpaceOnUse"
        style="mask-type:luminance"
      >
        <path
          fill="var(--pw-background-color)"
          d="M0 13.707h10.64v10.109H0V13.707Z"
        />
      </mask>
      <g mask="url(#d)">
        <path
          fill="var(--pw-icon-color)"
          fill-rule="evenodd"
          d="M9.63 13.803c-.844 1.809-2.49 5.084-3.803 6.157-1.85 1.51-5.816 3.856-5.816 3.856s8.658-2.901 10.547-7.982a3.331 3.331 0 0 1-.614-.917c-.16-.35-.26-.724-.313-1.114Z"
          clip-rule="evenodd"
        />
      </g>
      <path
        fill="var(--pw-icon-color)"
        fill-rule="evenodd"
        d="M18 0c1.104 0 2 1.119 2 2.5 0 1.38-.896 2.5-2 2.5-1.105 0-2-1.12-2-2.5 0-1.381.895-2.5 2-2.5Z"
        clip-rule="evenodd"
      />
      <path
        fill="var(--pw-icon-color)"
        fill-rule="evenodd"
        d="M18.63 0c1.006 0 1.88.625 2.323 1.543.03.178.047.362.047.55C21 3.698 19.823 5 18.37 5c-1.007 0-1.88-.626-2.323-1.543A3.215 3.215 0 0 1 16 2.906C16 1.301 17.177 0 18.63 0Z"
        clip-rule="evenodd"
      />
    </svg>
  `,
})
export class SprintIconComponent {}
