import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pw-item-priority-change-note',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <strong>Note:</strong>
    <ul>
      <li>
        Priority will remain permanent and will not be subjected to updates.
      </li>
      <li>Manual overrule will align Task Management System (jira).</li>
    </ul>
  `,
})
export class ItemPriorityChangeNoteComponent {}
