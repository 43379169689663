import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPriority } from '@pwiz/priority/ts';
import { MatButtonModule } from '@angular/material/button';
import {
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { ePriorityType } from '@pwiz/entity/ts';
import { ImpactOverviewComponent } from './impact-overview/impact-overview.component';
import { UrgencyOverviewComponent } from './urgency-overview/urgency-overview.component';
import { ConfidenceOverviewComponent } from './confidence-overview/confidence-overview.component';
import { EffortOverviewComponent } from './effort-overview/effort-overview.component';
import { ItemSummary } from '@pwiz/item/summary/ts';
import { PriorityOverviewEventsService } from './priority-overview-events.service';
import { FindUserPipe } from '@pwiz/user/ui';
import { PwUser } from '@pwiz/user/ts';

@Component({
  selector: 'pw-priority-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    PwDateTimePipe,
    PwEnumToReadableStringPipe,
    PwNullableValuePipe,
    PwLabelComponent,
    PwLabelHeaderComponent,
    FindUserPipe,
  ],
  templateUrl: './priority-overview.component.html',
  styleUrl: './priority-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PriorityOverviewEventsService],
})
export class PriorityOverviewComponent {
  #events = inject(PriorityOverviewEventsService);
  readonly componentMap = {
    [ePriorityType.impact]: ImpactOverviewComponent,
    [ePriorityType.urgency]: UrgencyOverviewComponent,
    [ePriorityType.confidence]: ConfidenceOverviewComponent,
    [ePriorityType.effort]: EffortOverviewComponent,
  };

  priority = input.required<UiPriority>();
  item = input.required<ItemSummary>();
  isReadOnly = input.required<boolean>();
  users = input.required<PwUser[] | null>();

  priorityClick = output<{ priority: UiPriority }>();
  $inputs = computed(() => ({
    priority: this.priority(),
    item: this.item(),
    isReadOnly: this.isReadOnly(),
    users: this.users(),
    updatePriority: () => this.onPriorityClick(),
  }));

  constructor() {
    this.#events.onPrioritize(() =>
      this.priorityClick.emit({ priority: this.priority() }),
    );
  }

  onPriorityClick() {
    this.priorityClick.emit({ priority: this.priority() });
  }
}
