import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ItemPriorityChangeService } from '@pwiz/item/ui-data';
import {
  DialogCloseDirective,
  DialogContentComponent,
  DialogFooterComponent,
  DialogFooterDirective,
  DialogHeaderComponent,
  DialogV3Component,
  PwDialogService,
  useCloseDialog,
  useDialogData,
} from '@pwiz/infra/dialog';
import { MatButton } from '@angular/material/button';
import {
  IsItemPriorityOverriddenPipe,
  ItemDisplayIdPipe,
  ItemPriorityChangeComponent,
  ItemPriorityChangeInfoComponent,
  ItemPriorityChangeNoteComponent,
  ItemPriorityChangePriorityOverviewComponent,
  ItemSystemPriorityPipe,
} from '@pwiz/item/ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { $useUserList } from '@pwiz/user/ui-data';
import {
  findProductPriority,
  groupPrioritiesByType,
  isPriorityRated,
  UiPriority,
} from '@pwiz/priority/ts';
import { ItemIdComponent } from '@pwiz/item-common/ui';
import { eItemPriority } from '@pwiz/entity/ts';
import { MatTooltip } from '@angular/material/tooltip';
import { LoaderProviderComponentComponent } from '@pwiz/infra/ui';
import { PriorityOverviewUpdatePriorityEvent } from '@pwiz/priority/ui';
import { usePrioritize } from '@pwiz/priority/feature';

@Component({
  selector: 'pw-item-priority-change-component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ItemPriorityChangeService],
  styleUrl: 'item-priority-change-dialog.component.scss',
  templateUrl: 'item-priority-change-dialog.component.html',
  imports: [
    DialogHeaderComponent,
    DialogContentComponent,
    DialogFooterComponent,
    MatButton,
    DialogCloseDirective,
    ItemDisplayIdPipe,
    ItemPriorityChangePriorityOverviewComponent,
    ItemPriorityChangeComponent,
    ItemPriorityChangeInfoComponent,
    ItemIdComponent,
    DialogFooterDirective,
    DialogV3Component,
    ItemPriorityChangeNoteComponent,
    MatTooltip,
    ItemSystemPriorityPipe,
    IsItemPriorityOverriddenPipe,
    LoaderProviderComponentComponent,
  ],
})
class ItemPriorityChangeDialogComponent {
  #prioritize = usePrioritize();
  #itemPriorityChangeService = inject(ItemPriorityChangeService);
  $item = $useItemPriorityChangeItem();
  $itemPriority = signal<eItemPriority | null>(null);
  $users = $useUserList();
  $productPriorityForm = computed(() => {
    const item = this.$item().data;
    if (!item) {
      return null;
    }
    return findProductPriority(item.priorities, item);
  });

  $isProductPriorityFormRated = computed(() => {
    const productPriority = this.$productPriorityForm();
    if (!productPriority) {
      return false;
    }
    return isPriorityRated(productPriority);
  });
  closeDialog = useCloseDialog();

  constructor() {
    effect(() => this.$itemPriority.set(this.$item().data?.priority || null), {
      allowSignalWrites: true,
    });
  }

  onSave() {
    const item = this.$item().data;
    const priority = this.$itemPriority();
    if (!item || !priority) {
      return;
    }
    this.closeDialog.lazyFinish(
      this.#itemPriorityChangeService.changeItemPriority(item, priority),
    );
  }

  rollback() {
    const item = this.$item().data;
    const priority = this.$itemPriority();
    if (!item || !priority) {
      return;
    }
    this.closeDialog.lazyFinish(this.#itemPriorityChangeService.rollback(item));
  }

  onUpdatePriority({ priority }: PriorityOverviewUpdatePriorityEvent) {
    this.#scorePriority(priority);
  }

  #scorePriority(priority: UiPriority) {
    this.closeDialog.cancel();
    this.#prioritize.open(priority);
  }

  onPmRate() {
    const priorities = untracked(this.$item).data?.priorities;
    if (!priorities) {
      return;
    }
    const { confidence, urgency } = groupPrioritiesByType(priorities);
    const pmPriority = confidence || urgency;
    if (!pmPriority || !pmPriority.length) {
      throw new Error('Pm priority is missing');
    }
    this.#scorePriority(pmPriority[0]);
  }
}

export function $useItemPriorityChangeItem() {
  const itemPriorityChangeService = inject(ItemPriorityChangeService);
  const dialogData = useItemPriorityChangeDialogData();
  return toSignal(itemPriorityChangeService.getItem$(dialogData.itemId), {
    requireSync: true,
  });
}

export function useItemPriorityDialog() {
  const dialog = inject(PwDialogService);
  return (itemId: string) =>
    dialog.openV3(ItemPriorityChangeDialogComponent, {
      data: { itemId },
    }).lazyFinish$;
}
export function useItemPriorityChangeDialogData() {
  return useDialogData<{ itemId: string }>();
}
