import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  DialogContentComponent,
  DialogFinishDirective,
  DialogFooterComponent,
  DialogFooterDirective,
  DialogHeaderComponent,
  DialogV2Component,
  PwDialogService,
} from '@pwiz/infra/dialog';
import {
  SprintItemSelectTableComponent,
  SprintNamePipe,
} from '@pwiz/sprint/ui';
import {
  getSprintItemConnectionList,
  SprintEditableTableItem,
  sprintItemDetailsToSprintEditableItem,
} from '@pwiz/sprint/ts';
import { CurrentSprintService } from '@pwiz/sprint/ui-data';
import { MatButton } from '@angular/material/button';
import { eSprintItemStatus } from '@pwiz/entity/ts';
import { UiTableDataProviderComponent } from '@pwiz/infra/table';

@Component({
  selector: 'pw-sprint-edit-remove-item-dialog-component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogContentComponent,
    DialogHeaderComponent,
    DialogV2Component,
    DialogFooterComponent,
    UiTableDataProviderComponent,
    SprintItemSelectTableComponent,
    MatButton,
    SprintNamePipe,
    DialogFooterDirective,
    DialogFinishDirective,
  ],
  host: {
    class: 'pw-scroll-container',
  },
  template: `
    <pw-dialog-v2 size="l">
      <pw-dialog-header>Remove Items from sprint</pw-dialog-header>
      <pw-dialog-content noTopMargin style="height: var(--pw-distance-80)">
        <pw-ui-table-data-provider
          [rowData]="$itemList()"
          fetchStatus="success"
        >
          <pw-sprint-item-select-table
            canRemove
            hideAction
            withSelection
            (selectedRows)="onSelectedRows($event)"
          />
        </pw-ui-table-data-provider>
      </pw-dialog-content>
      <pw-dialog-footer>
        <button
          [pwDialogFinish]="{ removedItems: $removedItems() }"
          ok
          pwDialogFooter="end"
          mat-raised-button
          color="primary"
        >
          Remove {{ $removedItems().length }} items from
          {{ $sprint().data | pwSprintName }}
        </button>
      </pw-dialog-footer>
    </pw-dialog-v2>
  `,
})
class SprintEditRemoveItemDialogComponentComponent {
  #currentSprintService = inject(CurrentSprintService);

  $removedItems = signal<SprintEditableTableItem[]>([]);
  $sprint = this.#currentSprintService.$sprint;
  $itemList = computed(() => {
    const sprint = this.$sprint().data;
    if (!sprint) {
      return [];
    }
    const itemList = getSprintItemConnectionList(sprint.itemList, null, [
      eSprintItemStatus.manually,
      eSprintItemStatus.omitted,
    ]);
    return itemList.map(sprintItemDetailsToSprintEditableItem);
  });

  onSelectedRows(selectedRows: SprintEditableTableItem[]) {
    this.$removedItems.set(selectedRows);
  }
}

export function useSprintRemoveItemDialog() {
  const dialogService = inject(PwDialogService);
  return () =>
    dialogService.openV3<never, { removedItems: SprintEditableTableItem[] }>(
      SprintEditRemoveItemDialogComponentComponent,
      {},
    ).finish$;
}
