import { computed, inject, Injectable } from '@angular/core';
import {
  useCurrentRoute,
  useMappedQueryParams,
} from '@pwiz/infra/query-params/data';
import { SprintView, SprintPageFilter, SprintType } from '@pwiz/sprint/ts';
import {
  queryParamsToString,
  queryParamToArray,
  ToQueryParam,
} from '@pwiz/infra/ts';
import { $useFilterUserPref } from '@pwiz/user/ui-data';
import { SprintService } from './sprint.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { of, startWith, switchMap } from 'rxjs';
import { PwCacheData } from '@pwiz/infra/cache/ts';

@Injectable()
export class SprintPageService {
  #page = $useCurrentPage();
  #sprintService = inject(SprintService);
  $isAutoSprintPage = computed(() => this.#page() === 'auto');
  filter = useMappedQueryParams<SprintPageFilter>(
    $useFilterUserPref()()?.myTeams[0]
      ? { teams: $useFilterUserPref()()!.myTeams[0] }
      : {},
    getFromQueryParams,
  );

  $getSprints() {
    const filter$ = toObservable(this.filter.$filters);
    return filter$.pipe(
      startWith({ teams: null, status: null }),
      switchMap(({ teams }) => {
        if (!teams || !teams?.length) {
          return of<PwCacheData<SprintView[]>>({ data: [], status: 'none' });
        }
        return this.#sprintService.getSprintList({
          teamList: [teams],
          isFeatureSprints: this.$isAutoSprintPage(),
        });
      }),
    );
  }
}

function $useCurrentPage() {
  const url = useCurrentRoute();

  return computed(() => url().at(-1)?.path as SprintType | undefined);
}

function getFromQueryParams(
  params: ToQueryParam<SprintPageFilter>,
): SprintPageFilter {
  return {
    teams: queryParamsToString(params.teams),
    status: queryParamToArray(params?.status),
  };
}
