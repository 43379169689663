import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtils } from '@pwiz/infra/ts';

@Pipe({
  name: 'pwPassedTime',
  standalone: true,
})
export class PwPassedTimePipe implements PipeTransform {
  transform(hoursPassed?: number | null): string {
    if (!hoursPassed) {
      return '-';
    }
    const { years, months, days, hours } = TimeUtils.passedTime(
      TimeUtils.converter.hoursToSeconds(hoursPassed),
    );
    console.log({ years, months, days, hours });
    return (
      print(years, 'Years') +
      print(months, 'Month') +
      print(hours ? days + 1 : days, 'Day', true)
    );
  }
}

function print(amount: number, label: string, isLast = false) {
  if (amount) {
    return `${amount} ${pluralize(amount, label)}${isLast ? '' : ', '}`;
  }
  return '';
}
function pluralize(n: number, singular: string): string {
  return n === 1 ? singular : `${singular}s`;
}
