import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationSidebarTitleComponent } from './components/title/navigation-sidebar-title.component';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import { RoadmapIconComponent } from '@pwiz/infra/icons/ui';

@Component({
  selector: 'pw-strategy-nav',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NavigationSidebarTitleComponent,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    SidebarLinkComponent,
    RoadmapIconComponent,
  ],
  styleUrl: 'navigation-sidebar.component.scss',
  template: `
    <pw-navigation-sidebar-title>Strategy</pw-navigation-sidebar-title>
    <ul>
      <li>
        <a routerLink="strategy" routerLinkActive="active">
          <pw-sidebar-link pageName="Business goals">
            <mat-icon slot="icon">location_on</mat-icon>
          </pw-sidebar-link>
        </a>
      </li>
      <li>
        <a routerLink="roadmap" routerLinkActive="active">
          <pw-sidebar-link pageName="Roadmap">
            <pw-roadmap-icon slot="icon" />
          </pw-sidebar-link>
        </a>
      </li>
    </ul>
  `,
})
export class StrategyNavComponent {}
