<mat-form-field class="example-full-width" subscriptSizing="dynamic">
  <input
    type="text"
    placeholder="Search by item ID, item title or item link"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    #inputElement
    (focusout)="onFocusOut()"
  />
  <div matSuffix class="pw-flex">
    <div class="key">
      <kbd>/</kbd>
    </div>
    <button
      class="clear"
      mat-icon-button
      (click)="onClear()"
      *ngIf="$isActive()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    hideSingleSelectionIndicator="true"
    panelWidth="25rem"
    (optionSelected)="onOptionSelected($event)"
  >
    @if ($filteredItems(); as items) {
<!--      Can't switch to the status-switch-case component because then the autocomplete selection isn't working well -->
      @switch (items.status) {
        @case ('loading') {
          <mat-option [disabled]="true">
            <pw-items-search-row [loading]="true" />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-items-search-row [loading]="true" />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-items-search-row [loading]="true" />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-items-search-row [loading]="true" />
          </mat-option>
        }
        @case ('success') {
          @for (
              issue of items.data || [];
            track { id: issue.id, updated: issue.updateDate }
            ) {
            <mat-option [value]="issue">
              <pw-items-search-row [issue]="issue"/>
            </mat-option>
          }
        }
        @case ('error') {
          <mat-option>error</mat-option>
        }
      }
    } @else if ($tooShortSearch()) {
      <mat-option disabled>
        Search should be longer than 3 characters</mat-option
      >
    }
  </mat-autocomplete>
</mat-form-field>
