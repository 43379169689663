<pw-sprint-edit-items-layout
  (addItem)="onAddItem()"
  (removeItem)="onRemoveItem()"
>
  <pw-ui-table-data-provider
    [rowData]="$manualItems().data || []"
    [fetchStatus]="$manualItems().status"
    slot="left"
  >
    <pw-sprint-item-select-table
      (removeItem)="onManualItemsChange($event)"
      hideTotalEffort
      canRemove
    />
  </pw-ui-table-data-provider>

  <pw-ui-table-data-provider
    [rowData]="$changedItems().data || []"
    [fetchStatus]="$changedItems().status"
    slot="right"
  >
    <pw-sprint-item-select-table
      hideTotalEffort
      canRemove
      (removeItem)="onRemoveRecommendation($event)"
    />
  </pw-ui-table-data-provider>
</pw-sprint-edit-items-layout>
