import { Breakpoints } from '@angular/cdk/layout';
import { PwDeviceSize } from '../type';

export const SCREEN_BREAK_POINT_MAP = new Map<string, PwDeviceSize>([
  [Breakpoints.XSmall, 's'],
  [Breakpoints.Small, 's'],
  [Breakpoints.Medium, 'm'],
  [Breakpoints.Large, 'l'],
  [Breakpoints.XLarge, 'xl'],
]);
