<pw-table
  disableSort
  [rowSelectionType]="withSelection() ? 'multiple' : 'none'"
  [displayColumns]="$displayColumns()"
  (rowsSelected)="onSelectedRows($event)"
>
  <pw-table-col headerName="Action" colId="action" widthDistance="22">
    <pw-sprint-item-select-table-action *pwTableCell="let row" [item]="row" />
  </pw-table-col>

  <pw-table-col headerName="ID" colId="itemId" widthDistance="35">
    <div class="pw-flex pw-gap pw-center-vr" *pwTableCell="let row">
      <pw-item-id [item]="row" showType mode="itemId" />
    </div>
  </pw-table-col>

  <pw-table-col headerName="Summary" colId="itemSummary">
    <pw-item-id
      *pwTableCell="let row"
      [item]="row"
      [showType]="false"
      disableLink
      mode="title"
    />
  </pw-table-col>

  <pw-table-col
    headerName="Effort in sprint"
    colId="sprintEffort"
    widthDistance="32"
  >
    <pw-sprint-vs-total
      *pwTableCell="let row"
      [inSprint]="row.sprintEffort"
      [remaining]="row.effortEstimation || row.systemEffort"
      [total]="row.effortEstimation || row.maxEffortEstimation"
      [done]="0"
      hidePercentCompleted
    />
  </pw-table-col>

  <pw-table-col headerName="Total effort" colId="effor" widthDistance="32">
    <ng-container *pwTableCell="let row">
      {{ row.effortEstimation || row.maxEffortEstimation | pwFixed: 1 }}
    </ng-container>
  </pw-table-col>

  <pw-table-col
    headerName="Priority"
    colId="priority"
    widthDistance="24"
    noPadding
  >
    <pw-item-priority *pwTableCell="let row" [item]="row" />
  </pw-table-col>

  <pw-table-col headerName="" colId="remove" widthDistance="12" noPadding>
    <div *pwTableCell="let sprintItem">
      <button mat-icon-button (click)="onRemove(sprintItem)" [disabled]="sprintItem.action === 'change'">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </pw-table-col>
</pw-table>
