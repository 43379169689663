import { PriorityUpdateEventsAdapter, UiPriority } from '@pwiz/priority/ts';
import { inject, Injectable, Provider } from '@angular/core';
import { ItemService } from '@pwiz/item/ui-data';

@Injectable()
class OnPriorityUpdateAdapter implements PriorityUpdateEventsAdapter {
  #itemService = inject(ItemService);
  onPriorityUpdated(priority: UiPriority) {
    this.#itemService.priorityFormChanged(priority);
  }
}
export const PriorityUpdateAdapter: Provider = {
  provide: PriorityUpdateEventsAdapter,
  useClass: OnPriorityUpdateAdapter,
};
