import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  Renderer2,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter, fromEvent, Observable, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-see-more',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './see-more.component.html',
  styleUrl: './see-more.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeeMoreComponent implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  $expanded = signal(false);
  $height = input.required<string>({ alias: 'height' });
  $visibleHeight = input<number>(96, { alias: 'visibleHeight' });
  $maxHeight = input<string>('1000px', { alias: 'maxHeight' });
  $elementHeight = signal(0);
  $showMore = computed(() => {
    const elementHeight = this.$elementHeight();
    const visibleHeight = this.$visibleHeight();
    return elementHeight > visibleHeight;
  });

  render = inject(Renderer2);
  transitionEnd$: Observable<Event> | null = null;

  @ViewChild('content')
  content!: ElementRef<HTMLDivElement>;

  @ViewChild('contentContainer')
  contentContainer!: ElementRef<HTMLDivElement>;

  constructor() {
    effect(() => {
      const expanded = this.$expanded();
      const height = this.$height() || '100px';
      if (!expanded) {
        this.render.setStyle(
          this.contentContainer.nativeElement,
          'max-height',
          height,
        );
      } else {
        this.render.setStyle(
          this.contentContainer.nativeElement,
          'max-height',
          this.$maxHeight(),
        );
      }
      this.#listenToTransitionEnd();
    });
  }

  ngAfterViewInit() {
    this.transitionEnd$ = fromEvent(
      this.contentContainer.nativeElement,
      'transitionend',
    ).pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((event) => event.target === this.contentContainer.nativeElement),
    );

    const resize$ = new ResizeObserver(([entry]) => {
      this.$elementHeight.set(entry.contentRect.height);
    });
    resize$.observe(this.content.nativeElement);
  }

  #listenToTransitionEnd() {
    this.transitionEnd$?.pipe(take(1)).subscribe(() => {
      const expanded = this.$expanded();
      if (!expanded) {
        this.render.removeClass(
          this.contentContainer.nativeElement,
          'expanded',
        );
      } else {
        this.render.addClass(this.contentContainer.nativeElement, 'expanded');
      }
    });
  }

  toggleExpand() {
    this.$expanded.update((prev) => !prev);
  }
}
