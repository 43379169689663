import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import {
  TableCellDirective,
  TableColComponent,
  TableComponent,
  toggleColumns,
} from '@pwiz/infra/table';
import { ItemIdComponent } from '@pwiz/item-common/ui';
import { FixedPipe, PwNullableValuePipe } from '@pwiz/infra/ui';
import { MatIconButton } from '@angular/material/button';
import {
  SprintEditableTableItem,
  SprintItemSearchRemoveEvent,
} from '@pwiz/sprint/ts';
import { MatIcon } from '@angular/material/icon';
import { AddSprintIconComponent } from '@pwiz/infra/icons/ui';
import { SprintVsTotalComponent } from '../../table/sprint-item-table/component/sprint-vs-total.component';
import { NgClass } from '@angular/common';
import { ItemPriorityComponent } from '@pwiz/item/ui';
import { SprintItemSelectTableActionComponent } from './sprint-item-select-table-action.component';

const TABLE_COL_LIST = [
  'action',
  'priority',
  'itemId',
  'itemSummary',
  'sprintEffort',
  'effort',
  'remove',
] as const;
type TableColId = (typeof TABLE_COL_LIST)[number];

@Component({
  selector: 'pw-sprint-item-select-table',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'sprint-item-select-table.component.html',
  host: {
    class: 'pw-scroll-container pw-scroll',
  },
  imports: [
    TableComponent,
    TableColComponent,
    ItemIdComponent,
    TableCellDirective,
    FixedPipe,
    MatIconButton,
    MatIcon,
    AddSprintIconComponent,
    PwNullableValuePipe,
    SprintVsTotalComponent,
    NgClass,
    ItemPriorityComponent,
    SprintItemSelectTableActionComponent,
  ],
})
export class SprintItemSelectTableComponent {
  canRemove = input(false, { transform: booleanAttribute });
  hideSprintEffort = input(false, { transform: booleanAttribute });
  hideTotalEffort = input(false, { transform: booleanAttribute });
  hideAction = input(false, { transform: booleanAttribute });
  $displayColumns = computed(() =>
    toggleColumns<TableColId>([...TABLE_COL_LIST], {
      remove: this.canRemove(),
      action: !this.hideAction(),
      sprintEffort: !this.hideSprintEffort(),
      effort: !this.hideTotalEffort(),
    }),
  );
  withSelection = input(false, { transform: booleanAttribute });
  removeItem = output<SprintItemSearchRemoveEvent>();
  selectedRows = output<SprintEditableTableItem[]>();

  onRemove(item: SprintEditableTableItem) {
    this.removeItem.emit({ item });
  }

  onSelectedRows(itemList: SprintEditableTableItem[]) {
    this.selectedRows.emit(itemList);
  }
}
