import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { usePwToaster } from '@pwiz/infra/dialog';
import { SlackConnectComponent } from '@pwiz/chat/ui';
import { SlackService } from '@pwiz/chat/ui-data';

@Component({
  selector: 'pw-slack-auth',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <pw-slack-connect (connect)="connectSlack()" />`,
  imports: [SlackConnectComponent],
})
export class SlackAuthComponent {
  #slackService = inject(SlackService);
  #toaster = usePwToaster();

  connectSlack() {
    this.#slackService.connect().subscribe({
      next: ({ url }) => (window.location.href = url),
      error: () => this.#toaster.error(`Something went wrong`),
    });
  }
}
