import { Pipe, PipeTransform } from '@angular/core';
import { ITeam, WithBoard } from '@pwiz/entity/ts';
import { filterTeamsByBoards } from '@pwiz/team/ts';

@Pipe({
  name: 'pwFilterTeamByBoards',
  standalone: true,
})
export class FilterTeamByBoardsPipe implements PipeTransform {
  transform(
    teams: WithBoard<ITeam>[],
    selectedBoards: string | string[],
  ): WithBoard<ITeam>[] {
    return filterTeamsByBoards(
      teams,
      Array.isArray(selectedBoards) ? selectedBoards : [selectedBoards],
    );
  }
}
