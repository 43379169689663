import { ItemAffectedCustomersCount, ItemPriorities } from '@pwiz/item/ts';
import { ePriorityType, findPriorityByType } from '@pwiz/entity/ts';
import { NumUtils } from '@pwiz/infra/ts';

export function toItemAffectedCustomersCount<T extends ItemPriorities>(
  itemPriorities: T,
): ItemAffectedCustomersCount {
  const length = findPriorityByType(
    itemPriorities.priorities,
    ePriorityType.impact,
  )?.accountPriority?.length;
  return {
    affectedCustomersCount: NumUtils.isNull(length) ? null : length,
  };
}
