import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwFormComponentV2, ToFormGroup } from '@pwiz/infra/form';
import { SettingsEffortFormValue } from '@pwiz/settings/ts';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

type IForm = ToFormGroup<SettingsEffortFormValue>;

@Component({
  selector: 'pw-story-point-mapping',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatRadioModule,
  ],
  templateUrl: './story-point-mapping.component.html',
  styleUrls: ['./story-point-mapping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PwFormComponentV2, useExisting: StoryPointMappingComponent },
  ],
})
export class StoryPointMappingComponent implements PwFormComponentV2<IForm> {
  #cd = inject(ChangeDetectorRef);
  form = this.#initForm();
  name = 'effortMap';
  @Input()
  set value(value: SettingsEffortFormValue | null | undefined) {
    if (value) {
      this.form.setValue(value);
    } else {
      this.form.reset();
    }
  }
  runCD(): void {
    this.#cd.detectChanges();
  }

  validate(): void {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.runCD();
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<IForm>({
      unit: fb.control('days', [Validators.required]),
    });
  }
}
