import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'pw-pwiz-logo',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './pwiz-logo.component.html',
  styleUrls: ['./pwiz-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwizLogoComponent {}
