import { Pipe, PipeTransform } from '@angular/core';
import { getPriorityDisplayLevel, UiPriority } from '@pwiz/priority/ts';
import { toReadableString } from '@pwiz/infra/ts';

@Pipe({
  name: 'priorityScore',
  standalone: true,
  pure: true,
})
export class PriorityScorePipe implements PipeTransform {
  transform(priority: UiPriority): string {
    return toReadableString(getPriorityDisplayLevel(priority));
  }
}
