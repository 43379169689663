<ng-container [formGroup]="form">

  <mat-form-field class="sprint-duration">
    <mat-label>Sprint Duration</mat-label>
    <input type="number" matInput [formControl]="form.controls.duration" />
    <mat-error>Must be grater than 1 day</mat-error>
    <span matTextSuffix>Days</span>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Start from</mat-label>
    <input
      matInput
      [min]="tomorrow"
      [matDatepicker]="picker"
      [formControl]="form.controls.startDate"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker" />
    <mat-datepicker #picker />
    <mat-error>
      @if (form.controls.startDate.errors; as errors) {
        @if (errors['required']) {
          Start date is required
        } @else {
          Start date has to come after today
        }
      }
    </mat-error>
  </mat-form-field>
</ng-container>
