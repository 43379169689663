import { Route } from '@angular/router';
import { importEmptyLayout } from '@pwiz/infra/ui';

export const STRATEGY_DRAWER_ROUTES: Route = {
  path: 'strategy',
  loadComponent: importEmptyLayout,
  children: [
    {
      path: 'business-goal/:businessGoalId',
      loadComponent: () => import('./sidebar/business-goal-drawer.component'),
    },
  ],
};
