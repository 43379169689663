import { Route } from '@angular/router';
import { importEmptyLayout } from '@pwiz/infra/ui';

export const TICKET_DRAWER_ROUTE: Route = {
  path: 'ticket',
  loadComponent: importEmptyLayout,
  children: [
    {
      path: ':ticketId',
      loadComponent: () =>
        import('./ticket-summary/ticket-summary-drawer.component'),
    },
  ],
};
