import { inject, Injectable } from '@angular/core';
import {
  combineLatest,
  filter,
  map,
  NEVER,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { ItemService } from '@pwiz/item/ui-data';
import { toItemSummary } from '@pwiz/item/summary/ts';
import { PrioritiesService } from '@pwiz/priority/ui-data';
import { SettingsService } from '@pwiz/settings/ui-data';
import { BaseEntityUtils } from '@pwiz/entity/ts';
import { BoardsService } from '@pwiz/board/ui-data';
import { toPriorityDisplayType } from '@pwiz/priority/ts';
import { getItemBoard, UiItemWithPriorities } from '@pwiz/item/ts';
import { PwCacheEntity } from '@pwiz/infra/cache/ts';
import { SprintService } from '@pwiz/sprint/ui-data';
import { ItemSprintSchedule } from '@pwiz/sprint/ts';
import { ItemSummaryProvider } from '@pwiz/item-common/ui-data';

@Injectable({
  providedIn: 'root',
})
export class ItemSummaryService extends ItemSummaryProvider {
  #boardService = inject(BoardsService);
  #itemService = inject(ItemService);
  #priorityService = inject(PrioritiesService);
  #settingsService = inject(SettingsService);
  #sprintService = inject(SprintService);
  #cache = new PwCacheEntity<UiItemWithPriorities>(null, of(null));
  #itemScheduleCache = new PwCacheEntity<ItemSprintSchedule>(null, NEVER);

  constructor() {
    super();
    this.#itemService.itemChange$().subscribe(() => this.reFetchItem());
  }

  #getItem(itemId: string) {
    this.#cache.setDatasource(itemId, this.#getFullItem$(itemId));
    return this.#cache.getV2$();
  }

  #getFullItem$(itemId: string) {
    return this.#itemService.getItemSummary(itemId).pipe(
      tap((item) =>
        this.#priorityService.updateCacheWith(
          item.priorities.map((p) => ({
            ...p,
            displayType: toPriorityDisplayType(p.type),
            item,
          })),
        ),
      ),
    );
  }

  getItemSummary$(itemId: string) {
    return combineLatest([
      this.#boardService.getBoards$(),
      this.#getItem(itemId),
      this.#settingsService.getSettings$().pipe(filter(Boolean)),
    ]).pipe(
      map(([{ data: boards }, item, settings]) =>
        item.status === 'success' && item.data
          ? toItemSummary(
              item.data,
              settings,
              BaseEntityUtils.findEntityById(
                boards || [],
                getItemBoard(item.data)?.id || '',
              ),
            )
          : null,
      ),
    );
  }

  getItemSchedule(itemId: string) {
    this.#itemScheduleCache.setDatasource(
      itemId,
      timer(1_000).pipe(
        switchMap(() => this.#sprintService.getItemSchedule(itemId)),
      ),
    );
    return this.#itemScheduleCache.getV2$();
  }

  reFetchItem() {
    this.#cache.reFetch();
    this.#itemScheduleCache.reFetch();
  }

  getItemPriorityChangeAuditEvent$(itemId: string) {
    return this.#itemService.getItemManalPriorityChangeAudit(itemId);
  }
}
