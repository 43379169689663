import { Pipe, PipeTransform } from '@angular/core';
import { IItem } from '@pwiz/entity/ts';
import { isItemManualPriorityOverridden } from '@pwiz/item/ts';

@Pipe({
  name: 'pwIsItemPriorityOverridden',
  standalone: true,
})
export class IsItemPriorityOverriddenPipe implements PipeTransform {
  transform(value: IItem) {
    return isItemManualPriorityOverridden(value);
  }
}
