import { UiItemWithPriorities } from '@pwiz/item/ts';
import { ItemWorkInProgress } from '../type';
import { eItemStatus, getItemTicketBase } from '@pwiz/entity/ts';
import { TimeUtils } from '@pwiz/infra/ts';

export function toItemWorkInProgress(
  item: UiItemWithPriorities,
): ItemWorkInProgress {
  const { workStartDate } = getItemTicketBase(item);
  if (item.status === eItemStatus.inProgress) {
    return {
      isInProgress: true,
      timeInProgress: !workStartDate
        ? 0
        : Math.floor(
            TimeUtils.converter.mileSecondsToHours(
              Date.now() - workStartDate.valueOf(),
            ),
          ),
    };
  }
  return {
    isInProgress: false,
    timeInProgress: null,
  };
}
