import {
  getItemKeyResultList,
  getItemSettings,
  toItemEffort,
  toItemEffortCost,
  toItemImpact,
  toItemMrr,
  toItemWithActualMetrics,
  UiItemWithPriorities,
} from '@pwiz/item/ts';
import { ItemSummary } from '../type';
import { IBoard, ISettings } from '@pwiz/entity/ts';
import { toItemTimeToDeliver } from './to-item-time-to-deliver';
import { toItemSla } from './to-item-sla';
import { toItemAffectedCustomersCount } from './to-item-affected-customers-count';
import { toItemWorkInProgress } from './to-item-work-in-progress';

export function toItemSummary(
  item: UiItemWithPriorities,
  currentSettings: ISettings,
  board: IBoard | null,
): ItemSummary {
  const settings = getItemSettings(item, currentSettings);
  const itemActualMetrics = toItemWithActualMetrics(
    item,
    settings.averageEngineerCost,
  );
  const itemEffort = toItemEffort(item, settings);
  const keyResultList = getItemKeyResultList(item);
  return {
    ...itemActualMetrics,
    ...toItemTimeToDeliver(item, settings),
    ...toItemSla(item, settings),
    ...toItemAffectedCustomersCount(item),
    ...toItemWorkInProgress(item),
    ...toItemEffortCost(itemEffort, settings),
    ...toItemMrr(itemActualMetrics),
    ...toItemImpact(item),
    ...itemEffort,
    boardName: board?.name || null,
    keyResultList,
    businessGoal: keyResultList?.[0]?.businessGoal || null,
  };
}
