import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import {
  RoadmapIconComponent,
  SprintIconComponent,
} from '@pwiz/infra/icons/ui';
import { NavigationSidebarTitleComponent } from './components/title/navigation-sidebar-title.component';

@Component({
  selector: 'pw-pm-nav',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: `navigation-sidebar.component.scss`,
  imports: [
    SidebarLinkComponent,
    RouterLink,
    RouterLinkActive,
    MatIcon,
    SprintIconComponent,
    RoadmapIconComponent,
    NavigationSidebarTitleComponent,
  ],
  template: `
    <pw-navigation-sidebar-title> Development </pw-navigation-sidebar-title>
    <ul>
      <li>
        <a routerLink="items" routerLinkActive="active">
          <pw-sidebar-link pageName="Live Backlog">
            <i slot="icon" class="tim-icons icon-atom"></i>
          </pw-sidebar-link>
        </a>
      </li>
      <li>
        <a routerLink="sprint" routerLinkActive="active">
          <pw-sidebar-link pageName="Sprints">
            <pw-sprint-icon slot="icon" />
          </pw-sidebar-link>
        </a>
      </li>
      <li>
        <a routerLink="priorities/new" routerLinkActive="active">
          <pw-sidebar-link pageName="New Priorities">
            <i slot="icon" class="tim-icons icon-molecule-40"></i>
            <span class="pw-badge">{{ newPrioritiesCount() }}</span>
          </pw-sidebar-link>
        </a>
      </li>
    </ul>
  `,
})
export class PmNavComponent {
  newPrioritiesCount = input.required<number | null>();
}
