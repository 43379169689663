import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPageNameComponent } from '@pwiz/layout/feature';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'pw-slack',
  standalone: true,
  imports: [CommonModule, HeaderPageNameComponent, RouterOutlet],
  host: { class: 'pw-container' },
  template: `
    <pw-header-page-name tabTitle="Settings - Slack">
      Settings / Slack
    </pw-header-page-name>
    <router-outlet />
  `,
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlackPageComponent {}

export default SlackPageComponent;
