import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pw-roadmap-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg
      width="20"
      height="20"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.599 149.573C73.3802 149.573 72.1615 149.104 71.2344 148.177L58.5365 135.458C56.6771 133.599 56.6771 130.583 58.5365 128.719L71.2344 116.005C73.0937 114.141 76.1094 114.141 77.9687 116.005C79.8281 117.865 79.8281 120.885 77.9687 122.745L68.6354 132.089L77.9687 141.432C79.8281 143.297 79.8281 146.313 77.9687 148.177C77.0365 149.104 75.8177 149.573 74.599 149.573Z"
        fill="white"
      />
      <mask
        id="mask0_204_22"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="146"
        y="107"
        width="54"
        height="88"
      >
        <path d="M146 107H200V194.125H146V107Z" fill="white" />
      </mask>
      <g mask="url(#mask0_204_22)">
        <path
          d="M199.677 143.026C200.245 144.484 200.057 146.141 199.167 147.474C198.276 148.807 196.818 149.573 195.229 149.573H155.552V189.302C155.552 191.911 153.391 194.073 150.786 194.073C148.187 194.073 146.026 191.911 146.026 189.302V113.016C146.026 110.411 148.187 108.25 150.786 108.25H195.229C196.818 108.25 198.276 109.01 199.167 110.349C200.057 111.682 200.245 113.333 199.677 114.797L194.026 128.911L199.677 143.026Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_204_22"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="61"
        height="81"
      >
        <path d="M0 0.125H60.6667V80.3333H0V0.125Z" fill="white" />
      </mask>
      <g mask="url(#mask1_204_22)">
        <path
          d="M30.1563 0.177094C13.5208 0.177094 0 13.7188 0 30.375C0 57.9011 26.1563 77.8646 27.3021 78.6875C28.125 79.3229 29.1406 79.6406 30.1563 79.6406C31.1719 79.6406 32.1875 79.3229 33.0156 78.6875C34.1563 77.8646 60.3177 57.9011 60.3177 30.375C60.3177 13.7188 46.7917 0.177094 30.1563 0.177094ZM30.1563 36.7344C26.6667 36.7344 23.8073 33.8698 23.8073 30.375C23.8073 26.8802 26.6667 24.0156 30.1563 24.0156C33.651 24.0156 36.5052 26.8802 36.5052 30.375C36.5052 33.8698 33.651 36.7344 30.1563 36.7344Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_204_22"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="69"
        width="137"
        height="126"
      >
        <path d="M0 69.6667H136.667V194.125H0V69.6667Z" fill="white" />
      </mask>
      <g mask="url(#mask2_204_22)">
        <path
          d="M131.74 194.073H33.3333C24.3698 194.073 15.9948 190.589 9.75521 184.266C3.47917 178.052 0 169.672 0 160.698C0 142.292 14.9531 127.323 33.3333 127.323H96.8229C103.219 127.323 109.188 124.844 113.625 120.349C118.156 115.865 120.63 109.891 120.63 103.484C120.63 90.3385 109.948 79.6406 96.8229 79.6406H57.1406C54.5104 79.6406 52.3802 77.5052 52.3802 74.875C52.3802 72.2448 54.5104 70.1094 57.1406 70.1094H96.8229C115.198 70.1094 130.156 85.0781 130.156 103.484C130.156 112.458 126.677 120.844 120.359 127.094C114.156 133.375 105.786 136.859 96.8229 136.859H33.3333C20.2031 136.859 9.52604 147.552 9.52604 160.698C9.52604 167.104 11.9948 173.078 16.4896 177.521C20.9635 182.057 26.9323 184.536 33.3333 184.536H131.74C134.37 184.536 136.505 186.672 136.505 189.302C136.505 191.938 134.37 194.073 131.74 194.073Z"
          fill="white"
        />
      </g>
    </svg>
  `,
})
export class RoadmapIconComponent {}
