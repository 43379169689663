import { Route } from '@angular/router';
import { importEmptyLayout } from '@pwiz/infra/ui';

export function itemDrawerRoutes(children: Route[]) {
  return {
    path: 'item',
    loadComponent: importEmptyLayout,
    loadChildren: () => children,
  };
}
