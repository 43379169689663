@if (channels()) {
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Tech stakeholder channel</mat-label>
    <input
      type="text"
      matInput
      [formControl]="form.controls.stakeholdersChannel"
      [matAutocomplete]="stakeholderAuto"
    />
    <pw-clear-search
      [search]="stakeholderSearch()"
      (searchChange)="form.controls.stakeholdersChannel.reset()"
      matIconSuffix
    />
    <mat-autocomplete
      #stakeholderAuto="matAutocomplete"
      [displayWith]="displayWith()"
    >
      @for (channel of stakeholderChannels(); track channel.id) {
        <mat-option [value]="channel.id">{{ channel.value }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>CFT stakeholders channel</mat-label>
    <input
      type="text"
      matInput
      [formControl]="form.controls.customerFacingTeamsChannel"
      [matAutocomplete]="customerFacingAuto"
    />
    <pw-clear-search
      [search]="customerFacingSearch()"
      (searchChange)="form.controls.customerFacingTeamsChannel.reset()"
      matIconSuffix
    />
    <mat-autocomplete #customerFacingAuto="matAutocomplete" [displayWith]="displayWith()">
      @for (channel of customerFacingChannels(); track channel.id) {
        <mat-option [value]="channel.id">{{ channel.value }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Management channel</mat-label>
    <input
      type="text"
      matInput
      [formControl]="form.controls.mgmtChannel"
      [matAutocomplete]="mgmtAuto"
    />
    <pw-clear-search
      [search]="mgmtSearch()"
      (searchChange)="form.controls.mgmtChannel.reset()"
      matIconSuffix
    />
    <mat-autocomplete #mgmtAuto="matAutocomplete" [displayWith]="displayWith()">
      @for (channel of mgmtChannels(); track channel.id) {
        <mat-option [value]="channel.id">{{ channel.value }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
} @else {
  <div class="input-loader">
    <pw-rectangle-skeleton />
  </div>
  <div class="input-loader">
    <pw-rectangle-skeleton />
  </div>
  <div class="input-loader">
    <pw-rectangle-skeleton />
  </div>
}
