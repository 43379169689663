<form [formGroup]="form" style="height: 100%" (submit)="onSave()">
  <pw-drawer-container size="m">
    <pw-drawer-header>Sprint Settings</pw-drawer-header>
    <pw-drawer-content>
      <pw-sprint-global-settings-form class="sprint-dates" />
      @if ($teamValues(); as team) {
        <pw-sprint-settings-form [value]="team" [$teams]="teamList()" />
      }
    </pw-drawer-content>
    <pw-drawer-footer align="end">
      <button mat-raised-button color="primary" type="submit">Save</button>
    </pw-drawer-footer>
  </pw-drawer-container>
</form>
