import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerFooterComponent,
  PwDrawerHeaderComponent,
  useClosePwDrawer,
} from '@pwiz/infra/drawer';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  SprintGlobalSettingsFormComponent,
  SprintSettingsFormComponent,
} from '@pwiz/sprint/ui';
import { SprintSettingsContainerForm } from '@pwiz/sprint/ts';
import { SprintService } from '@pwiz/sprint/ui-data';
import { useDetectChanges } from '@pwiz/infra/hooks';
import {
  AddTeam,
  BaseEntityUtils,
  ISprintSettings,
  ISprintTeamSettingsBase,
  ITeam,
} from '@pwiz/entity/ts';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-sprint-settings-drawer',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    PwDrawerContentComponent,
    PwDrawerHeaderComponent,
    PwDrawerFooterComponent,
    ReactiveFormsModule,
    SprintGlobalSettingsFormComponent,
    SprintSettingsFormComponent,
    MatTableModule,
    MatButtonModule,
  ],
  templateUrl: './sprint-settings-drawer.component.html',
  styleUrl: './sprint-settings-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SprintSettingsDrawerComponent {
  form = new FormGroup<SprintSettingsContainerForm>({});
  #cd = useDetectChanges();
  #close = useClosePwDrawer();
  #sprintService = inject(SprintService);
  $globalSprintSettings = toSignal(this.#sprintService.getSprintSettings$(), {
    initialValue: null,
  });
  sprintSettings = input<ISprintTeamSettingsBase | null>(null);
  teamList = input.required<ITeam[]>();
  $teamValues = computed(() => {
    const teamList = this.teamList();
    const globalSettings = this.$globalSprintSettings();
    const sprintSettings = this.sprintSettings();
    if (!globalSettings) {
      return {};
    }
    this.#setGlobalSettings(globalSettings);
    if (!sprintSettings) {
      return this.#setTeamSettings(
        globalSettings,
        BaseEntityUtils.toIdList(teamList),
      );
    }
    const teamSettings = globalSettings.teams[teamList[0].id];
    return {
      [teamList[0].id]: {
        mix: sprintSettings.mix || teamSettings.mix,
        velocity: sprintSettings?.velocity || teamSettings.velocity,
        team: teamList[0],
      },
    };
  });
  save = output<ISprintSettings>();

  #setGlobalSettings({ global: { startDate, duration } }: ISprintSettings) {
    this.form.controls.global?.setValue({ startDate, duration });
    this.form.controls.global?.disable();
  }

  #setTeamSettings({ teams }: ISprintSettings, teamIds: string[]) {
    return teamIds.reduce<Record<
      string,
      AddTeam<ISprintTeamSettingsBase>
    > | null>((acc, teamId) => {
      const settings = teams[teamId];
      if (settings) {
        acc![teamId] = settings;
      }
      return acc;
    }, {});
  }

  onSave() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.#cd();
      return;
    }
    this.save.emit(this.form.value as ISprintSettings);
    this.#close();
  }
}
