import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'pw-slack-auth-res',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'pw-scroll-container pw-center-text pw-padding pw-padding-top pw-padding-l pw-gap-l',
  },
  template: `
    @if (isError()) {
      <h6>There was an error connecting to slack</h6>
      <p>{{ error() }}</p>
      <p>{{ errorDescription() }}</p>
    } @else {
      <h6>Success! Slack connected!</h6>
      <p>Now you can receive notification!</p>
      <span>
        <a mat-flat-button color="primary" routerLink="../"
          >Set up PWiZ slack channels</a
        >
      </span>
    }
  `,
  imports: [MatAnchor, RouterLink],
})
export class SlackAuthResComponent {
  isError = input.required<boolean>();
  error = input<string>('');
  errorDescription = input<string>('');
}
