<div class="show-more-container" #contentContainer>
  <div #content>
    <ng-content/>
  </div>
</div>
@if ($showMore()) {
  <div class="show-btn-container pw-width-100p">
    <button (click)="toggleExpand()" class="show-btn pw-link-button">show {{$expanded() ? 'less' : 'more'}}</button>
  </div>

}
