import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import {
  MatOption,
  MatSelect,
  MatSelectTrigger,
} from '@angular/material/select';
import { ItemPriorityUiComponent } from '../item-priority/item-priority-ui.component';
import { ReactiveFormsModule } from '@angular/forms';
import { eItemPriority } from '@pwiz/entity/ts';
import { ITEM_SCORED_PRIORITIES } from '@pwiz/item/ts';

@Component({
  selector: 'pw-item-priority-change',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    ItemPriorityUiComponent,
    ReactiveFormsModule,
    MatSelectTrigger,
  ],
  template: `
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Priority</mat-label>
      <mat-select [(value)]="priority" [disabled]="readonly()">
        <mat-select-trigger>
          @if (priority(); as priority) {
            <pw-item-priority-ui [priority]="priority" />
          }
        </mat-select-trigger>
        @for (priority of priorityList; track priority) {
          <mat-option [value]="priority">
            <pw-item-priority-ui [priority]="priority" />
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
})
export class ItemPriorityChangeComponent {
  readonly = input(false, { transform: booleanAttribute });
  priority = model<eItemPriority | null>(null);
  priorityList = ITEM_SCORED_PRIORITIES;
}
