<pw-header-page-name title="User preferences">
  User preferences
</pw-header-page-name>
<div class="pw-flex pw-column pw-gap-l my-container pw-scroll-container">
  <h6>My boards</h6>
  <p>Boards are pre-selected on every page filter</p>
  <pw-my-boards />
</div>
<div class="pw-flex pw-column pw-gap-l my-container pw-scroll-container">
  <h6>My teams</h6>
  <p>Teams are pre-selected on every page filter</p>
  <pw-my-teams />
</div>
