<pw-dialog-v3 size="xl">
  <pw-dialog-header>
    <div class="pw-flex pw-gap-l">
      Manual priority overrule
      <pw-item-id [item]="$item().data" mode="itemId" showType />
    </div>
  </pw-dialog-header>
  <pw-dialog-content class="pw-flex pw-column">
    <pw-loader-provider [status]="$item().status">
      <pw-item-priority-change-priority-overview
        [item]="$item().data"
        [priorityList]="$item().data?.priorities || null"
        [users]="$users()"
        (updatePriority)="onUpdatePriority($event)"
      />
      <div class="pw-flex pw-center-vr action-container">
        <pw-item-priority-change-info
          [isProductRated]="$isProductPriorityFormRated()"
          [priorityType]="$productPriorityForm()?.type || null"
          [loading]="$item().status"
          (ratePriority)="onPmRate()"
        />
        <pw-item-priority-change
          [readonly]="!$isProductPriorityFormRated()"
          [(priority)]="$itemPriority"
        />
      </div>
    </pw-loader-provider>
    <pw-item-priority-change-note />
  </pw-dialog-content>
  <pw-dialog-footer>
    <button
      mat-stroked-button
      pwDialogFooter="start"
      color="accent"
      pwDialogClose
    >
      Cancel
    </button>
    <div class="pw-flex pw-gap pw-justify-end" pwDialogFooter="end">
      @if ($item().data; as item) {
        @if (item | pwIsItemPriorityOverridden) {
          <button mat-stroked-button color="accent" (click)="rollback()">
            Reset priority to PWiZ recommendation ({{
              item | pwItemSystemPriority
            }})
          </button>
        }
      }
      <button mat-raised-button color="primary" (click)="onSave()">Save</button>
    </div>
  </pw-dialog-footer>
</pw-dialog-v3>
