<pw-settings-sla-header></pw-settings-sla-header>
<pw-settings-sla-form [value]="$value()?.sla"> </pw-settings-sla-form>

<div class="pw-center pw-flex">
  <pw-settings-sla-footer></pw-settings-sla-footer>
</div>

<pw-step-buttons>
  <button
    mat-button
    pwOnBoardingButtonsPreviousStep
    matStepperPrevious
    color="primary"
  >
    Previous
  </button>
  <button
    mat-raised-button
    pwOnBoardingButtonsNextStep
    matStepperNext
    color="primary"
    [disabled]="form.invalid"
    (click)="onNext()"
  >
    Next
  </button>
</pw-step-buttons>
