import { Route } from '@angular/router';
import { DASHBOARD_DRAWER_ROUTES } from '@pwiz/dashboard/feature';
import { itemDrawerRoutes } from '@pwiz/item/feature';
import { ITEM_SUMMARY_DRAWER_ROUTES } from '@pwiz/item/summary/feature';
import { SPRINT_DRAWER_ROUTES } from '@pwiz/sprint/feature';
import { STRATEGY_DRAWER_ROUTES } from '@pwiz/strategy/feature';
import { TEAM_DRAWER_ROTE } from '@pwiz/team/feature';
import { TICKET_DRAWER_ROUTE } from '@pwiz/ticket/feature';

const routes: Route[] = [
  itemDrawerRoutes(ITEM_SUMMARY_DRAWER_ROUTES),
  TICKET_DRAWER_ROUTE,
  ...SPRINT_DRAWER_ROUTES,
  STRATEGY_DRAWER_ROUTES,
  TEAM_DRAWER_ROTE,
  ...DASHBOARD_DRAWER_ROUTES,
];

export const DRAWER_ROUTES: Route[] = routes.map((route) => ({
  ...route,
  outlet: 'sidebar',
}));
