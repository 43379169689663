import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgFormGeneralType,
  pwFormAggregator,
  pwFormComponentProvider,
  PwFormComponentV2,
  ToFormGroup,
} from '@pwiz/infra/form';
import { OnBoardingAssigneesForm } from '@pwiz/on-boarding/ts';
import { FormGroup } from '@angular/forms';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import {
  SettingsDefaultAssigneeComponent,
  SettingsDefaultAssigneeHeaderComponent,
  SettingsEngineerFormComponent,
} from '@pwiz/settings/ui';
import {
  ENGINEER_COST_TOOLTIP,
  PROVIDER_TYPE_PRIORITY_MAP,
} from '@pwiz/settings/ts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import {
  NextStepDirective,
  PreviousStepDirective,
  StepButtonsComponent,
} from '@pwiz/on-boarding/ui';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

type IForm = ToFormGroup<OnBoardingAssigneesForm>;

@Component({
  selector: 'pw-assignee-on-boarding',
  standalone: true,
  imports: [
    CommonModule,
    SettingsDefaultAssigneeHeaderComponent,
    SettingsDefaultAssigneeComponent,
    SettingsEngineerFormComponent,
    MatTooltipModule,
    MatButtonModule,
    MatStepperModule,
    NextStepDirective,
    PreviousStepDirective,
    StepButtonsComponent,
  ],
  templateUrl: './assignee-on-boarding.component.html',
  styleUrls: ['./assignee-on-boarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [pwFormComponentProvider(AssigneeOnBoardingComponent)],
})
export class AssigneeOnBoardingComponent
  implements PwFormComponentV2<IForm>, AfterViewInit
{
  #formComponentControls!: PwFormComponentV2<IForm>;
  #onBoardingService = inject(OnBoardingService);
  #router = inject(Router);

  form = new FormGroup<IForm>({} as IForm);
  $value = toSignal(this.#onBoardingService.getAssigneeStepForm$());
  users = this.#onBoardingService.getUsers();
  providerTypeByPriority = PROVIDER_TYPE_PRIORITY_MAP;
  engineerCostText = ENGINEER_COST_TOOLTIP.split('*');

  name = 'assignees';

  @ViewChildren(PwFormComponentV2)
  forms!: QueryList<PwFormComponentV2<NgFormGeneralType>>;

  ngAfterViewInit() {
    this.#formComponentControls = pwFormAggregator(this.forms, this.form);
  }

  runCD(): void {
    this.#formComponentControls.runCD?.();
  }

  validate(): void {
    this.#formComponentControls.validate?.();
  }

  onNext() {
    this.#onBoardingService
      .saveAssigneeForm(this.form.value as OnBoardingAssigneesForm)
      .subscribe(() => this.#router.navigateByUrl('/'));
  }
}
