<mat-horizontal-stepper [selectedIndex]="step$ | async" (selectionChange)="onSelectionChange($event)" labelPosition="bottom" [linear]="true">
  <mat-step [stepControl]="form.controls.taskManagement" [completed]="((completedIndex$ | async) || -1) >= 0">
    <ng-template matStepLabel>Task Management</ng-template>
    <pw-task-management-on-boarding></pw-task-management-on-boarding>
  </mat-step>
  <mat-step [stepControl]="form.controls.crm" [completed]="((completedIndex$ | async) || -1) >= 1">
    <ng-template matStepLabel>CRM</ng-template>
    <pw-crm-on-boarding></pw-crm-on-boarding>
  </mat-step>
  <mat-step [stepControl]="form.controls.priority" [completed]="((completedIndex$ | async) || -1) >= 2">
    <ng-template matStepLabel>Priority</ng-template>
    <pw-sla-on-boarding></pw-sla-on-boarding>
  </mat-step>
  <mat-step [stepControl]="form.controls.ticket" [completed]="((completedIndex$ | async) || -1) >= 3">
    <ng-template matStepLabel>Ticket Size</ng-template>
    <pw-t-shirt-size-on-boarding></pw-t-shirt-size-on-boarding>
  </mat-step>
  <mat-step [stepControl]="form.controls.assignee" [completed]="((completedIndex$ | async) || -1) >= 4">
    <ng-template matStepLabel>Domain Assignees</ng-template>
    <pw-assignee-on-boarding></pw-assignee-on-boarding>
  </mat-step>
</mat-horizontal-stepper>
