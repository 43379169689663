import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SprintSettingsContainerForm, SprintSettingsFG } from '@pwiz/sprint/ts';
import { TimeUtils } from '@pwiz/infra/ts';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'pw-sprint-global-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
  ],
  templateUrl: './sprint-global-settings-form.component.html',
  styleUrl: './sprint-global-settings-form.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  viewProviders: [provideParentControl()],
})
export class SprintGlobalSettingsFormComponent implements OnInit {
  #parentControl =
    useParentControlContainer<FormGroup<SprintSettingsContainerForm>>();
  form = this.#initForm();
  tomorrow = TimeUtils.addToDate(new Date(), { days: 1 });

  ngOnInit(): void {
    this.#parentControl.control.setControl('global', this.form);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<SprintSettingsFG>({
      duration: fb.control(null, [Validators.required, Validators.min(0)]),
      startDate: fb.control(null, [Validators.required]),
    });
  }
}
