import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { SprintSettingsDrawerComponent } from './sprint-settings-drawer.component';
import { PwToasterService } from '@pwiz/infra/dialog';
import { CurrentSprintService, SprintService } from '@pwiz/sprint/ui-data';
import { $useTeams } from '@pwiz/team/ui-data';
import { ISprintSettings, ISprintTeamSettingsBase } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-sprint-settings-specific-drawer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <pw-sprint-settings-drawer
      [teamList]="$sprintTeams()"
      [sprintSettings]="sprint().data?.settings || null"
      (save)="onSave($event)"
    />
  `,
  imports: [SprintSettingsDrawerComponent],
})
export class SprintSettingsSpecificDrawerComponent {
  #toaster = inject(PwToasterService);
  #sprintService = inject(SprintService);
  #currentSprintService = inject(CurrentSprintService);
  sprintId = input.required<string>();
  teamList = input.required<string[]>();
  $allTeams = $useTeams();
  $sprintTeams = computed(() =>
    this.$allTeams().filter(({ id }) => this.teamList().includes(id)),
  );
  sprint = this.#currentSprintService.$sprint;

  constructor() {
    effect(
      () => {
        this.#currentSprintService.sprintId = this.sprintId();
      },
      { allowSignalWrites: true },
    );
  }

  onSave(settings: ISprintSettings) {
    const sprint = untracked(() => this.sprint().data);
    if (!sprint) {
      throw new Error('sprint not found');
    }
    this.#sprintService
      .saveSpecificSprintSettings(
        sprint,
        settings.teams[sprint.team.id] as ISprintTeamSettingsBase,
      )
      .subscribe({
        next: () =>
          this.#toaster.successes(`Sprint ${sprint.index} settings saved`, {
            duration: 'medium',
          }),
        error: () =>
          this.#toaster.error(
            `Failed to save Sprint ${sprint.index} settings`,
            {
              duration: 'medium',
            },
          ),
      });
  }
}

export default SprintSettingsSpecificDrawerComponent;
