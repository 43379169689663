import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IItem, PwIssue } from '@pwiz/entity/ts';
import { FocusMonitor } from '@angular/cdk/a11y';
import { adminLayoutShouldFocusSearch } from '@pwiz/layout/ts';
import { fromEvent } from 'rxjs';
import {
  IssueItemPipe,
  RectangleSkeletonComponent,
  StatusErrorDirective,
  StatusLoadingDirective,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';
import { ItemSearchService } from '@pwiz/layout/ui-data';
import { ItemsSearchRowComponent } from '@pwiz/layout/ui';
import { TicketIconComponent } from '@pwiz/infra/icons/ui';
import { RouterLink } from '@angular/router';
import { DrawerLinkDirective, DrawerRoutingService } from '@pwiz/infra/drawer';

@Component({
  selector: 'pw-items-search',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    RectangleSkeletonComponent,
    IssueItemPipe,
    ItemsSearchRowComponent,
    TicketIconComponent,
    RouterLink,
    DrawerLinkDirective,
    StatusSwitchCaseComponent,
    StatusLoadingDirective,
    StatusErrorDirective,
  ],
  templateUrl: './items-search.component.html',
  styleUrls: ['./items-search.component.scss'],
  providers: [ItemSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'small-icon-button small-form-fields',
  },
})
export class ItemsSearchComponent {
  #searchService = inject(ItemSearchService);
  #focusMonitor = inject(FocusMonitor);
  #drawer = inject(DrawerRoutingService);

  control = new FormControl<string | IItem>('');
  #$search = toSignal(this.control.valueChanges, { initialValue: '' });
  $tooShortSearch = computed(() => {
    const search = this.#$search();
    return typeof search === 'string' && search.length < 3 && search.length > 0;
  });
  $filteredItems = toSignal(this.#searchService.tickets$, {
    initialValue: null,
  });

  $isActive = computed(() => {
    const str = this.#$search();
    return typeof str === 'string' && str.length > 0;
  });

  @ViewChild('inputElement')
  input!: ElementRef<HTMLInputElement>;

  @ViewChild(MatAutocomplete)
  autoComplete!: MatAutocomplete;

  constructor() {
    this.control.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.#searchService.onSearchChange(
        typeof value === 'string' ? value : undefined,
      );
    });
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(takeUntilDestroyed())
      .subscribe((event) => this.focusSearch(event));
  }
  onClear() {
    this.control.setValue('');
  }

  onOptionSelected({ option }: MatAutocompleteSelectedEvent) {
    const selectedItem = <PwIssue>option.value;
    this.onTickSelect(selectedItem);
    this.control.setValue('');
  }

  focusSearch = (event: KeyboardEvent) => {
    if (event.code === 'Slash' && adminLayoutShouldFocusSearch(event)) {
      this.#focusMonitor.focusVia(this.input.nativeElement, 'keyboard');
      event.preventDefault();
    }
  };

  onFocusOut() {
    setTimeout(() => (this.autoComplete.showPanel = false), 20);
  }

  onTickSelect(issue: PwIssue) {
    if (issue.item) {
      this.#drawer.itemSummary(issue.item.id);
    } else {
      this.#drawer.ticketSummary(issue.id);
    }
  }
}
