import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { useQueryParams } from '@pwiz/infra/query-params/data';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pw-auth',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent {
  #authService = inject(AuthService);
  activatedRoute = inject(ActivatedRoute);
  constructor() {
    const { value } = useQueryParams();
    const { invitation, organization, organization_name } = value();
    this.#authService.loginWithRedirect({
      authorizationParams: {
        invitation,
        organization,
        organization_name,
        redirect_uri: `${window.location.origin}/on-login`,
      },
      appState: { target: this.activatedRoute.snapshot.queryParams['state'] },
    });
  }
}
