import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  TaskManagementSystemName,
  TaskManagementSystemSelectedEvent,
} from '@pwiz/on-boarding/ts';

@Component({
  selector: 'pw-on-boarding-task-management-ui',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './on-boarding-task-management-ui.component.html',
  styleUrls: ['./on-boarding-task-management-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnBoardingTaskManagementUiComponent {
  @Output() systemSelected =
    new EventEmitter<TaskManagementSystemSelectedEvent>();

  onSelected(system: TaskManagementSystemName) {
    this.systemSelected.emit({
      selected: system,
    });
  }
}
