<div class="general">
  <h6 class="subheader">Overview</h6>
  <pw-label-group childrenStyle="row" class="content">
    <pw-label>
      <pw-label-header>Business goal:</pw-label-header>
      <pw-status-switch-case [status]="fetchStatus()">
        {{ $item()?.keyResultList?.[0]?.businessGoal?.name | pwNullableValue }}
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <pw-label-header>Key result:</pw-label-header>
      <pw-status-switch-case [status]="fetchStatus()">
        {{ $item()?.keyResultList?.[0]?.name | pwNullableValue }}
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <pw-label-header>Deadline:</pw-label-header>
      <pw-status-switch-case [status]="fetchStatus()">
        <pw-quarter
          [year]="$item()?.deadlineYear || null"
          [q]="$item()?.deadlineQ || null"
        />
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <ng-container slot="header">Number of affected customers:</ng-container>
      <pw-status-switch-case [status]="fetchStatus()">
        {{ $item()?.affectedCustomersCount }}
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <ng-container slot="header">Total related ARR:</ng-container>
      <pw-status-switch-case [status]="fetchStatus()">
        <pw-currency [amount]="$item()?.arr" />
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <pw-label-header>Potential Risk:</pw-label-header>
      <pw-status-switch-case [status]="fetchStatus()">
        <pw-item-risk *pwStatusSuccess [item]="$item()!" />
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <pw-label-header>Potential Gain:</pw-label-header>
      <pw-status-switch-case [status]="fetchStatus()">
        <pw-currency [amount]="$item()?.gain" />
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <ng-container slot="header">Effort size (max):</ng-container>
      <pw-status-switch-case [status]="fetchStatus()">
        {{ $maxEffort() | pwNullableValue }} Days
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label>
      <ng-container slot="header">Jira effort estimation:</ng-container>
      <pw-status-switch-case [status]="fetchStatus()">
        {{ $item()?.effortEstimation | pwFixed: 1 | pwNullableValue }}
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
    <pw-label [inLine]="false">
      <ng-container slot="header">Description:</ng-container>
      <pw-status-switch-case [status]="fetchStatus()">
        <pw-see-more height="6rem">
          <pw-item-description
            [descriptionObject]="$item()?.ticketBase?.descriptionObject || null"
            [descriptionText]="$item()?.ticketBase?.descriptionText || null"
          />
        </pw-see-more>
        <div pwStatusLoading>
          <pw-rectangle-skeleton width="3rem" height="1rem" />
        </div>
      </pw-status-switch-case>
    </pw-label>
  </pw-label-group>
</div>

@if ($isDone()) {
  <div>
    <h6 class="subheader">Actual</h6>
    <pw-label-group childrenStyle="row" class="content">
      <pw-label>
        <pw-label-header>Delivery time:</pw-label-header>
        <pw-status-switch-case [status]="fetchStatus()">
          {{ $item()?.actualTimeToDeliver | pwPassedTime | pwNullableValue }}
          <div pwStatusLoading>
            <pw-rectangle-skeleton width="3rem" height="1rem" />
          </div>
        </pw-status-switch-case>
      </pw-label>
      <pw-label>
        <pw-label-header>Effort (Gross):</pw-label-header>
        <pw-status-switch-case [status]="fetchStatus()">
          {{ $item()?.actualEffort | pwNullableValue }} hours
          <div pwStatusLoading>
            <pw-rectangle-skeleton width="3rem" height="1rem" />
          </div>
        </pw-status-switch-case>
      </pw-label>
      <pw-label>
        <pw-label-header>Cost (Gross):</pw-label-header>
        <pw-status-switch-case [status]="fetchStatus()">
          <pw-currency [amount]="$item()?.actualCost" />
          <div pwStatusLoading>
            <pw-rectangle-skeleton width="3rem" height="1rem" />
          </div>
        </pw-status-switch-case>
      </pw-label>
    </pw-label-group>
  </div>
}
