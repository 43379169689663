import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { ePriorityType } from '@pwiz/entity/ts';
import {
  PwEnumToReadableStringPipe,
  RectangleSkeletonComponent,
  StatusLoadingDirective,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';
import { MatButton } from '@angular/material/button';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';

@Component({
  selector: 'pw-item-priority-change-info',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <pw-status-switch-case [status]="loading()">
      <ng-container pwStatusLoading>
        <pw-rectangle-skeleton />
      </ng-container>
      @if (isProductRated()) {
        <h6>Change priority to:</h6>
      } @else {
        <h6>
          PM must rate {{ priorityType() | pwEnumToReadableString }} before
          overwriting the priority
        </h6>
        <button mat-raised-button color="primary" (click)="ratePriority.emit()">
          Rate {{ priorityType() | pwEnumToReadableString }}
        </button>
      }
    </pw-status-switch-case>
  `,
  imports: [
    PwEnumToReadableStringPipe,
    MatButton,
    StatusSwitchCaseComponent,
    StatusLoadingDirective,
    RectangleSkeletonComponent,
  ],
})
export class ItemPriorityChangeInfoComponent {
  isProductRated = input.required<boolean>();
  priorityType = input.required<ePriorityType | null>();
  loading = input.required<PwCacheStatus>();
  ratePriority = output<void>();
}
