import { inject, Provider } from '@angular/core';
import { ContentProjectionService } from '../content-projection.service';

export const provideContentProjection = (): Provider[] => {
  return [
    {
      provide: ContentProjectionService,
      /** This will ensure that I always have only 1 instance of the service no matter how many times the function is used*/
      useFactory: () =>
        inject(ContentProjectionService, { skipSelf: true, optional: true }) ||
        new ContentProjectionService(),
    },
  ];
};
