<pw-priority-overview-base
  [priority]="priority()"
  [isReadOnly]="isReadOnly()"
  [users]="users()"
  (updatePriority)="updatePriority.emit($event)"
>
  <pw-label [inLine]="true" class="pw-flex pw-wrap pw-center-vr">
    <pw-label-header>
      Affected customers
      @if (priority().accountPriority.length; as len) {
        ({{ len }})
      }
      :
    </pw-label-header>
    <pw-customer-list [accountList]="$accounts()" /> </pw-label
></pw-priority-overview-base>
