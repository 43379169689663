@if (issue(); as issue) {
  <div class="left">
    <pw-ticket-id [ticket]="issue" disableLink/>
    @if (issue | pwIssueItem; as item) {
      <pw-item-priority [item]="item" />
    }
  </div>
  {{ issue.summery }}
} @else if (loading()) {
  <div class="left">
    <pw-rectangle-skeleton colorShade="dark" height="1rem" width="var(--pw-distance-30)"/>
    <pw-rectangle-skeleton colorShade="dark" height="1rem" width="var(--pw-distance-30)"/>
  </div>
  <pw-rectangle-skeleton randomWidth colorShade="dark" height="1rem" width="var(--pw-distance-16)"/>
}
