import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardAutocompleteComponent } from '@pwiz/board/ui';
import { IBoard } from '@pwiz/entity/ts';
import { $useBoards } from '@pwiz/board/ui-data';
import {
  MyBoardsListComponent,
  MyBoardsListContentDirective,
} from '@pwiz/board/ui';
import { $useUserPreferences, CurrentUserService } from '@pwiz/user/ui-data';
import { PwToasterService } from '@pwiz/infra/dialog';
import {
  addBoardToUserPref,
  DEFAULT_USER_PREFERENCES,
  deleteBoardToUserPref,
} from '@pwiz/user/ts';
import { filterTeamsByBoards } from '@pwiz/team/ts';
import { $useTeams } from '@pwiz/team/ui-data';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { ClearSearchComponent, FilterPipe } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-my-boards',
  standalone: true,
  imports: [
    CommonModule,
    BoardAutocompleteComponent,
    MyBoardsListComponent,
    MatFormField,
    MatInput,
    MatLabel,
    FormsModule,
    MatIcon,
    MatIconButton,
    ClearSearchComponent,
    FilterPipe,
    MatSuffix,
    MyBoardsListContentDirective,
  ],
  templateUrl: './my-boards.component.html',
  styleUrl: './my-boards.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pw-scroll-container' },
})
export class MyBoardsComponent {
  #userService = inject(CurrentUserService);
  #toaster = inject(PwToasterService);

  $userPref = $useUserPreferences();
  $boards = $useBoards();
  $teams = $useTeams();
  $search = signal('');
  $boardMap = computed(() => {
    const boards = this.$boards();
    const myBoards = this.$userPref()?.myBoards || [];
    return boards.reduce((acc, board) => {
      acc.set(board.id, myBoards.includes(board.id));
      return acc;
    }, new Map<string, boolean>());
  });

  #onBoardSelected({ board }: { board: IBoard }) {
    const pref = this.$userPref() || DEFAULT_USER_PREFERENCES;
    const userPreferences = addBoardToUserPref(pref, board.id);
    const favoriteTeams = this.$teams().filter(({ id }) =>
      pref.myTeams.includes(id),
    );
    userPreferences.myTeams = filterTeamsByBoards(
      favoriteTeams,
      userPreferences.myBoards,
    ).map(({ id }) => id);
    this.#userService.savePref(userPreferences).subscribe({
      error: () => this.#toaster.error('Failed to add board to my boards'),
    });
  }

  #onDeleteBoard({ board }: { board: IBoard }) {
    const pref = this.$userPref() || DEFAULT_USER_PREFERENCES;
    this.#userService
      .savePref(deleteBoardToUserPref(pref, board.id))
      .subscribe({
        error: () =>
          this.#toaster.error('Failed to remove board from my boards'),
      });
  }

  toggle(board: IBoard) {
    const isFavorite = this.$boardMap().get(board.id);
    if (isFavorite) {
      this.#onDeleteBoard({ board });
    } else {
      this.#onBoardSelected({ board });
    }
  }
}
