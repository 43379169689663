import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { SalesforceFormComponent } from '@pwiz/crm/salesforce/ui';
import {
  SalesforceSettingsFormContainer,
  SalesforceSettingsFormValue,
} from '@pwiz/crm/salesforce/ts';
import { SalesforceSettingsService } from '@pwiz/crm/salesforce/ui-data';
import { CrmSettingsService } from '@pwiz/provider/ui-data';
import { usePwToaster } from '@pwiz/infra/dialog';
import { MatIcon } from '@angular/material/icon';
import { HeaderPageNameComponent } from '@pwiz/layout/feature';

@Component({
  selector: 'pw-salesforce-settings',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pw-container pw-gap-l' },
  imports: [
    SalesforceFormComponent,
    ReactiveFormsModule,
    MatButton,
    HeaderPageNameComponent,
    MatIcon,
  ],
  template: `
     <pw-header-page-name title="Settings - Salesforfce">
         Settings / Salesforce 
    </pw-header-page-name>
    <p>
      <mat-icon>info</mat-icon>
      Enter the names of the Salesforce fields you use for the information
      below.
    </p>
    <form
      (ngSubmit)="saveSettings()"
      [formGroup]="form"
      class="pw-container pw-gap-inherit"
    >
      <pw-salesforce-setting-form
        [value]="$settingsDefined()"
        [salesforceFields]="$salesforceFields()"
        class="pw-flex-1"
      />
      <div class="pw-flex pw-justify-end">
        <button mat-raised-button color="primary" type="submit">Save</button>
      </div>
    </form>
  `,
})
export class SalesforceSettingsComponent {
  #toaster = usePwToaster();
  #salesforceSettingsService = inject(SalesforceSettingsService);
  #crmSettingsService = inject(CrmSettingsService);
  form = new FormGroup<SalesforceSettingsFormContainer>(
    {} as SalesforceSettingsFormContainer,
  );
  $settingsDefined = toSignal(this.#crmSettingsService.getSettings(), {
    initialValue: null,
  });
  $salesforceFields = toSignal(
    this.#salesforceSettingsService.getFieldsSalesforce(),
    {
      initialValue: null,
    },
  );
  #idFieldsSettings = computed(() => this.$settingsDefined()?.id);
  saveSettings() {
    const values = this.form.controls.salesforceSettings.value as SalesforceSettingsFormValue;
    if (values.fieldMapArr && values.fieldMapMrr) {
      this.#toaster.error('Cannot set both fieldMapArr and fieldMapMrr');
    } else if (this.form.valid) {
      this.#crmSettingsService.updateFields({ ...values, id: this.#idFieldsSettings() ?? '' }).subscribe({
        next: () => this.#toaster.successes('Salesforce settings saved'),
        error: () => this.#toaster.error('Failed to save Salesforce settings'),
      });
    } else {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
    }
  }
}
