import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pw-ai-icon',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./private/shared.scss'],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      viewBox="0 0 375 375"
      height="500"
      version="1.0"
    >
      <defs>
        <clipPath id="a"><path d="M30.34 0h314.25v375H30.34Zm0 0" /></clipPath>
      </defs>
      <path fill="transparent" d="M-37.5-37.5h450v450h-450z" />
      <path fill="transparent" d="M-37.5-37.5h450v450h-450z" />
      <g clip-path="url(#a)">
        <path
          fill="var(--pw-primary-color)"
          d="M344.648 187.5c-150.03 13.57-145.906 35.54-157.152 187.445C176.25 223.04 180.296 201.07 30.34 187.5 180.37 173.93 176.25 151.96 187.496.055 198.742 151.96 194.617 173.93 344.648 187.5Zm-221.785-59.234C100.22 96.926 77.578 82.98 77.578 82.98s13.871 22.645 45.285 45.286ZM297.34 82.98s-22.645 13.872-45.29 45.286c31.419-22.641 45.29-45.286 45.29-45.286ZM77.578 292.02s22.64-13.872 45.285-45.286C91.45 269.375 77.578 292.02 77.578 292.02Zm174.473-45.286c22.644 31.34 45.289 45.286 45.289 45.286s-13.95-22.645-45.29-45.286Zm0 0"
        />
      </g>
    </svg>
  `,
})
export class AiIconComponent {}
