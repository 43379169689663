import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { $useTeams } from '@pwiz/team/ui-data';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  FilterTeamByBoardsPipe,
  TeamListComponent,
  TeamListContentDirective,
} from '@pwiz/team/ui';
import { ITeam } from '@pwiz/entity/ts';
import { FilterPipe } from '@pwiz/infra/ui';
import { $useUserPreferences, CurrentUserService } from '@pwiz/user/ui-data';
import { usePwToaster } from '@pwiz/infra/dialog';

@Component({
  selector: 'pw-my-teams',
  standalone: true,
  imports: [
    CommonModule,
    FilterPipe,
    FilterTeamByBoardsPipe,
    FormsModule,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    TeamListComponent,
    TeamListContentDirective,
  ],
  templateUrl: './my-teams.component.html',
  styleUrl: './my-teams.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pw-scroll-container' },
})
export class MyTeamsComponent {
  #currentUserService = inject(CurrentUserService);
  #toaster = usePwToaster();
  $search = signal('');
  $teams = $useTeams();
  $userPref = $useUserPreferences();
  $teamMap = computed(() => {
    const { myTeams } = this.$userPref() || { myTeams: <string[]>[] };
    const teams = this.$teams();
    return teams.reduce<Record<string, boolean>>((acc, team) => {
      acc[team.id] = !!myTeams.find((id) => team.id === id);
      return acc;
    }, {});
  });

  favoriteTeam(team: ITeam) {
    const { myTeams } = this.$userPref() || { myTeams: <string[]>[] };
    const index = myTeams.findIndex((id) => id === team.id);
    const newTeams = structuredClone(myTeams);
    if (index >= 0) {
      newTeams.splice(index, 1);
    } else {
      newTeams.push(team.id);
    }
    this.#currentUserService.updatePref({ myTeams: newTeams }).subscribe({
      error: () =>
        this.#toaster.error(`failed to save ${team.name}`, {
          duration: 'medium',
        }),
    });
  }
}
