import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * @description
 * @slots icon, name
 */
@Component({
  selector: 'pw-sidebar-link',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './sidebar-link.component.scss',
  template: `
    <div class="icon-container pw-flex pw-center">
      <ng-content select="[slot=icon]" />
    </div>
    <span class="page-name">
      {{ pageName() }}
      <div class="name-inner-container">
        <ng-content select="[slot=name]" />
      </div>
    </span>
    <span class="badge">
      <ng-content />
    </span>
  `,
})
export class SidebarLinkComponent {
  pageName = input.required<string>();
}
