<pw-drawer-container size="xl">
  <pw-drawer-header>
    Edit sprint {{ $sprint() ? ($sprint()! | pwSprintName) : 'Sprint' }}
  </pw-drawer-header>
  <pw-drawer-content>
    @if ($sprint(); as sprint) {
      <pw-sprint-edit-item
        [sprint]="sprint"
        (itemsChange)="onItemsChange($event)"
      />
    }
  </pw-drawer-content>
  <pw-drawer-footer>
    <button mat-raised-button color="primary" (click)="onSave()">
      Save
    </button>
  </pw-drawer-footer>
</pw-drawer-container>
