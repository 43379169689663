import { Injectable, signal } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable()
export class LayoutService {
  #headerPageNameTemplate = signal<TemplatePortal<unknown> | null>(null);
  #headerContentTemplate = signal<TemplatePortal<unknown> | null>(null);

  getHeaderPageNameTemplate() {
    return this.#headerPageNameTemplate.asReadonly();
  }

  setHeaderPageNameTemplate(value: TemplatePortal<unknown> | null) {
    this.#headerPageNameTemplate.set(value);
  }

  getHeaderContentTemplate() {
    return this.#headerContentTemplate.asReadonly();
  }

  setHeaderContentTemplate(value: TemplatePortal<unknown> | null) {
    this.#headerContentTemplate.set(value);
  }
}
