import { authGuardFn } from '@auth0/auth0-angular';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { inject } from '@angular/core';

export async function pwAuthRedirect(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) {
  const router = inject(Router);
  const isLoggedIn = await firstValueFrom(authGuardFn(route, state));
  if (isLoggedIn) {
    return true;
  }
  const urlTree = router.parseUrl(`auth`);
  urlTree.queryParams = {
    state: state.url,
  };
  return urlTree;
}
