<p>
  Want to measure your revenue in an automatic way? connect your source of
  customers relationship management (CRM).
</p>
<div class="pw-padding pw-flex pw-space-around">
  <button mat-raised-button color="accent" (click)="onSelected('hubspot')">
    <i class="fab fa-hubspot" matButtonIcon></i> HubSpot
  </button>
  <button mat-raised-button color="accent" (click)="onSelected('salesforce')">
    <i class="fab fa-salesforce" matButtonIcon></i> SalesForce
  </button>
</div>
<pw-step-buttons>
  <button
    mat-button
    pwOnBoardingButtonsPreviousStep
    matStepperPrevious
    color="primary"
  >
    Previous
  </button>
  <button
    mat-raised-button
    pwOnBoardingButtonsNextStep
    matStepperNext
    color="primary"
    [disabled]="form.invalid"
  >
    Next
  </button>
</pw-step-buttons>
