import { inject, Injectable } from '@angular/core';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import {
  SLACK_API,
  SLACK_API_ENDPOINTS,
  SlackSettingsFormValue,
} from '@pwiz/chat/ts';
import { HttpClient } from '@angular/common/http';
import { IdValuePair, PwUiRedirect } from '@pwiz/infra/ts';
import { filter } from 'rxjs/operators';
import { ISlack } from '@pwiz/entity/ts';

@Injectable({ providedIn: 'root' })
export class SlackService {
  #http = inject(HttpClient);
  #url = useBaseApiUrl(SLACK_API);

  connect() {
    return this.#http.get<PwUiRedirect>(
      `${this.#url}/${SLACK_API_ENDPOINTS.authenticate}`,
    );
  }

  getChannels() {
    return this.#http.get<IdValuePair[]>(
      `${this.#url}/${SLACK_API_ENDPOINTS.channels}`,
    );
  }

  saveSettings(slackSettings: SlackSettingsFormValue) {
    return this.#http.post(
      `${this.#url}/${SLACK_API_ENDPOINTS.settings}`,
      slackSettings,
    );
  }

  getSettings() {
    return this.#http
      .get<ISlack | null>(`${this.#url}/${SLACK_API_ENDPOINTS.settings}`)
      .pipe(filter((slack): slack is ISlack => !!slack));
  }
}
