import { Pipe, PipeTransform } from '@angular/core';
import { eTicketStatus, getItemTicketBase, IItem } from '@pwiz/entity/ts';
import { TimeUtils } from '@pwiz/infra/ts';

@Pipe({
  name: 'pwItemDaysOpen',
  standalone: true,
})
export class ItemDaysOpenPipe implements PipeTransform {
  #notClosedStatuses = [
    eTicketStatus.open,
    eTicketStatus.inProgress,
    eTicketStatus.unknown,
  ];
  transform(item: IItem) {
    const { issueCreatedAt, workFinishDate, status } = getItemTicketBase(item);
    if (!issueCreatedAt) {
      return null;
    }
    if (workFinishDate) {
      /** The first day should be counted as well*/
      return TimeUtils.daysBetween(issueCreatedAt, workFinishDate) + 1;
    }
    if (this.#notClosedStatuses.includes(status)) {
      /** The first day should be counted as well*/
      return TimeUtils.daysBetween(issueCreatedAt, new Date()) + 1;
    }
    return null;
  }
}
