import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { ePriorityType, findPriorityByType, IItem } from '@pwiz/entity/ts';
import {
  PriorityConfidenceOverviewComponent,
  PriorityEffortOverviewComponent,
  PriorityImpactOverviewComponent,
  PriorityOverviewUpdatePriorityEvent,
  PriorityUrgencyOverviewComponent,
} from '@pwiz/priority/ui';
import { UiPriority } from '@pwiz/priority/ts';
import { PwUser } from '@pwiz/user/ts';
import {
  NameComponent,
  RectangleSkeletonComponent,
  StatusLoadingDirective,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';

@Component({
  selector: 'pw-item-priority-change-priority-overview',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PriorityConfidenceOverviewComponent,
    PriorityUrgencyOverviewComponent,
    PriorityImpactOverviewComponent,
    PriorityEffortOverviewComponent,
    StatusSwitchCaseComponent,
    NameComponent,
    RectangleSkeletonComponent,
    StatusLoadingDirective,
  ],
  templateUrl: 'item-priority-change-priority-overview.component.html',
  styleUrl: 'item-priority-change-priority-overview.component.scss',
})
export class ItemPriorityChangePriorityOverviewComponent {
  item = input.required<IItem | null>();
  priorityList = input.required<UiPriority[] | null>();
  users = input.required<PwUser[] | null>();
  isReadOnly = input(false, { transform: booleanAttribute });
  updatePriority = output<PriorityOverviewUpdatePriorityEvent>();
  $confidence = this.#$getPriorityByType(ePriorityType.confidence);
  $effort = this.#$getPriorityByType(ePriorityType.effort);
  $urgency = this.#$getPriorityByType(ePriorityType.urgency);
  $impact = this.#$getPriorityByType(ePriorityType.impact);

  #$getPriorityByType(type: ePriorityType) {
    return computed(() => {
      const priorityList = this.priorityList();
      if (!priorityList) {
        return null;
      }
      return findPriorityByType(priorityList, type);
    });
  }

  onUpdatePriority(event: PriorityOverviewUpdatePriorityEvent) {
    this.updatePriority.emit(event);
  }
}
