import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  BasicAutocompleteComponent,
  PwEnumToReadableStringPipe,
  RectangleSkeletonComponent,
  toFormControlValueSignal,
} from '@pwiz/infra/ui';
import {
  SalesforceFieldIdValuePairsWithPickListValues,
  SalesforceSettingsForm,
  SalesforceSettingsFormContainer,
  SalesforceSettingsFormValue,
} from '@pwiz/crm/salesforce/ts';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  CRM_ACCOUNT_STATUS_LIST,
  ProviderCrmSettingsDTO,
  ProviderCrmSettingsValueMapAccountStatus,
} from '@pwiz/entity/ts';

@Component({
  selector: 'pw-salesforce-setting-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrl: 'salesforce-setting-form.component.scss',
  templateUrl: './salesforce-setting-form.component.html',
  imports: [
    ReactiveFormsModule,
    MatSelectModule,
    RectangleSkeletonComponent,
    BasicAutocompleteComponent,
    MatFormField,
    PwEnumToReadableStringPipe,
    MatLabel,
  ],
  providers: [provideParentControl()],
})
export class SalesforceFormComponent implements OnInit {
  #parentControl =
    useParentControlContainer<FormGroup<SalesforceSettingsFormContainer>>();
  #fb = inject(FormBuilder);
  form = this.#initForm();
  salesforceFields = input.required<
    SalesforceFieldIdValuePairsWithPickListValues[] | null
  >();
  value = input.required<SalesforceSettingsFormValue | null>();
  $listTypeAccountStatus = signal(CRM_ACCOUNT_STATUS_LIST);
  $fieldMapAccountStatus = toFormControlValueSignal<string | null>(
    this.form,
    'fieldMapAccountStatus',
  );
  $accountStatsValue = this.#getAccountStatusValueMap();
  $optionsWithoutPicklistValues = computed(
    () =>
      this.salesforceFields()?.filter((item) => !item?.picklistValues) ?? [],
  );
  $optionsWithPicklistValues = computed(
    () =>
      this.salesforceFields()?.filter(({ picklistValues }) => {
        return picklistValues && picklistValues.filter(({ active }) => active);
      }) ?? [],
  );
  constructor() {
    effect(
      () => {
        const value = this.value();
        if (value) {
          this.form.patchValue(value);
        }
      },
      { allowSignalWrites: true },
    );
    effect(() => {
      const valueMapAccountStatus = this.form.controls.valueMapAccountStatus;
      Object.keys(valueMapAccountStatus.controls).forEach((key) =>
        // @ts-expect-error some wired ts error. Think we need to upgrade ts version. this code works.
        valueMapAccountStatus.removeControl(key),
      );
      this.#createValueStatusControls(
        this.$fieldMapAccountStatus(),
        this.getPickListValues(),
      );
    });
  }
  ngOnInit() {
    this.#parentControl.control.setControl('salesforceSettings', this.form);
  }
  get valueMapAccountStatus() {
    return this.form.controls.valueMapAccountStatus;
  }
  getPickListValues() {
    const fieldStatus = this.$fieldMapAccountStatus();
    if (!fieldStatus) {
      return null;
    }
    const salesforceFields = this.salesforceFields();
    if (!salesforceFields) {
      return null;
    }
    const filed = salesforceFields.find(({ id }) => id === fieldStatus);
    if (!filed) {
      return null;
    }
    if (!filed.picklistValues || !filed.picklistValues) {
      return null;
    }
    return filed.picklistValues.map(({ label }) => label);
  }
  #createValueStatusControls(
    fieldMapStatus: string | null,
    picklistValues: string[] | null,
  ) {
    picklistValues?.forEach((key) =>
      this.form.controls.valueMapAccountStatus.addControl(
        key,
        new FormControl(null),
      ),
    );
    if (fieldMapStatus === this.value()?.fieldMapAccountStatus) {
      const valueMapAccountStatus = this.value()?.valueMapAccountStatus;
      if (valueMapAccountStatus) {
        this.form.controls.valueMapAccountStatus.patchValue(
          valueMapAccountStatus,
        );
      }
    }
  }
  #getAccountStatusValueMap() {
    const $formValue = toFormControlValueSignal<
      ProviderCrmSettingsDTO['valueMapAccountStatus']
    >(this.form, 'valueMapAccountStatus');
    return computed(() => Object.keys($formValue() || {}));
  }
  #initForm() {
    return this.#fb.group<SalesforceSettingsForm>({
      fieldMapMrr: this.#fb.control(null),
      fieldMapAccountStatus: this.#fb.control(null),
      fieldMapArr: this.#fb.control(null),
      fieldMapAssignedCs: this.#fb.control(null),
      fieldMapFirstWonDate: this.#fb.control(null),
      fieldMapName: this.#fb.control(null),
      fieldMapOwner: this.#fb.control(null),
      fieldMapRenewalDate: this.#fb.control(null),
      valueMapAccountStatus:
        this.#fb.group<ProviderCrmSettingsValueMapAccountStatus>({}),
    });
  }
}
