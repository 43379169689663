import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pwYesNo',
  standalone: true,
  pure: true,
})
export class PwYesNoPipe implements PipeTransform {
  transform(value?: boolean | null): string {
    return value == null ? '' : value ? 'Yes' : 'No';
  }
}
