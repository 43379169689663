import { Route } from '@angular/router';
import { importEmptyLayout } from '@pwiz/infra/ui';

export const TEAM_DRAWER_ROTE: Route = {
  path: 'team',
  loadComponent: importEmptyLayout,
  children: [
    {
      path: ':teamId',
      loadComponent: () =>
        import('./team-summary/team-summary-drawer.component'),
    },
  ],
};
