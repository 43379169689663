import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwLabelGroupComponent,
  PwLabelHeaderComponent,
  RectangleSkeletonComponent,
} from '@pwiz/infra/ui';
import { UiItemBase } from '@pwiz/entity/ts';
import { getItemIssue } from '@pwiz/item/ts';
import { ItemIdComponent } from '@pwiz/item-common/ui';

@Component({
  selector: 'pw-item-stats',
  standalone: true,
  imports: [
    CommonModule,
    ItemIdComponent,
    PwEnumToReadableStringPipe,
    PwDateTimePipe,
    PwLabelGroupComponent,
    PwLabelComponent,
    PwLabelHeaderComponent,
    RectangleSkeletonComponent,
  ],
  templateUrl: './item-stats.component.html',
  styleUrl: './item-stats.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemStatsComponent {
  item = input.required<UiItemBase | null>();
  $issue = computed(() => (this.item() ? getItemIssue(this.item()!) : null));
}
