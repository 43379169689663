import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import { MatButtonModule } from '@angular/material/button';
import { MatStep, MatStepperModule } from '@angular/material/stepper';
import {
  NextStepDirective,
  OnBoardingTaskManagementUiComponent,
  StepButtonsComponent,
} from '@pwiz/on-boarding/ui';
import {
  TaskManagementForm,
  TaskManagementSystemSelectedEvent,
} from '@pwiz/on-boarding/ts';
import { tap } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { pwTakeUntilDestroyed } from '@pwiz/infra/ui';
import {
  pwFormComponentProvider,
  PwFormComponentV2,
  PwValidators,
  ToFormGroup,
} from '@pwiz/infra/form';

type IForm = ToFormGroup<TaskManagementForm>;

@Component({
  selector: 'pw-task-management-on-boarding',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    StepButtonsComponent,
    NextStepDirective,
    OnBoardingTaskManagementUiComponent,
  ],
  templateUrl: './task-management-on-boarding.component.html',
  styleUrls: ['./task-management-on-boarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [pwFormComponentProvider(TaskManagementOnBoardingComponent)],
})
export class TaskManagementOnBoardingComponent
  implements PwFormComponentV2<IForm>
{
  #cd = inject(ChangeDetectorRef);
  #onBoardingService = inject(OnBoardingService);
  step = inject(MatStep);
  form = this.#initForm();
  name = 'taskManagement';
  validate = () => {
    throw new Error('Method not implemented.');
  };
  runCD = () => {
    throw new Error('Method not implemented.');
  };

  constructor() {
    this.#onBoardingService
      .hasTaskManagementSystem$()
      .pipe(
        pwTakeUntilDestroyed(),
        tap((hasTM) => {
          this.form.setValue({ connected: hasTM });
          this.form.markAllAsTouched();
        }),
      )
      .subscribe();
  }

  onSystemSelected({ selected }: TaskManagementSystemSelectedEvent) {
    this.#onBoardingService.connectTaskManagementSystem(selected);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<IForm>({
      connected: fb.control(false, [PwValidators.equals(true)]),
    });
  }
}
