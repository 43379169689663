<pw-priority-overview-base
  [isReadOnly]="isReadOnly()"
  [users]="users()"
  [priority]="priority()"
  (updatePriority)="updatePriority.emit($event)"
>
  <pw-label [inLine]="true">
    <ng-container slot="header">Confidence level:</ng-container>
    {{ priority().level }}
  </pw-label>
  <pw-label [inLine]="true">
    <ng-container slot="header">Aligns to company goals:</ng-container>
    {{ item().keyResultTargetList?.length !== 0 | pwYesNo }}
  </pw-label>
  <pw-label [inLine]="true">
    <ng-container slot="header">Innovative:</ng-container>
    {{ item().innovative | pwYesNo }}
  </pw-label>
  <pw-label [inLine]="true" class="pw-flex pw-center-vr pw-gap">
    <ng-container slot="header">Commitment to:</ng-container>
    <pw-customer-list [accountList]="item().committedToCustomers || []" />
  </pw-label>
  <pw-label [inLine]="true">
    <ng-container slot="header">Customer commitment:</ng-container>
    {{ item().customerCommitmentDate | pwDateTime }}
  </pw-label>
  <pw-label [inLine]="true">
    <ng-container slot="header">Internal deadline:</ng-container>
    {{ item().internalDeadLine | pwDateTime }}
  </pw-label></pw-priority-overview-base
>
