import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CrmForm, CrmSystemName } from '@pwiz/on-boarding/ts';
import {
  pwFormComponentProvider,
  PwFormComponentV2,
  PwValidators,
  ToFormGroup,
} from '@pwiz/infra/form';
import { FormBuilder } from '@angular/forms';
import {
  NextStepDirective,
  PreviousStepDirective,
  StepButtonsComponent,
} from '@pwiz/on-boarding/ui';
import { MatStepperModule } from '@angular/material/stepper';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import { pwTakeUntilDestroyed } from '@pwiz/infra/ui';
import { tap } from 'rxjs';

type IForm = ToFormGroup<CrmForm>;

@Component({
  selector: 'pw-crm-on-boarding',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    StepButtonsComponent,
    PreviousStepDirective,
    NextStepDirective,
    MatStepperModule,
  ],
  templateUrl: './crm-on-boarding.component.html',
  styleUrls: ['./crm-on-boarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [pwFormComponentProvider(CrmOnBoardingComponent)],
})
export class CrmOnBoardingComponent implements PwFormComponentV2<IForm> {
  #onBoardingService = inject(OnBoardingService);
  form = this.#initForm();
  name = 'crm';

  constructor() {
    this.#onBoardingService
      .hasCrm$()
      .pipe(
        pwTakeUntilDestroyed(),
        tap((hasCrm) => this.form.setValue({ connected: hasCrm || null }))
      )
      .subscribe();
  }

  runCD(): void {
    throw new Error('Method not implemented.');
  }

  validate(): void {
    throw new Error('Method not implemented.');
  }
  onSelected(hubspot: CrmSystemName) {
    this.#onBoardingService.selectedCrmSystem(hubspot);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<IForm>({
      connected: fb.control(false),
    });
  }
}
