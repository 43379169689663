import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { useHostBinding } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-drawer-footer',
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      padding: 0 var(--pw-distance-4) var(--pw-distance-4);
    }

    :host(.align-end) {
      display: flex;
      justify-content: end;
    }

    :host(.align-start) {
      display: flex;
      justify-content: start;
    }
  `,
  template: `<ng-content />`,
})
export class PwDrawerFooterComponent {
  align = input<'start' | 'end'>('end');

  constructor() {
    const { conditionalClass } = useHostBinding();
    effect(() => {
      conditionalClass(`align-start`, this.align() === 'start');
      conditionalClass(`align-end`, this.align() === 'end');
    });
  }
}
