import { SALECEFORCE_API_ENDPONT_MAP, SALECEFORCE_API_ENDPONT, SalesforceFieldIdValuePairsWithPickListValues } from '@pwiz/crm/salesforce/ts';
import { HttpClient } from '@angular/common/http';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SalesforceSettingsService {
  #http = inject(HttpClient);
  #url = useBaseApiUrl(SALECEFORCE_API_ENDPONT);
  getFieldsSalesforce() {
    return this.#http.get<SalesforceFieldIdValuePairsWithPickListValues[]>(`${this.#url}/${SALECEFORCE_API_ENDPONT_MAP.accountFields}`)
  }
}



