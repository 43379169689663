import { Pipe, PipeTransform } from '@angular/core';
import { getIssueItem, getIssueItemOrFail } from '@pwiz/ticket/ts';
import { IItem } from '@pwiz/entity/ts';

@Pipe({
  name: 'pwIssueItem',
  standalone: true,
})
export class IssueItemPipe implements PipeTransform {
  transform(...value: Parameters<typeof getIssueItemOrFail>): IItem | null {
    return getIssueItem(...value);
  }
}
