import { Route } from '@angular/router';
import { ON_BOARDING_URL } from './on-boarding-url';
import { OnBoardingStepsComponent } from '../on-boarding-steps/on-boarding-steps.component';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import { OrganizationSettingsPageService } from '@pwiz/global-settings/ui-data';

export const ON_BOARDING_ROUTE: Route = {
  path: ON_BOARDING_URL,
  providers: [OnBoardingService, OrganizationSettingsPageService],
  loadComponent: () =>
    import('../on-boarding-layout/on-boarding-layout.component').then(
      (m) => m.OnBoardingLayoutComponent,
    ),
  loadChildren: () => [
    {
      path: ':step',
      component: OnBoardingStepsComponent,
    },
    {
      path: '**',
      pathMatch: 'full',
      redirectTo: 'task-management',
    },
  ],
};
