const ignoreTags = ['INPUT', 'TEXTAREA'];
const ignoreClasses = ['mat-mdc-overlay'];
const ignoreAttributes = ['data-ignore-global-search'];

export function adminLayoutShouldFocusSearch(event: KeyboardEvent) {
  /** Remove the window, document, html & body elements */
  const path = event.composedPath().slice(0, -4) as HTMLElement[];
  return !path.some(
    (target) =>
      ignoreTags.includes(target.tagName) ||
      ignoreClasses.some((cls) => target.classList.contains(cls)) ||
      ignoreAttributes.some((atr) => target.hasAttribute(atr))
  );
}
