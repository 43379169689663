<pw-settings-default-assignee-header>
</pw-settings-default-assignee-header>
<div class="pw-flex pw-center">
  <pw-settings-default-assignee
    [users]="users()"
    [providerByPriority]="providerTypeByPriority"
    [value]="$value()?.defaultAssignee"
  />
</div>
<p>{{engineerCostText[0]}}</p>
<pw-settings-engineer-form
  [matTooltip]="engineerCostText[1]"
  [value]="$value()?.averageEngineerCost"
/>

<pw-step-buttons>
  <button
    mat-button
    pwOnBoardingButtonsPreviousStep
    matStepperPrevious
    color="primary"
  >
    Previous
  </button>
  <button
    mat-raised-button
    pwOnBoardingButtonsNextStep
    matStepperNext
    color="primary"
    [disabled]="form.invalid"
    (click)="onNext()"
  >
    Finish
  </button>
</pw-step-buttons>
