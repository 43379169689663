import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { OrganizationService } from '@pwiz/organization/ui-data';
import { OnboardingStage } from '@pwiz/entity/ts';
import { ON_BOARDING_URL } from './on-boarding-url';
import { mapOrganizationOnboardingStep } from '@pwiz/on-boarding/ts';

export function onBoardingGuard(
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> {
  const orgService = inject(OrganizationService);
  return orgService.organization$.pipe(
    map((org) => {
      if (org.onboardingStage === OnboardingStage.FINISHED) {
        return true;
      }
      return createUrlTreeFromSnapshot(route, [
        ON_BOARDING_URL,
        mapOrganizationOnboardingStep(org.onboardingStage),
      ]);
    })
  );
}
