<pw-priority-overview-base
  [priority]="priority()"
  [isReadOnly]="isReadOnly()"
  [users]="users()"
  (updatePriority)="updatePriority.emit($event)"
>
  <pw-label [inLine]="true">
    <pw-label-header>Severity:</pw-label-header>
    {{ priority().level | pwEnumToReadableString }}
  </pw-label>
</pw-priority-overview-base>
