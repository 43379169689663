import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiItemWithSla } from '@pwiz/item/ts';
import { ItemPriorityComponent } from '../item-priority/item-priority.component';
import { PwNullableValuePipe, RectangleSkeletonComponent } from '@pwiz/infra/ui';
import { ItemDaysOpenPipe } from '../pipe/item-days-open.pipe';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ItemIdComponent } from '@pwiz/item-common/ui';
import { IsItemPriorityOverriddenPipe, ItemSystemPriorityPipe } from '../pipe';
import { PwUser } from '@pwiz/user/ts';
import { IAuditLog } from '@pwiz/entity/ts';
import { ItemPriorityUiComponent } from '../item-priority/item-priority-ui.component';

@Component({
  selector: 'pw-item-glance',
  standalone: true,
  imports: [
    CommonModule,
    ItemIdComponent,
    ItemPriorityComponent,
    PwNullableValuePipe,
    ItemDaysOpenPipe,
    MatIconButton,
    MatIcon,
    IsItemPriorityOverriddenPipe,
    ItemPriorityUiComponent,
    ItemSystemPriorityPipe,
    RectangleSkeletonComponent
  ],
  templateUrl: './item-glance.component.html',
  styleUrl: './item-glance.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemGlanceComponent {
  item = input.required<UiItemWithSla | null>();
  userList = input.required<PwUser[] | null>();
  itemPriorityChangeAuditEvent = input.required<IAuditLog | null>();
  priorityClick = output();
}
