import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwDateTimePipe, PwNullableValuePipe } from '@pwiz/infra/ui';
import { SprintView } from '@pwiz/sprint/ts';
import { SprintStatusComponent } from '../sprint-status/sprint-status.component';
import { SprintNamePipe } from '../pipe';

@Component({
  selector: 'pw-sprint-glance-view',
  standalone: true,
  imports: [
    CommonModule,
    PwDateTimePipe,
    SprintStatusComponent,
    PwNullableValuePipe,
    SprintNamePipe,
  ],
  templateUrl: './sprint-glance-view.component.html',
  styleUrls: ['./sprint-glance-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-border pw-padding',
  },
})
export class SprintGlanceViewComponent {
  today = new Date();
  sprint = input.required<SprintView>();
  count = computed(() => this.sprint().count);
}
