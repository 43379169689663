@if (item(); as item) {
  <h5 class="summary">
    <pw-item-id
      class="item-id"
      [item]="item"
      [showType]="true"
      mode="title"
      [disableLink]="true"
    />
  </h5>
  <span
    class="extra-small-icon-button pw-flex pw-center-vr pw-gap"
    (click)="priorityClick.emit()"
  >
    <h6 class="priority">Priority:</h6>
    <pw-item-priority [item]="item" showManual [auditLog]="itemPriorityChangeAuditEvent()" [users]="userList()"/>
    <button mat-icon-button><mat-icon style="font-size: 1.2rem;">edit</mat-icon></button>
    @if (item | pwIsItemPriorityOverridden) {
      <p>PWiZ recommendation:</p>
      <pw-item-priority-ui [priority]="item | pwItemSystemPriority"/>
    }
  </span>
  <h6 class="sla">
    {{ item.type === 'feature' ? 'TTD' : 'SLA' }}:
    <span [class]="item.slo?.alert">{{
      item.slo?.status | pwNullableValue
    }}</span>
  </h6>
  @if ((item | pwItemDaysOpen) !== null) {
    <h6>Days since created: {{ item | pwItemDaysOpen }}</h6>
  }
} @else {
  <pw-rectangle-skeleton class="summary" height="4rem" />
}
