import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import {
  SprintAddItemDialogItemChangeEvent,
  SprintDetails,
  SprintEditableTableItem,
  SprintItemChange,
  sprintItemDetailsToSprintEditableItem,
  SprintItemSearchRemoveEvent,
} from '@pwiz/sprint/ts';
import {
  filter,
  map,
  merge,
  Observable,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { toPwCache } from '@pwiz/infra/cache/ts';
import { toSignal } from '@angular/core/rxjs-interop';
import { CurrentSprintService } from '@pwiz/sprint/ui-data';
import {
  SprintEditItemsLayoutComponent,
  SprintItemSelectComponent,
  SprintItemSelectTableComponent,
} from '@pwiz/sprint/ui';
import { MatButton } from '@angular/material/button';
import { useSprintEditAddItemDialog } from '../sprint-edit-add-item.dialog.component';
import { useSubject } from '@pwiz/infra/hooks';
import { addMap, filterNotNull } from '@pwiz/infra/ts';
import { BaseEntityUtils } from '@pwiz/entity/ts';
import { useSprintRemoveItemDialog } from '../sprint-edit-remove-item.dialog.component';
import { UiTableDataProviderComponent } from '@pwiz/infra/table';

@Component({
  selector: 'pw-sprint-edit-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'sprint-edit-items.component.html',
  host: {
    class: 'pw-scroll-container',
  },
  imports: [
    SprintItemSelectComponent,
    SprintItemSelectTableComponent,
    SprintEditItemsLayoutComponent,
    MatButton,
    UiTableDataProviderComponent,
  ],
})
export class SprintEditItemsComponent {
  #currentSprintService = inject(CurrentSprintService);
  #openAddItemDialog = useSprintEditAddItemDialog();
  #openRemoveItemDialog = useSprintRemoveItemDialog();

  sprint = input<SprintDetails>();
  itemsChange = output<SprintAddItemDialogItemChangeEvent>();

  addItems$ = useSubject<void>();
  #removeItems$ = useSubject<void>();
  #manualItemRemoved$ = useSubject<SprintEditableTableItem>();
  #recommendationRemoved$ = useSubject<SprintEditableTableItem>();
  #sprintChange$ = this.#expectedSprintChange$();
  #removedManualItems$ = this.#allRemovedItems(this.#manualItemRemoved$);
  $changedItems = toSignal(this.#systemRecommendation$(), {
    requireSync: true,
  });
  $manualItems = toSignal(this.#manualAddedItems$(), {
    requireSync: true,
  });

  #getSprintChange(manualItems: SprintEditableTableItem[]) {
    return this.#currentSprintService
      .getSprintChangeBeforeEdit(manualItems)
      .pipe(toPwCache<SprintItemChange>());
  }

  #expectedSprintChange$() {
    return this.#manualItems$().pipe(
      startWith([]),
      addMap((manualItems) => this.#getSprintChange(manualItems)),
      shareReplay(1),
    );
  }

  #systemRecommendation$() {
    return this.#sprintChange$.pipe(
      map(([, cacheRes]) => ({
        ...cacheRes,
        data: cacheRes.data?.systemItems || [],
      })),
    );
  }

  #manualAddedItems$() {
    return this.#sprintChange$.pipe(
      map(([manualItems, cacheRes]) => ({
        ...cacheRes,
        data: this.#combineManualAddedItemsWithEditSprintRes(
          cacheRes.data,
          manualItems,
        ),
      })),
    );
  }

  #combineManualAddedItemsWithEditSprintRes(
    cacheRes: SprintItemChange | null,
    manualItems: SprintEditableTableItem[],
  ) {
    if (!cacheRes) {
      return [];
    }
    return cacheRes.sprintItems
      .map((sprintItem) => {
        const { entity } = BaseEntityUtils.findById(
          manualItems,
          sprintItem.item.id,
        );
        if (!entity) {
          return null;
        }
        if (entity.action === 'remove') {
          return entity;
        }
        return sprintItemDetailsToSprintEditableItem(sprintItem);
      })
      .filter(filterNotNull);
  }

  onRemoveItem() {
    this.#removeItems$.next();
  }

  onAddItem() {
    this.addItems$.next();
  }

  #getManuallyAddedItems() {
    return this.addItems$.pipe(
      switchMap(() => this.#openAddItemDialog()),
      filter(({ ok, response }) => ok && !!response),
      map(({ response }) => response!.selectedItems),
    );
  }

  #getManuallyRemovedItems() {
    return this.#removeItems$.pipe(
      switchMap(() => this.#openRemoveItemDialog()),
      filter(({ ok, response }) => ok && !!response),
      map(({ response }) => response!.removedItems),
    );
  }

  #dialogItems$() {
    return merge(
      this.#getManuallyAddedItems(),
      this.#getManuallyRemovedItems(),
    ).pipe(
      map((manualItems) => <SprintEditableTableItem[]>[...manualItems]),
      shareReplay(1),
    );
  }

  #rejectedRecommendations() {
    return this.#allRemovedItems(
      this.#recommendationRemoved$.asObservable(),
    ).pipe(
      map((recommendationList) =>
        recommendationList.map(
          ({ action, ...item }) =>
            <SprintEditableTableItem>{
              ...item,
              action: action === 'add' ? 'remove' : 'add',
            },
        ),
      ),
    );
  }

  #manualItems$() {
    return this.#dialogItems$().pipe(
      addMap(() => this.#rejectedRecommendations()),
      map(([manualItems, recommendations]) => [
        ...manualItems,
        ...recommendations,
      ]),
      scan((allAddedItems, newAddedItems) => [
        ...allAddedItems,
        ...newAddedItems,
      ]),
      addMap(() => this.#removedManualItems$),
      map(([manualItems, manualItemToRemove]) => {
        /**
         * Because each item can be added and removed multiple times. Each item in the manualItemToRemove
         * array removes only 1 item from the result
         */
        const notRemovedItems = [...manualItems];
        for (const item of manualItemToRemove) {
          const { index } = BaseEntityUtils.findById(notRemovedItems, item.id);
          if (index === null) {
            continue;
          }
          notRemovedItems.splice(index, 1);
        }
        return BaseEntityUtils.removeDuplicationsById(notRemovedItems);
      }),
      tap((itemList) =>
        this.itemsChange.emit({
          itemList,
        }),
      ),
    );
  }

  #allRemovedItems(source: Observable<SprintEditableTableItem>) {
    return source.pipe(
      scan<SprintEditableTableItem, SprintEditableTableItem[]>(
        (allRemoved, newRemoved) => [...allRemoved, newRemoved],
        [],
      ),
      startWith(<SprintEditableTableItem[]>[]),
      shareReplay(1),
    );
  }

  onManualItemsChange({ item }: SprintItemSearchRemoveEvent) {
    this.#manualItemRemoved$.next(item);
  }

  onRemoveRecommendation({ item }: SprintItemSearchRemoveEvent) {
    this.#recommendationRemoved$.next(item);
  }
}
