import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { getImpactAccounts, UiPriority } from '@pwiz/priority/ts';
import {
  PwLabelComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomerListComponent } from '@pwiz/account/ui';
import { PwUser } from '@pwiz/user/ts';
import { PriorityOverviewBaseComponent } from '../priority-overview-base.component';
import { PriorityOverviewUpdatePriorityEvent } from '../priority-overview-update-priority-event';

@Component({
  selector: 'pw-priority-impact-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwNullableValuePipe,
    PwLabelComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    PwLabelHeaderComponent,
    CustomerListComponent,
    PriorityOverviewBaseComponent,
  ],
  templateUrl: 'priority-impact-overview.component.html',
  styleUrl: './priority-impact-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-priority-impact-overview',
  },
})
export class PriorityImpactOverviewComponent {
  priority = input.required<UiPriority>();
  $accounts = computed(() => getImpactAccounts(this.priority()));
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  updatePriority = output<PriorityOverviewUpdatePriorityEvent>();
}
