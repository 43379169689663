import { Injectable } from '@angular/core';

@Injectable()
export class PriorityOverviewEventsService {
  #handlePrioritize!: () => void;

  prioritize() {
    this.#handlePrioritize();
  }

  onPrioritize(callback: () => void) {
    this.#handlePrioritize = callback;
  }
}
