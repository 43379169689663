import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'pw-slack-auth',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <router-outlet />`,
  imports: [RouterOutlet],
})
export class SlackAuthContainerComponent {}
