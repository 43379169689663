<mat-form-field>
  <mat-label>Search boards</mat-label>
  <input matInput [(ngModel)]="$search" />
  <pw-clear-search [(search)]="$search" matIconSuffix/>
</mat-form-field>
<pw-my-boards-list
  [myBoards]="$boards() | pwFilter: ['name', 'displayId'] : $search()" class="board-settings"
>
  <ng-template pwMyBoardsListContent let-board>
    <button
      mat-icon-button
      (click)="toggle(board)"
      [ngClass]="{favorite: $boardMap().get(board.id)}"
    >
      <mat-icon>star</mat-icon>
    </button>
  </ng-template>
</pw-my-boards-list>
