import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pw-navigation-sidebar-title',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: `navigation-sidebar-title.component.scss`,
  template: ` <ng-content /> `,
})
export class NavigationSidebarTitleComponent {}
