@if (item(); as item) {
  <pw-label-group inLine [showSeparators]="true">
    <pw-label>
      <pw-label-header>Assignee</pw-label-header>
      {{item.providerAssignee?.name || 'Unassigned'}}
    </pw-label>
    <pw-label>
      <pw-label-header>Item type</pw-label-header>
      {{item.type | pwEnumToReadableString}}
    </pw-label>
    <pw-label>
      <pw-label-header>Item ID</pw-label-header>
      @if (item) {
        <pw-item-id [item]="item" mode="itemId" />
      }
    </pw-label>
    <pw-label>
      <pw-label-header>Jira type</pw-label-header>
      <span>{{$issue()?.tmsMetaData?.originalType}}</span>
    </pw-label>
    <pw-label>
      <pw-label-header>Status</pw-label-header>
      <p>{{ item?.status | pwEnumToReadableString }}</p>
    </pw-label>
    <pw-label>
      <pw-label-header>Board</pw-label-header>
      <p>{{ item.ticketBase.board?.name }}</p>
    </pw-label>
  </pw-label-group>
}
@else{
  <pw-rectangle-skeleton height="4rem"/>
  }
