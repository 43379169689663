import { IItem, ISettings } from '@pwiz/entity/ts';
import { ItemSla } from '@pwiz/item/ts';
import { calcItemSla } from '@pwiz/item-common/ts';

export function toItemSla<T extends IItem>(
  item: T,
  settings: ISettings,
): ItemSla {
  return {
    slo: calcItemSla(item, settings) || undefined,
  };
}
