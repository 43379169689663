import { Pipe, PipeTransform } from '@angular/core';
import { findUser, PwUser } from '@pwiz/user/ts';

@Pipe({
  name: 'pwFindUser',
  standalone: true,
})
export class FindUserPipe implements PipeTransform {
  transform(userId?: string | null, usersList?: PwUser[] | null) {
    return findUser(userId, usersList);
  }
}
