import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  Route,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SlackAuthComponent } from './slack-auth.component';
import { SlackAuthResComponent } from './slack-auth-res.component';
import { SlackAuthContainerComponent } from './slack-auth-container.component';
import { $useOrgHasSlack } from '@pwiz/organization/ui-data';
import SlackPageComponent from './slack-page.component';
import { SlackSettingsComponent } from './slack-settings.component';

export const SLACK_ROUTE: Route = {
  path: 'slack',
  component: SlackPageComponent,
  children: [
    {
      path: 'auth',
      component: SlackAuthContainerComponent,
      canActivate: [canActivateAuth],
      children: [
        {
          path: 'error',
          component: SlackAuthResComponent,
          data: { isError: true },
        },
        {
          path: 'success',
          component: SlackAuthResComponent,
          data: { isError: false },
        },
        {
          path: '',
          component: SlackAuthComponent,
        },
      ],
    },
    {
      path: '',
      canActivate: [canActivateSettings],
      component: SlackSettingsComponent,
    },
  ],
};

function canActivateSettings(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | UrlTree {
  if ($useOrgHasSlack()()) {
    return true;
  }
  return createUrlTreeFromSnapshot(route, ['auth']);
}

function canActivateAuth(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | UrlTree {
  if (!$useOrgHasSlack()()) {
    return true;
  }
  return createUrlTreeFromSnapshot(route, ['../']);
}
