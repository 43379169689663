import { eItemPriority, ePriorityType } from '@pwiz/entity/ts';
import {
  Persona,
  PrioritiesForPersonas,
} from './count-priorities-for-personas';
import {
  mapFilterToPriorityPageParams,
  PriorityTableFilter,
} from '@pwiz/priorities/pages/ts';
import { SlackMessageBlock } from '../../../../type';
import { toPriorityDisplayType } from '@pwiz/priority/ts';
import { getDisplayPriority, ITEM_PRIORITIES } from '@pwiz/item/ts';
import { createSlackLink } from '../util/create-slack-link';
import { filterNotNull } from '@pwiz/infra/ts';

const PRIORITIES_TO_EXCLUDE = [eItemPriority.none, eItemPriority.skipped];

export function createOutstandingPrioritiesMessageBlocks(
  persona: Persona,
  personaCounts: PrioritiesForPersonas,
  filters: PriorityTableFilter,
  uiUrl: string,
): SlackMessageBlock[] {
  const header: SlackMessageBlock = createSubHeader(persona);
  const count = personaCounts[persona];
  const prioritiesToInclude = ITEM_PRIORITIES.filter(
    (p) => !PRIORITIES_TO_EXCLUDE.find((ep) => ep === p),
  );
  const content = createRow(
    prioritiesToInclude,
    count,
    persona,
    filters,
    uiUrl,
  );

  return content ? [header, content] : [header];
}

function createRow(
  prioritiesToInclude: eItemPriority[],
  counts: Record<eItemPriority, number>,
  persona: Persona,
  filters: PriorityTableFilter,
  uiUrl: string,
): SlackMessageBlock | null {
  const priorityCountArr = prioritiesToInclude
    .map((priority) => ({
      priority: getDisplayPriority(priority),
      count: counts[priority],
    }))
    .filter(({ count }) => count != null);
  const total = priorityCountArr.reduce<number>(
    (acc, { count }) => (acc += count),
    0,
  );
  if (priorityCountArr.length === 0) {
    return null;
  }
  const form = {
    pm: ePriorityType.urgency,
    em: ePriorityType.effort,
    cs: ePriorityType.impact,
  }[persona];
  const formName = toPriorityDisplayType(form);
  const filterParams = mapFilterToPriorityPageParams({
    ...filters,
    type: form,
    priority: prioritiesToInclude,
  });
  const priorityBreakDown = priorityCountArr
    .map(({ priority, count }, index, arr) => [
      {
        type: 'text',
        text: `${priority} (${count})`,
        style: {
          code: true,
        },
      },
      index < arr.length - 1
        ? {
            type: 'text',
            text: ', ',
          }
        : null,
    ])
    .reduce((acc, byPriority) => {
      acc.push(...byPriority);
      return acc;
    }, [])
    .filter(filterNotNull);
  return {
    type: 'rich_text',
    elements: [
      {
        type: 'rich_text_section',
        elements: [
          {
            type: 'text',
            text: `${total} ${total > 1 ? 'bugs' : 'bug'} in `,
          },
          ...priorityBreakDown,
          {
            type: 'text',
            text: ' - ',
          },
          createSlackLink(`Add ${formName}`, uiUrl, filterParams),
        ],
      },
    ],
  };
}

function createSubHeader(persona: 'pm' | 'em' | 'cs'): SlackMessageBlock {
  const icon = {
    pm: ':magic_wand:',
    em: ':man-lifting-weights::skin-tone-2:',
    cs: ':trophy:',
  }[persona];
  const personaName = {
    pm: 'PM',
    em: 'Dev',
    cs: 'Customer Success',
  }[persona] as string;
  return {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `${icon} |   *Bugs needing ${personaName} Attention*  | ${icon}`,
    },
  };
}
