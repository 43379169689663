import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { usePwToaster } from '@pwiz/infra/dialog';
import { CurrentSprintService } from '@pwiz/sprint/ui-data';
import { SprintNamePipe } from '@pwiz/sprint/ui';
import { MatButton } from '@angular/material/button';
import {
  SprintAddItemDialogItemChangeEvent,
  SprintEditableTableItem,
} from '@pwiz/sprint/ts';
import {
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerFooterComponent,
  PwDrawerHeaderComponent,
  PwDrawerService,
  usePwDrawerData,
} from '@pwiz/infra/drawer';
import { SprintEditItemsComponent } from '../components/sprint-edit-items/sprint-edit-items.component';

@Component({
  selector: 'pw-sprint-add-item-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'sprint-edit.drawer.component.html',
  imports: [
    SprintNamePipe,
    MatButton,
    SprintEditItemsComponent,
    PwDrawerContainerComponent,
    PwDrawerHeaderComponent,
    PwDrawerContentComponent,
    PwDrawerFooterComponent,
  ],
})
export class SprintItemEditDrawerComponent {
  #currentSprintService = inject(CurrentSprintService);
  #toaster = usePwToaster();
  #drawer = inject(PwDrawerService);

  itemsToAdd: SprintEditableTableItem[] = [];
  $sprint = computed(() => this.#currentSprintService.$sprint().data);

  onSave() {
    this.#currentSprintService.editSprint(this.itemsToAdd).subscribe({
      next: () => this.#onSaveSuccess(),
      error: () => this.#onSaveFail(),
    });
  }

  #onSaveSuccess() {
    this.#drawer.closeDrawer();
    this.#toaster.successes(
      `Added ${this.itemsToAdd.length} items to ${this.$sprint.name}`,
    );
  }

  #onSaveFail() {
    this.#toaster.error(
      `Couldn't add ${this.itemsToAdd.length} items to ${this.$sprint.name}`,
    );
  }

  onItemsChange({ itemList }: SprintAddItemDialogItemChangeEvent) {
    this.itemsToAdd = itemList;
  }
}
