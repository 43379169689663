import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPriority } from '@pwiz/priority/ts';
import {
  CurrencyComponent,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
} from '@pwiz/infra/ui';
import { PriorityOverviewBaseComponent } from '../priority-overview-base.component';
import { PwUser } from '@pwiz/user/ts';
import { PriorityOverviewUpdatePriorityEvent } from '../priority-overview-update-priority-event';

@Component({
  selector: 'pw-priority-effort-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelComponent,
    CurrencyComponent,
    PwEnumToReadableStringPipe,
    PriorityOverviewBaseComponent,
  ],
  templateUrl: './priority-effort-overview.component.html',
  styleUrl: 'priority-effort-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityEffortOverviewComponent {
  itemEstimatedCost = input.required<number | null>();
  priority = input.required<UiPriority>();
  isReadOnly = input.required<boolean>();
  users = input.required<PwUser[] | null>();
  updatePriority = output<PriorityOverviewUpdatePriorityEvent>();
}
