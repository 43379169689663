import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { PwizLogoComponent } from '../pwiz-logo/pwiz-logo.component';

@Component({
  selector: 'pw-header',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterLink,
    PwizLogoComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  isProfileMenuOpes = signal(false);

  @Input({ required: true })
  user!: Signal<User | null | undefined>;

  @Output()
  logout = new EventEmitter<void>();

  @Output()
  menuIconClicked = new EventEmitter<void>();

  onLogout() {
    this.logout.emit();
  }

  onProfileOpen() {
    this.isProfileMenuOpes.set(true);
  }

  onProfileClose() {
    this.isProfileMenuOpes.set(false);
  }

  onMenuIconClicked() {
    this.menuIconClicked.emit();
  }
}
