import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { OrganizationService } from '@pwiz/organization/ui-data';
import { CurrentUserService } from '@pwiz/user/ui-data';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { APP_PROVIDER_ITEM_SUMMARY_SERVICE } from '../provider/item-summary-provider';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'pw-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [APP_PROVIDER_ITEM_SUMMARY_SERVICE],
  imports: [NgxSpinnerComponent, RouterOutlet],
  styles: `
    :host {
      display: block;
      height: 100%;
    }
  `,
  template: `
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="ball-8bits"
      [fullScreen]="true"
    >
      <p style="color: white">Loading...</p>
    </ngx-spinner>

    <router-outlet />
  `,
})
export class AppComponent {
  #authService = inject(AuthService);
  #orgService = inject(OrganizationService);
  #currentUser = inject(CurrentUserService);
  #spinner = inject(NgxSpinnerService);
  constructor() {
    this.#spinner.show();
    this.#authService.isAuthenticated$
      .pipe(
        takeUntilDestroyed(),
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() =>
          combineLatest([
            this.#orgService.organization$,
            this.#currentUser.user$(),
          ]),
        ),
        take(1),
      )
      .subscribe(() => this.#spinner.hide());
  }
}
