import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { map, shareReplay, startWith } from 'rxjs';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  getOnBoardingStep,
  getOnBoardingStepIndex,
  OnBoardingForm,
} from '@pwiz/on-boarding/ts';
import { TaskManagementOnBoardingComponent } from '../task-management-on-boarding/task-management-on-boarding.component';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import {
  NgFormGeneralType,
  pwFormAggregator,
  PwFormComponentV2,
  ToFormGroup,
} from '@pwiz/infra/form';
import { FormGroup } from '@angular/forms';
import { CrmOnBoardingComponent } from '../crm-on-boarding/crm-on-boarding.component';
import { SlaOnBoardingComponent } from '../sla-on-boarding/sla-on-boarding.component';
import { TShirtSizeOnBoardingComponent } from '../t-shirt-size-on-boarding/t-shirt-size-on-boarding.component';
import { AssigneeOnBoardingComponent } from '../assignee-on-boarding/assignee-on-boarding.component';

type IForm = ToFormGroup<OnBoardingForm>;

@Component({
  selector: 'pw-on-boarding-steps',
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    TaskManagementOnBoardingComponent,
    CrmOnBoardingComponent,
    SlaOnBoardingComponent,
    TShirtSizeOnBoardingComponent,
    AssigneeOnBoardingComponent,
  ],
  templateUrl: './on-boarding-steps.component.html',
  styleUrls: ['./on-boarding-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnBoardingStepsComponent implements AfterViewInit {
  #activatedRoute = inject(ActivatedRoute);
  #formComponentControls!: PwFormComponentV2<IForm>;
  #onBoardingService = inject(OnBoardingService);
  #router = inject(Router);

  #cd = inject(ChangeDetectorRef);

  form = new FormGroup<IForm>({} as IForm);
  step$ = this.#activatedRoute.paramMap.pipe(
    map((paramMap) => getOnBoardingStepIndex(paramMap.get('step')))
  ); // TODO signal - there is an issue that the step is set before the step is valid so the step isn't activated
  completedIndex$ = this.#onBoardingService
    .completedIndex$()
    .pipe(startWith(0), shareReplay(1)); // TODO signal

  @ViewChildren(PwFormComponentV2)
  forms!: QueryList<PwFormComponentV2<NgFormGeneralType>>;

  ngAfterViewInit() {
    this.#formComponentControls = pwFormAggregator(this.forms, this.form);
    this.form.statusChanges.subscribe(() => this.#cd.detectChanges());
  }

  onSelectionChange({ selectedIndex }: StepperSelectionEvent) {
    this.#router.navigate(['/on-boarding', getOnBoardingStep(selectedIndex)]);
    this.#syncOrgOnEveryStepChange();
  }

  #syncOrgOnEveryStepChange() {
    this.#onBoardingService.reFetchOrg();
  }
}
