import { Route } from '@angular/router';
import { DASHBOARD_DRAWER_ROUTE_URL_MAP } from '@pwiz/dashboard/ts';

export const DASHBOARD_DRAWER_ROUTES: Route[] = [
  /** Looks like auxiliary routes don't work well with nested routes*/
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.outstandingRisk,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-outstanding-risk-drawer.component'),
  },
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.removedRisk,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-removed-risk-drawer.component'),
  },
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.slaBreach,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-sla-breach-drawer.component'),
  },
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.criticalBugs,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-critical-bugs-drawer.component'),
  },
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.dealBreakers,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-deal-breaker-drawer.component'),
  },
  {
    path: DASHBOARD_DRAWER_ROUTE_URL_MAP.customerTier,
    loadComponent: () =>
      import('./bug/drawer/dashboard-bug-customer-tier-drawer.component'),
  },
];
