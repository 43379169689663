<mat-form-field class="search">
  <mat-label>Search boards</mat-label>
  <input matInput [formControl]="searchControl">
  @if ($canClearSearch()) {
    <button matSuffix mat-icon-button (click)="clearSearch()" type="button">
      <mat-icon>clear</mat-icon>
    </button>
  }
</mat-form-field>

<table mat-table [dataSource]="dataSource" class="small-form-fields">
  <ng-container matColumnDef="board">
    <th mat-header-cell *matHeaderCellDef>Team</th>
    <td mat-cell *matCellDef="let row">
      {{row.team}}
    </td>
  </ng-container>

  <ng-container matColumnDef="velocity">
    <th mat-header-cell *matHeaderCellDef>Velocity</th>
    <td mat-cell *matCellDef="let row">
      <mat-form-field class="features">
        <input
          type="number"
          matInput
          [formControl]="row.form.controls.velocity"
        />
        <span matSuffix>(sp)</span>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="feature">
    <th mat-header-cell *matHeaderCellDef>Feature</th>
    <td mat-cell *matCellDef="let row">
      <mat-form-field class="features">
        <input
          type="number"
          matInput
          [formControl]="row.form.controls.mix.controls.features"
        />
        <span matSuffix>%</span>
<!--        <mat-error>-->
<!--          @if (row.form.controls.mix.controls.features.errors; as errors) {-->
<!--            @if (errors['required']) {-->
<!--              This field is required-->
<!--            } @else {-->
<!--              Invalid percent-->
<!--            }-->
<!--          }-->
<!--        </mat-error>-->
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="bug">
    <th mat-header-cell *matHeaderCellDef>Bug</th>
    <td mat-cell *matCellDef="let row">
      <mat-form-field class="features">
        <input
          type="number"
          matInput
          [formControl]="row.form.controls.mix.controls.bugs"
        />
        <span matSuffix>%</span>
<!--        <mat-error>-->
<!--          @if (row.form.controls.mix.controls.bugs.errors; as errors) {-->
<!--            @if (errors['required']) {-->
<!--              This field is required-->
<!--            } @else {-->
<!--              Invalid percent-->
<!--            }-->
<!--          }-->
<!--        </mat-error>-->
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="open">
    <th mat-header-cell *matHeaderCellDef>Buffer</th>
    <td mat-cell *matCellDef="let row">
      <mat-form-field class="features">
        <input
          type="number"
          matInput
          [formControl]="row.form.controls.mix.controls.other"
        />
        <span matSuffix>%</span>
<!--        <mat-error>-->
<!--          @if (row.form.controls.mix.controls.other.errors; as errors) {-->
<!--            @if (errors['required']) {-->
<!--              This field is required-->
<!--            } @else {-->
<!--              Invalid percent-->
<!--            }-->
<!--          }-->
<!--        </mat-error>-->
      </mat-form-field>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
</table>



<!--<ng-container [formGroup]="form">-->
<!--  &lt;!&ndash;  <pw-settings-column class="sprint-dates-duration">&ndash;&gt;-->
<!--  &lt;!&ndash;    <ng-container slot="header">Duration & Start date</ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;      <mat-form-field class="sprint-duration">&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-label>Sprint Duration</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;        <input util="number" matInput [formControl]="form.controls.duration" />&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-error>Must be grater than 1 day</mat-error>&ndash;&gt;-->
<!--&lt;!&ndash;        <span matSuffix>Days</span>&ndash;&gt;-->
<!--&lt;!&ndash;      </mat-form-field>&ndash;&gt;-->

<!--&lt;!&ndash;      <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-label>Start from</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;        <input&ndash;&gt;-->
<!--&lt;!&ndash;          matInput&ndash;&gt;-->
<!--&lt;!&ndash;          [min]="tomorrow"&ndash;&gt;-->
<!--&lt;!&ndash;          [matDatepicker]="picker"&ndash;&gt;-->
<!--&lt;!&ndash;          [formControl]="form.controls.startDate"&ndash;&gt;-->
<!--&lt;!&ndash;        />&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-datepicker-toggle matIconSuffix [for]="picker" />&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-datepicker #picker />&ndash;&gt;-->
<!--&lt;!&ndash;      </mat-form-field>&ndash;&gt;-->

<!--  <p>Sprint Mix</p>-->
<!--  <div class="sprint-mix pw-flex pw-gap">-->
<!--    <mat-form-field class="features">-->
<!--      <mat-label>Features</mat-label>-->
<!--      <input-->
<!--        util="number"-->
<!--        matInput-->
<!--        [formControl]="form.controls.mix.controls.features"-->
<!--      />-->
<!--      <span matSuffix>%</span>-->
<!--      <mat-error *ngIf="form.controls.mix.controls.features.invalid"-->
<!--        >Invalid percent</mat-error-->
<!--      >-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="bugs">-->
<!--      <mat-label>Bugs</mat-label>-->
<!--      <input-->
<!--        util="number"-->
<!--        matInput-->
<!--        [formControl]="form.controls.mix.controls.bugs"-->
<!--      />-->
<!--      <span matSuffix>%</span>-->
<!--      <mat-error *ngIf="form.controls.mix.controls.bugs.invalid"-->
<!--        >Invalid percent</mat-error-->
<!--      >-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="other">-->
<!--      <mat-label>Open</mat-label>-->
<!--      <input-->
<!--        util="number"-->
<!--        matInput-->
<!--        [formControl]="form.controls.mix.controls.other"-->
<!--      />-->
<!--      <span matSuffix>%</span>-->
<!--      <mat-error *ngIf="form.controls.mix.controls.other.invalid"-->
<!--        >Invalid percent</mat-error-->
<!--      >-->
<!--    </mat-form-field>-->
<!--  </div>-->

<!--  <mat-form-field class="velocity">-->
<!--    <mat-label>-->
<!--      Team Total Working Days-->
<!--    </mat-label>-->
<!--    <input matInput util="number" [formControl]="form.controls.velocity"/>-->
<!--    <mat-error>-->
<!--      @if (form.controls.velocity.errors; as errors) {-->
<!--        @if (errors['required']) {-->
<!--          This field is required-->
<!--        } @else {-->
<!--          Value mast be grater than 0-->
<!--        }-->
<!--      }-->
<!--    </mat-error>-->
<!--  </mat-form-field>-->
<!--</ng-container>-->
