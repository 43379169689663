import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSelectSearchComponent } from '@pwiz/base/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SprintPageFilterFormComponent } from '@pwiz/sprint/ui';
import {
  AUTO_SPRINT_PAGE_STATUSES,
  COMMITTED_SPRINT_PAGE_STATUSES,
  SprintFilterContainer,
  SprintPageFilter,
} from '@pwiz/sprint/ts';
import { provideParentControl } from '@pwiz/infra/form-2/ui';
import { SprintPageService, SprintService } from '@pwiz/sprint/ui-data';
import { useSyncFormAndParams } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-sprint-page-filters',
  standalone: true,
  imports: [
    CommonModule,
    BasicSelectSearchComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    SprintPageFilterFormComponent,
  ],
  templateUrl: './sprint-page-filters.component.html',
  styleUrl: './sprint-page-filters.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideParentControl()],
})
export class SprintPageFiltersComponent {
  #sprintService = inject(SprintService);
  #sprintPageService = inject(SprintPageService);
  $teams = this.#sprintService.$sprintTeams;
  $pageStatuses = $usePageStatuses();
  filter = this.#sprintPageService.filter;
  form = new FormGroup<SprintFilterContainer>({});

  constructor() {
    useSyncFormAndParams(
      this.filter.$filters,
      this.form,
      ({ filter }) => this.filter.setFilters(filter as SprintPageFilter),
      'filter',
    );
  }
}

function $usePageStatuses() {
  const $isAutoSprintPage = inject(SprintPageService).$isAutoSprintPage;
  return computed(() => {
    const isAutoSprintPage = $isAutoSprintPage();
    return mapToIdValuePair(
      isAutoSprintPage
        ? AUTO_SPRINT_PAGE_STATUSES
        : COMMITTED_SPRINT_PAGE_STATUSES,
    );
  });
}

function mapToIdValuePair(statuses: Readonly<string[]>) {
  return statuses.map((status) => ({ id: status, value: status }));
}
