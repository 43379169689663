import { ItemSummaryDialogService } from '@pwiz/item/ts';
import { inject, Provider } from '@angular/core';
import { DrawerRoutingService } from '@pwiz/infra/drawer';

class ItemSummaryDialogAdapterService implements ItemSummaryDialogService {
  drawerRouting = inject(DrawerRoutingService);

  openItemSummary(itemId: string) {
    this.drawerRouting.itemSummary(itemId);
  }
}

export const ItemPageEventHandlerProvider: Provider = {
  provide: ItemSummaryDialogService,
  useClass: ItemSummaryDialogAdapterService,
};
