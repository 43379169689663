import { computed, inject } from '@angular/core';
import { OrganizationService } from './organization.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { PwCacheOptions } from '@pwiz/infra/cache/ts';
import { eProviderType } from '@pwiz/entity/ts';

function $useOrgCache(options?: PwCacheOptions) {
  return toSignal(inject(OrganizationService).org$(options), {
    requireSync: true,
  });
}

/**
 * @description Get org. Non-nullable. Can be used under root.
 * @warning This is unsafe to use at the root of the app.
 * @param options
 */
export function $useOrg(options?: PwCacheOptions) {
  const $cache = $useOrgCache(options);
  return computed(() => $cache().data!);
}

export function $useOrgHasSlack() {
  const $org = $useOrg();
  return computed(
    () =>
      !!$org().providers.find(
        ({ providerType }) => providerType === eProviderType.chat,
      ),
  );
}
