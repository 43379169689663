import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SprintService } from '@pwiz/sprint/ui-data';
import { $useTeams } from '@pwiz/team/ui-data';
import { ISprintSettings } from '@pwiz/entity/ts';
import { SprintSettingsDrawerComponent } from './sprint-settings-drawer.component';
import { PwToasterService } from '@pwiz/infra/dialog';

@Component({
  selector: 'pw-sprint-settings-global',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <pw-sprint-settings-drawer
      [teamList]="$teamList()"
      (save)="onSave($event)"
    />
  `,
  imports: [SprintSettingsDrawerComponent],
})
export class SprintSettingsGlobalDrawerComponent {
  #toaster = inject(PwToasterService);
  #sprintService = inject(SprintService);
  $teamList = $useTeams();

  onSave(sprintSettings: ISprintSettings) {
    this.#sprintService.saveSprintSettings(sprintSettings).subscribe({
      next: () =>
        this.#toaster.successes('Sprint settings saved successfully', {
          duration: 'medium',
        }),
      error: () =>
        this.#toaster.error('Failed to save sprint settings', {
          duration: 'medium',
        }),
    });
  }
}

export default SprintSettingsGlobalDrawerComponent;
