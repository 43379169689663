import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPriority } from '@pwiz/priority/ts';
import {
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwNullableValuePipe,
  PwYesNoPipe,
} from '@pwiz/infra/ui';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomerListComponent } from '@pwiz/account/ui';
import { IItem } from '@pwiz/entity/ts';
import { PwUser } from '@pwiz/user/ts';
import { PriorityOverviewBaseComponent } from '../priority-overview-base.component';
import { PriorityOverviewUpdatePriorityEvent } from '../priority-overview-update-priority-event';

@Component({
  selector: 'pw-priority-confidence-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelComponent,
    PwYesNoPipe,
    PwDateTimePipe,
    PwEnumToReadableStringPipe,
    MatIconModule,
    MatTooltipModule,
    PwNullableValuePipe,
    CustomerListComponent,
    PriorityOverviewBaseComponent,
  ],
  templateUrl: './priority-confidence-overview.component.html',
  styleUrl: 'priority-confidence-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityConfidenceOverviewComponent {
  priority = input.required<UiPriority>();
  item = input.required<IItem>();
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  updatePriority = output<PriorityOverviewUpdatePriorityEvent>();
}
