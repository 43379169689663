import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ItemsSearchRowComponent } from '@pwiz/layout/ui';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';
import { SprintItemSearch } from '@pwiz/sprint/ts';
import { SprintItemSelectRowComponent } from './sprint-item-select-row.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-sprint-item-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatAutocompleteTrigger,
    MatInput,
    ReactiveFormsModule,
    ItemsSearchRowComponent,
    MatOption,
    MatAutocomplete,
    MatLabel,
    SprintItemSelectRowComponent,
  ],
  templateUrl: `sprint-item-select.component.html`,
  styleUrl: `sprint-item-select.component.scss`,
})
export class SprintItemSelectComponent {
  itemSelected = output<SprintItemSearch>();
  search = output<string | null>();

  control = new FormControl<string | null>(null);

  items = input<SprintItemSearch[] | null>();
  loadingStatus = input.required<PwCacheStatus>();

  constructor() {
    this.control.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((search) => this.search.emit(search));
  }
  onOptionSelected({ option }: MatAutocompleteSelectedEvent) {
    if (!option) {
      return;
    }
    this.itemSelected.emit(option.value);
    this.control.reset();
  }
}
