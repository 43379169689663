import { SalesforceCompanyRes } from '../type';
import {
  eAccountStatus,
  IAccountDto,
  IProvider,
  IProviderCrmSettings,
  IProviderUser,
  ISalesforceAccountStatus,
  ISettings,
} from '@pwiz/entity/ts';
import { getAccountFieldsFromAccountQuery } from './get-fields-for-account-query';
import { calcAccountTier } from '@pwiz/account/ts';

export function salesforceCompanyToAccount(
  sfCompany: SalesforceCompanyRes,
  provider: IProvider,
  providerUsers: IProviderUser[],
  crmSettings: IProviderCrmSettings,
  settings: ISettings,
): IAccountDto {
  const company = getAccountFieldsFromAccountQuery(crmSettings, sfCompany);
  const arr =
    company.annualRevenue != null
      ? Math.round(company.annualRevenue || 0)
      : Math.round((company.monthlyRevenue || 0) * 12);
  return {
    name: company.name,
    providerId: provider.id,
    organizationId: provider.organizationId,
    arr,
    idInProvider: sfCompany.Id,
    status: getCustomerStatus(crmSettings, company.status),
    assignedCs: findProviderUserBuName(providerUsers, company.assignedCs),
    renewalDate: company.renewalDate,
    owner: findProviderUser(providerUsers, company.ownerId),
    tier: calcAccountTier(arr, settings),
  };
}

function findProviderUser(
  providerUsers: IProviderUser[],
  id: string | null,
): IProviderUser | undefined {
  if (id) {
    return (
      providerUsers.find(({ idInProvider }) => idInProvider === id) || undefined
    );
  }
  return undefined;
}

function findProviderUserBuName(
  providerUsers: IProviderUser[],
  userName: string | null,
): IProviderUser | undefined {
  if (userName) {
    const searchName = userName.toLowerCase();
    return (
      providerUsers.find(({ name }) => name?.toLowerCase() === searchName) ||
      undefined
    );
  }
  return undefined;
}

const map: Record<ISalesforceAccountStatus, eAccountStatus> = {
  lead: eAccountStatus.prospect,
  'active-customer': eAccountStatus.customer,
  'inactive-customer': eAccountStatus.inactiveCustomer,
};

function getCustomerStatus(
  crmSettings: IProviderCrmSettings,
  status: string | null,
): eAccountStatus {
  if (!status) {
    return eAccountStatus.customer;
  }
  if (
    !crmSettings.valueMapAccountStatus ||
    !crmSettings.valueMapAccountStatus[status]
  ) {
    return eAccountStatus.customer;
  }
  const pwStatus = crmSettings.valueMapAccountStatus[status];
  return map[pwStatus];
}
