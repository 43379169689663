import { useQueryParams } from '@pwiz/infra/query-params/data';
import { computed } from '@angular/core';
import { ITeam } from '@pwiz/entity/ts';
import { queryParamsToString } from '@pwiz/infra/ts';
import { $useTeams } from '@pwiz/team/ui-data';

export function useSprintTeams() {
  const $id = $useSprintTeamId();
  const $boards = $useTeams();
  return computed(() => findTeam($id() ? [$id()!] : [], $boards() || []));
}

export function $useSprintTeamId() {
  const params = useQueryParams().value;
  return computed(() => queryParamsToString(params()['teams']));
}

function findTeam(teamId: string[], teams: ITeam[]) {
  return teams.filter(({ id }) => teamId.includes(id));
}
