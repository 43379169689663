import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'pw-slack-connect',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButton],
  host: {
    class:
      'pw-scroll-container pw-padding pw-padding-top pw-gap-l pw-center pw-center-text',
  },
  template: `
    <h6>
      You don't have slack connected.<br />
      Connect Slack to get notifications!
    </h6>
    <span class="pw-flex pw-center">
      <button mat-raised-button color="accent" (click)="connect.emit()">
        Connect Slack
      </button>
    </span>
  `,
})
export class SlackConnectComponent {
  connect = output<void>();
}
