import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  DialogCloseDirective,
  DialogContentComponent,
  DialogFooterComponent,
  DialogFooterDirective,
  DialogHeaderComponent,
  DialogV2Component,
  PwDialogService,
  useCloseDialogFinished,
} from '@pwiz/infra/dialog';
import { MatButton } from '@angular/material/button';
import {
  SprintItemSelectComponent,
  SprintItemSelectTableComponent,
} from '@pwiz/sprint/ui';
import {
  SprintEditableTableItem,
  SprintItemSearch,
  SprintItemSearchRemoveEvent,
} from '@pwiz/sprint/ts';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NEVER, switchMap } from 'rxjs';
import { CurrentSprintService } from '@pwiz/sprint/ui-data';
import { toPwCache } from '@pwiz/infra/cache/ts';
import { UiTableDataProviderComponent } from '@pwiz/infra/table';

@Component({
  selector: 'pw-sprint-edit-add-item-drawer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogV2Component,
    DialogHeaderComponent,
    DialogContentComponent,
    DialogFooterComponent,
    MatButton,
    DialogCloseDirective,
    DialogFooterDirective,
    SprintItemSelectComponent,
    UiTableDataProviderComponent,
    SprintItemSelectTableComponent,
  ],
  host: {
    class: 'pw-scroll-container',
  },
  template: `
    <pw-dialog-v2 size="l">
      <pw-dialog-header>Add Items to sprint</pw-dialog-header>
      <pw-dialog-content noTopMargin>
        <div
          style="height: var(--pw-distance-80)"
          class="pw-scroll-container pw-gap"
        >
          <pw-sprint-item-select
            (search)="onSearch($event)"
            [items]="$items().data || []"
            [loadingStatus]="$items().status"
            (itemSelected)="onItemSelected($event)"
            class="pw-padding-top pw-padding pw-padding-s"
          />
          <pw-ui-table-data-provider
            [rowData]="$selectedItems()"
            fetchStatus="success"
          >
            <pw-sprint-item-select-table
              canRemove
              hideSprintEffort
              (removeItem)="onRemoveItem($event)"
            />
          </pw-ui-table-data-provider>
        </div>
      </pw-dialog-content>
      <pw-dialog-footer>
        <button
          pwDialogFooter="start"
          mat-stroked-button
          color="accent"
          pwDialogClose
        >
          Cancel
        </button>
        <button
          pwDialogFooter="end"
          mat-raised-button
          color="primary"
          (click)="onAdd()"
        >
          Add
        </button>
      </pw-dialog-footer>
    </pw-dialog-v2>
  `,
})
export class SprintEditAddItemDialogComponent {
  #currentSprintService = inject(CurrentSprintService);
  #closeDialog = useCloseDialogFinished();

  $search = signal<string | null>(null);
  $items = toSignal(this.#$getItems(), { requireSync: true });
  $selectedItems = signal<SprintEditableTableItem[]>([]);

  onSearch(search: string | null) {
    this.$search.set(search);
  }

  #$getItems() {
    const search$ = toObservable(this.$search);
    return search$.pipe(
      switchMap((search) => this.#getItemsForSearch$(search)),
      toPwCache('none'),
    );
  }

  #getItemsForSearch$(search: string | null) {
    if (!search || search.length < 3) {
      return NEVER;
    }
    return this.#currentSprintService.searchItems$(search);
  }

  onItemSelected(item: SprintItemSearch) {
    this.$selectedItems.update((items) => [
      ...items,
      { ...item, action: 'add', sprintEffort: null },
    ]);
  }

  onAdd() {
    this.#closeDialog({
      ok: true,
      response: { selectedItems: this.$selectedItems() },
    });
  }

  onRemoveItem({ item }: SprintItemSearchRemoveEvent) {
    this.$selectedItems.update((items) =>
      items.filter(({ id }) => id !== item.id),
    );
  }
}

export function useSprintEditAddItemDialog() {
  const dialog = inject(PwDialogService);
  return () =>
    dialog.openV3<never, { selectedItems: SprintEditableTableItem[] }>(
      SprintEditAddItemDialogComponent,
      {},
    ).finish$;
}
