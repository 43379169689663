import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SettingsTShirtSizeFormComponent,
  SettingsTShirtSizeHeaderComponent,
  StoryPointMappingComponent,
  StoryPointsMappingHeaderComponent,
} from '@pwiz/settings/ui';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import {
  NextStepDirective,
  PreviousStepDirective,
  StepButtonsComponent,
} from '@pwiz/on-boarding/ui';
import {
  NgFormGeneralType,
  pwFormAggregator,
  pwFormComponentProvider,
  PwFormComponentV2,
  ToFormGroup,
} from '@pwiz/infra/form';
import { OnBoardingTicketForm } from '@pwiz/on-boarding/ts';
import { FormGroup } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

type IForm = ToFormGroup<OnBoardingTicketForm>;

@Component({
  selector: 'pw-t-shirt-size-on-boarding',
  standalone: true,
  imports: [
    CommonModule,
    SettingsTShirtSizeFormComponent,
    SettingsTShirtSizeHeaderComponent,
    StoryPointMappingComponent,
    StoryPointsMappingHeaderComponent,
    MatButtonModule,
    MatStepperModule,
    NextStepDirective,
    PreviousStepDirective,
    StepButtonsComponent,
  ],
  templateUrl: './t-shirt-size-on-boarding.component.html',
  styleUrls: ['./t-shirt-size-on-boarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [pwFormComponentProvider(TShirtSizeOnBoardingComponent)],
})
export class TShirtSizeOnBoardingComponent
  implements PwFormComponentV2<IForm>, AfterViewInit
{
  #onBoardingService = inject(OnBoardingService);
  #formComponentControls!: PwFormComponentV2<IForm>;

  form = new FormGroup<IForm>({} as IForm);
  name = 'ticket';
  $value = toSignal(this.#onBoardingService.getTShirtSizeStepForm$());

  @ViewChildren(PwFormComponentV2)
  forms!: QueryList<PwFormComponentV2<NgFormGeneralType>>;

  ngAfterViewInit() {
    this.#formComponentControls = pwFormAggregator(this.forms, this.form);
  }

  runCD(): void {
    this.#formComponentControls.runCD?.();
  }

  validate(): void {
    this.#formComponentControls.validate?.();
  }

  onNext() {
    this.#onBoardingService.saveTicketForm(
      this.form.value as OnBoardingTicketForm,
    );
  }
}
