import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyBoardsComponent } from '../my-boards/my-boards.component';
import { HeaderPageNameComponent } from '@pwiz/layout/feature';
import { MyTeamsComponent } from '../my-teams/my-teams.component';

@Component({
  selector: 'pw-user-user-preferences-page',
  standalone: true,
  imports: [
    CommonModule,
    MyBoardsComponent,
    HeaderPageNameComponent,
    MyTeamsComponent,
  ],
  templateUrl: './user-preferences-page.component.html',
  styleUrl: './user-preferences-page.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-flex pw-gap-xxl',
  },
})
export class UserPreferencesPageComponent {}
