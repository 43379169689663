<pw-drawer-container size="l">
  <pw-drawer-header>
    Item Summary
  </pw-drawer-header>
  <pw-drawer-content class="pw-flex pw-column pw-gap-xl">
    <pw-item-glance [item]="$item()" (priorityClick)="onPriorityClick()" [userList]="$users()" [itemPriorityChangeAuditEvent]="$itemManualPriorityChangEvent()"/>
    <pw-item-stats [item]="$item()"/>
    <pw-item-overview [item]="$item()" [$settings]="$settings()"/>
    <pw-item-priority-overview [item]="$item()" (viewPriority)="onViewPriority($event)" [users]="$users()"/>
    <div>
      <h6>Ticket breakdown</h6>
      <pw-sprint-item-schedule [itemSchedule]="$itemSchedule()" [team]="$team()" readonly/>
    </div>
  </pw-drawer-content>
</pw-drawer-container>
