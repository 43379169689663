import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { SlackChannelsComponent, SlackInfoComponent } from '@pwiz/chat/ui';
import { provideParentControl } from '@pwiz/infra/form-2/ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  SlackSettingsFormContainer,
  SlackSettingsFormValue,
} from '@pwiz/chat/ts';
import { SlackService } from '@pwiz/chat/ui-data';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { usePwToaster } from '@pwiz/infra/dialog';

@Component({
  selector: 'pw-slack-settings',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pw-container pw-gap-l' },
  imports: [
    SlackInfoComponent,
    SlackChannelsComponent,
    ReactiveFormsModule,
    MatButton,
  ],
  providers: [provideParentControl()],
  template: `
    <pw-slack-info />
    <form
      [formGroup]="form"
      (ngSubmit)="saveSettings()"
      class="pw-container pw-gap-inherit"
    >
      <pw-slack-channels
        [formGroup]="form"
        [channels]="$channels()"
        class="pw-flex-1"
      />
      <div class="pw-flex pw-justify-end">
        <button mat-raised-button type="submit" color="primary">Save</button>
      </div>
    </form>
  `,
})
export class SlackSettingsComponent implements OnInit {
  #toaster = usePwToaster();
  #slackService = inject(SlackService);
  form = new FormGroup<SlackSettingsFormContainer>(
    {} as SlackSettingsFormContainer,
  );

  ngOnInit() {
    this.#slackService.getSettings().subscribe((settings) => {
      this.form.setValue({
        slackSettings: {
          stakeholdersChannel: settings.stakeholdersChannel,
          mgmtChannel: settings.mgmtChannel,
          customerFacingTeamsChannel: settings.customerFacingTeamsChannel,
        },
      });
    });
  }

  $channels = toSignal(this.#slackService.getChannels(), {
    initialValue: null,
  });

  saveSettings() {
    const { slackSettings } = this.form.value;
    if (slackSettings) {
      this.#save(slackSettings as SlackSettingsFormValue);
    }
  }

  #save(slackSettings: SlackSettingsFormValue) {
    this.#slackService
      .saveSettings(slackSettings as SlackSettingsFormValue)
      .subscribe({
        next: () => this.#toaster.successes('Slack settings saved'),
        error: () => this.#toaster.error('Failed to save Slack settings'),
      });
  }
}
