<pw-settings-t-shirt-size-header></pw-settings-t-shirt-size-header>
<pw-settings-t-shirt-size-form
  type="bug"
  [value]="$value()?.bugTShirtSizes"
>
</pw-settings-t-shirt-size-form>
<pw-story-points-mapping-header></pw-story-points-mapping-header>
<pw-story-point-mapping [value]="$value()?.effortMap"></pw-story-point-mapping>


<pw-step-buttons>
  <button
    mat-button
    pwOnBoardingButtonsPreviousStep
    matStepperPrevious
    color="primary"
  >
    Previous
  </button>
  <button
    mat-raised-button
    pwOnBoardingButtonsNextStep
    matStepperNext
    color="primary"
    [disabled]="form.invalid"
    (click)="onNext()"
  >
    Next
  </button>
</pw-step-buttons>
