import { Pipe, PipeTransform } from '@angular/core';
import { IItem } from '@pwiz/entity/ts';
import { mapItemScoreToPriority } from '@pwiz/priority/ts';

@Pipe({
  name: 'pwItemSystemPriority',
  standalone: true,
})
export class ItemSystemPriorityPipe implements PipeTransform {
  transform(value: IItem) {
    return mapItemScoreToPriority(value, value.priorityScore);
  }
}
