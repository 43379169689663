import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriorityOverviewComponentInterface } from '../priority-overview-component-interface';
import { UiPriority } from '@pwiz/priority/ts';
import { ItemSummary } from '@pwiz/item/summary/ts';
import {
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwNullableValuePipe,
  PwYesNoPipe,
} from '@pwiz/infra/ui';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomerListComponent } from '@pwiz/account/ui';
import { PwUser } from '@pwiz/user/ts';
import { PriorityConfidenceOverviewComponent } from '@pwiz/priority/ui';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'pw-confidence-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelComponent,
    PwYesNoPipe,
    PwDateTimePipe,
    PwEnumToReadableStringPipe,
    MatIconModule,
    MatTooltipModule,
    PwNullableValuePipe,
    CustomerListComponent,
    PriorityConfidenceOverviewComponent,
    MatButton,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<pw-priority-confidence-overview
    [priority]="priority()"
    [users]="users()"
    [isReadOnly]="isReadOnly()"
    [item]="item()"
    (updatePriority)="updatePriority()()"
  /> `,
})
export class ConfidenceOverviewComponent
  implements PriorityOverviewComponentInterface
{
  priority = input.required<UiPriority>();
  item = input.required<ItemSummary>();
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  updatePriority = input.required<() => void>();
}
