import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemPriorityComponent } from '@pwiz/item/ui';
import { IssueItemPipe, RectangleSkeletonComponent } from '@pwiz/infra/ui';
import { TicketIdComponent } from '@pwiz/ticket/ui';
import { IIssue } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-items-search-row',
  standalone: true,
  imports: [
    CommonModule,
    ItemPriorityComponent,
    RectangleSkeletonComponent,
    IssueItemPipe,
    TicketIdComponent,
  ],
  templateUrl: './items-search-row.component.html',
  styleUrls: ['./items-search-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-flex pw-center-vr pw-gap-l',
  },
})
export class ItemsSearchRowComponent {
  issue = input<IIssue>();
  loading = input<boolean>(false);
}
