import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ItemSummary } from '@pwiz/item/summary/ts';
import { PwEnumToReadableStringPipe, PwOnInitSignal } from '@pwiz/infra/ui';
import { isItemDone } from '@pwiz/item/ts';
import { sortPriorities, UiPriority } from '@pwiz/priority/ts';
import { PriorityOverviewComponent } from './priority-overview/priority-overview.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ePriorityStatus, ePriorityType } from '@pwiz/entity/ts';
import { PriorityScorePipe } from '@pwiz/priority/ui';
import { PwUser } from '@pwiz/user/ts';

@Component({
  selector: 'pw-item-priority-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    PriorityOverviewComponent,
    MatExpansionModule,
    PwEnumToReadableStringPipe,
    PriorityScorePipe,
  ],
  templateUrl: './item-priority-overview.component.html',
  styleUrl: './item-priority-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemPriorityOverviewComponent extends PwOnInitSignal {
  item = input.required<ItemSummary | null>();
  newPriority = ePriorityStatus.new;
  $item_safe = computed(() => (this.$didInit() ? this.item() : null));
  $isDone = computed(() => {
    const item = this.$item_safe();
    return item ? isItemDone(item) : false;
  });
  $priorities = computed(() => {
    const item = this.$item_safe();
    return item && sortPriorities<UiPriority>(item?.priorities);
  });
  users = input.required<PwUser[] | null>();
  domainMap: Record<ePriorityType, string> = {
    [ePriorityType.impact]: 'Customer',
    [ePriorityType.urgency]: 'Product',
    [ePriorityType.confidence]: 'Product',
    [ePriorityType.effort]: 'R&D',
  };

  viewPriority = output<{ priority: UiPriority }>();

  onPriorityClick(event: { priority: UiPriority }) {
    this.viewPriority.emit(event);
  }
}
