import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { UiPriority } from '@pwiz/priority/ts';
import { FindUserPipe } from '@pwiz/user/ui';
import {
  $useLoaderStatus,
  PwDateTimePipe,
  PwLabelComponent,
  PwLabelGroupComponent,
  PwLabelHeaderComponent,
  RectangleSkeletonComponent,
  StatusLoadingDirective,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';
import { ePriorityStatus } from '@pwiz/entity/ts';
import { PwUser } from '@pwiz/user/ts';
import { MatButton } from '@angular/material/button';
import { PriorityOverviewUpdatePriorityEvent } from './priority-overview-update-priority-event';

@Component({
  selector: 'pw-priority-overview-base',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-priority-overview-base pw-flex pw-column pw-gap',
  },
  template: `
    <pw-status-switch-case [status]="status()">
      <pw-rectangle-skeleton height="20" pwStatusLoading />

      <pw-label-group childrenStyle="row">
        <pw-label>
          <pw-label-header>Assignee:</pw-label-header>
          {{ priority().providerAssignee?.name }}
        </pw-label>
        <pw-label>
          <pw-label-header>Scored:</pw-label-header>
          {{
            $isScoredBySystem()
              ? 'System'
              : (priority().scoredBy | pwFindUser: users())?.name
          }}
          at {{ priority().scoredDate | pwDateTime }}
        </pw-label>
        <ng-content />
        @if (priority().reason) {
          <pw-label>
            <ng-container slot="header">Reason:</ng-container>
            {{ priority().reason }}
          </pw-label>
        }
      </pw-label-group>
    </pw-status-switch-case>
    @if (!isReadOnly()) {
      <div>
        <button
          mat-raised-button
          color="primary"
          class="update-priority-button"
          (click)="updatePriority.emit({ priority: priority() })"
        >
          {{ priority().score !== null ? 'Update Priority' : 'Prioritize' }}
        </button>
      </div>
    }
  `,
  imports: [
    FindUserPipe,
    PwDateTimePipe,
    PwLabelComponent,
    PwLabelHeaderComponent,
    PwLabelGroupComponent,
    MatButton,
    StatusSwitchCaseComponent,
    RectangleSkeletonComponent,
    StatusLoadingDirective,
  ],
})
export class PriorityOverviewBaseComponent {
  priority = input.required<UiPriority>();
  $isScoredBySystem = computed(
    () => this.priority().status === ePriorityStatus.autoRated,
  );
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  status = $useLoaderStatus();

  updatePriority = output<PriorityOverviewUpdatePriorityEvent>();
}
