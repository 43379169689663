import { Route } from '@angular/router';
import { BOARD_SETTINGS_ROUTE } from '@pwiz/board/feature';
import { TEAM_SETTINGS_ROUTE } from '@pwiz/team/feature';

export function getSettingsRoute(...subRoutes: Route[]): Route {
  return {
    path: 'settings',
    loadComponent: () => import('./layout/settings-layout-page.component'),
    children: [
      {
        path: 'org',
        loadComponent: () =>
          import(
            './organization-settings-page/organization-settings-page.component'
          ),
      },
      ...subRoutes,
      BOARD_SETTINGS_ROUTE,
      TEAM_SETTINGS_ROUTE,
      {
        path: '**',
        redirectTo: 'org',
        pathMatch: 'full',
      },
    ],
  };
}
