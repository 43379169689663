import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SprintPageService,
  SprintService,
  useSprintTeams,
} from '@pwiz/sprint/ui-data';
import { HeaderPageNameComponent } from '@pwiz/layout/feature';
import { SprintsViewComponent } from '@pwiz/sprint/ui';
import { RouterLink } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProjectDirective } from '@pwiz/infra/content-projection/feature';
import { MatButtonModule } from '@angular/material/button';
import { MenuTriggerComponent } from '@pwiz/infra/ui';
import { MatMenuModule } from '@angular/material/menu';
import { useOpenSprintSettings } from '../hooks/use-open-sprint-settings';
import { SprintPageFiltersComponent } from './sprint-page-filters/sprint-page-filters.component';
import { filter, take } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { useTeams$ } from '@pwiz/team/ui-data';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-sprint-page',
  standalone: true,
  imports: [
    CommonModule,
    HeaderPageNameComponent,
    SprintsViewComponent,
    RouterLink,
    MatSlideToggleModule,
    ProjectDirective,
    MatButtonModule,
    MenuTriggerComponent,
    MatMenuModule,
    SprintPageFiltersComponent,
    MatIconModule,
  ],
  templateUrl: './sprint-page.component.html',
  styleUrls: ['./sprint-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SprintPageService],
  host: {
    class: 'pw-scroll',
  },
})
export class SprintPageComponent {
  openGlobalSettings = useOpenSprintSettings();
  #sprintPageService = inject(SprintPageService);
  #sprintService = inject(SprintService);

  $isAutoSprintPage = this.#sprintPageService.$isAutoSprintPage;
  $sprints = toSignal(this.#sprintPageService.$getSprints(), {
    requireSync: true,
  });
  $selectedBoard = useSprintTeams();

  constructor() {
    const {
      filter: { $filters },
    } = this.#sprintPageService;
    const initialBoardId = $filters().teams;
    if (!initialBoardId || !initialBoardId.length) {
      this.useSelectFirstTeam();
    }
  }

  openGeneralSprintSetting() {
    this.openGlobalSettings(null, null);
  }

  useSelectFirstTeam() {
    toObservable(this.#sprintService.$sprintTeams)
      .pipe(
        filter((teams) => teams.length > 0),
        take(1),
      )
      .subscribe(([team]) => {
        team && this.#sprintPageService.filter.setFilters({ teams: team.id });
      });
  }
}
