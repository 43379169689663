import { Route } from '@angular/router';
import {
  PRIORITIES_ARCHIVE_URL,
  PRIORITIES_NEW_URL,
  PRIORITIES_URL,
} from '@pwiz/priority/ts';

const PRIORITIES_HISTORY_PAGE_ROUTE: Route = {
  path: PRIORITIES_ARCHIVE_URL,
  loadComponent: () =>
    import('./priorities-history-page/priorities-history-page.component'),
};

const NEW_PRIORITIES_PAGE_ROUTE: Route = {
  path: PRIORITIES_NEW_URL,
  loadComponent: () =>
    import('./priorities-new-page/priorities-new-page.component'),
};

export const PRIORITIES_ROUTES: Route = {
  path: PRIORITIES_URL,
  children: [NEW_PRIORITIES_PAGE_ROUTE, PRIORITIES_HISTORY_PAGE_ROUTE],
};
