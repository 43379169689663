import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CustomerNavComponent } from './customer-nav.component';
import { PmNavComponent } from './pm-nav.component';
import { StrategyNavComponent } from './strategy-nav.component';

@Component({
  selector: 'pw-navigation-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    CustomerNavComponent,
    PmNavComponent,
    StrategyNavComponent,
  ],
  styleUrls: ['./navigation-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      padding-top: var(--pw-distance-2);
      padding-inline: var(--pw-distance-2);
      width: var(--pw-sidebar-width, var(--pw-distance-42));
      height: 100%;
      background: linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
      --pw-icon-color: var(--pw-background-color);
      transition: width var(--pw-animation);
    }
  `,
  template: `
    <pw-customer-nav />
    <pw-strategy-nav />
    <pw-pm-nav [newPrioritiesCount]="newPrioritiesCount()" />
  `,
})
export class NavigationSidebarComponent {
  isProd = input.required<boolean>();
  newPrioritiesCount = input.required<number | null>();
}
