<mat-form-field subscriptSizing="dynamic">
  <mat-label>Status</mat-label>
  <mat-select [formControl]="form.controls.status" placeholder="All statuses" multiple>
    @for (status of $statusArr(); track status) {
      <mat-option [value]="status.id">{{status.value | pwEnumToReadableString}}</mat-option>
    }
  </mat-select>
</mat-form-field>

<mat-form-field subscriptSizing="dynamic">
  <mat-label>Teams</mat-label>
  <pw-basic-select-search
    [formControl]="form.controls.teams"
    [data]="teams() | pwIdValuePair"
    [multiple]="false"
    placeholder="All teams"
    searchInputPlaceholder="Search board"
  />
</mat-form-field>
