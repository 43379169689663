import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'pw-slack-info',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pw-flex pw-center-vr pw-gap' },
  template: `
    <mat-icon>info</mat-icon>
    PWiZ Slack bot is used to send daily notifications with the status of your
    current projects.
  `,
  imports: [MatIcon],
})
export class SlackInfoComponent {}
