<mat-form-field>
  <mat-label>Search teams</mat-label>
  <input matInput [(ngModel)]="$search" />
  @if ($search().length > 0) {
    <button matSuffix mat-icon-button (click)="$search.set('')">
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>
<pw-team-list
  [teams]="
    $teams()
      | pwFilter: 'name' : $search()
      | pwFilterTeamByBoards: $userPref()?.myBoards || []
  "
  (action)="favoriteTeam($event)"
  interactable
  class="pw-scroll"
>
  <button
    mat-icon-button
    *pwTeamListContent="let team"
    [ngClass]="{ favorite: $teamMap()[team.id] }"
  >
    <mat-icon>star</mat-icon>
  </button>
</pw-team-list>
