import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { IItem } from '@pwiz/entity/ts';
import { RectangleSkeletonComponent } from '@pwiz/infra/ui';
import { ItemIdComponent } from '@pwiz/item-common/ui';

@Component({
  selector: 'pw-sprint-item-select-row',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      display: block;
      min-width: var(--pw-distance-36);
      height: var(--pw-distance-8);
    }
  `,
  template: `
    @if (loading()) {
      <pw-rectangle-skeleton />
    } @else {
      <p class="pw-flex pw-gap-l">
        <pw-item-id
          [item]="item()"
          mode="itemId"
          disableLink
          class="pw-flex-1"
        />
        -
        <pw-item-id
          [item]="item()"
          mode="title"
          disableLink
          [showType]="false"
        />
      </p>
    }
  `,
  imports: [RectangleSkeletonComponent, ItemIdComponent],
})
export class SprintItemSelectRowComponent {
  item = input<IItem | null>(null);
  loading = input(false, { transform: booleanAttribute });
}
