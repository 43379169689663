import { inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';
import { PwCacheEntity } from '@pwiz/infra/cache/ts';
import {
  isItemManualPriorityOverridden,
  UiItemWithPriorities,
} from '@pwiz/item/ts';
import { NEVER, of } from 'rxjs';
import { eItemPriority, IItem } from '@pwiz/entity/ts';

@Injectable()
export class ItemPriorityChangeService {
  #itemService = inject(ItemService);
  #cache = new PwCacheEntity<UiItemWithPriorities>(null, NEVER);

  getItem$(itemId: string) {
    this.#cache.setDatasource(itemId, this.#itemService.getItemSummary(itemId));
    return this.#cache.getV2$();
  }

  changeItemPriority(item: IItem, manualPriority: eItemPriority) {
    if (item.priority === manualPriority) {
      return of(item);
    }
    return this.#itemService.overridePriority(item.id, {
      newPriority: manualPriority,
    });
  }

  rollback(item: IItem) {
    if (!isItemManualPriorityOverridden(item)) {
      return of(null);
    }
    return this.#itemService.rollbackOverridePriority(item.id);
  }
}
