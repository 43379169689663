import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  contentChild,
  effect,
  input,
  output,
} from '@angular/core';
import { ITeam } from '@pwiz/entity/ts';
import { TeamListContentDirective } from './team-list-content.directive';
import { NgTemplateOutlet } from '@angular/common';
import { BooleanInput } from '@angular/cdk/coercion';
import { useHostBinding } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-team-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet],
  host: { class: 'pw-team-list' },
  template: `
    @for (team of teams(); track team.id) {
      <div class="pw-flex pw-center-vr row" (click)="action.emit(team)">
        <span class="pw-flex-1">{{ team.name }}</span>
        <ng-container
          [ngTemplateOutlet]="content().template"
          [ngTemplateOutletContext]="{ $implicit: team }"
        />
      </div>
    }
  `,
  styles: `
    :host(.interactable) {
      .row {
        border-radius: var(--pw-border-radius);
        padding: 0 var(--pw-distance-4);
        min-height: var(--pw-distance-12);
        &:hover,
        &:focus-within {
          background-color: var(--pw-nutral-light-color);
          outline: var(--pw-accent-color) 1px solid;
        }
      }
    }
  `,
})
export class TeamListComponent {
  action = output<ITeam>();
  content = contentChild.required<TeamListContentDirective>(
    TeamListContentDirective,
  );
  interactable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });
  teams = input.required<ITeam[]>();

  constructor() {
    const { conditionalClass } = useHostBinding();
    effect(() => {
      conditionalClass('interactable', this.interactable());
    });
  }
}
