import { HttpClient } from '@angular/common/http';
import { PROVIDER_CRM_SETTINGS_API } from '@pwiz/provider/ts';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { Injectable, inject } from '@angular/core';
import { ProviderCrmSettingsDTO } from '@pwiz/entity/ts';

@Injectable({ providedIn: 'root' })
export class CrmSettingsService {
  #http = inject(HttpClient);
  #url = useBaseApiUrl(PROVIDER_CRM_SETTINGS_API);

  getSettings() {
    return this.#http.get<ProviderCrmSettingsDTO>(
      `${this.#url}`
    )
  }
  updateFields(fields: ProviderCrmSettingsDTO) {
    return this.#http.put(
      `${this.#url}`,
      fields
    );
  }
}



