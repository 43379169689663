import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SprintSettingsBoardFG,
  SprintSettingsContainerForm,
  TeamSprintSettingsFG,
  TeamSprintSettingsFV,
} from '@pwiz/sprint/ts';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SettingsColumnComponent } from './settings-column/settings-column.component';
import { BasicSelectSearchComponent } from '@pwiz/base/ui';
import { MatSelectModule } from '@angular/material/select';
import { PwEnumToReadableStringPipe } from '@pwiz/infra/ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  InfoDialogComponent,
  InfoDialogTriggerDirective,
} from '@pwiz/infra/dialog';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AddTeam, ISprintTeamSettingsBase, ITeam } from '@pwiz/entity/ts';
import { pwGetFormFieldDefaultOptions } from '@pwiz/infra/material-defaults/ts';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';

interface ITableRow {
  team: string;
  form: FormGroup;
}

@Component({
  selector: 'pw-sprint-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    SettingsColumnComponent,
    BasicSelectSearchComponent,
    MatSelectModule,
    PwEnumToReadableStringPipe,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    CdkConnectedOverlay,
    InfoDialogComponent,
    CdkOverlayOrigin,
    InfoDialogTriggerDirective,
    MatTableModule,
  ],
  templateUrl: './sprint-settings-form.component.html',
  styleUrls: ['./sprint-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [provideParentControl()],
  providers: [pwGetFormFieldDefaultOptions({ subscriptSizing: 'dynamic' })],
})
export class SprintSettingsFormComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #fb = inject(FormBuilder);
  #parentControl =
    useParentControlContainer<FormGroup<SprintSettingsContainerForm>>();
  dataSource = new MatTableDataSource<ITableRow>();
  displayColumns = ['board', 'velocity', 'feature', 'bug', 'open'];
  form = this.#initForm();
  searchControl = new FormControl('');
  $canClearSearch = useCanClear(this.searchControl);
  $teams = input.required<ITeam[]>();

  value = input.required<Record<string, AddTeam<ISprintTeamSettingsBase>>>();

  constructor() {
    this.dataSource.filterPredicate = (data: ITableRow, filter) =>
      filter ? data.team.toLowerCase().includes(filter) : true;
    const $search = toSignal(this.searchControl.valueChanges, {
      initialValue: null,
    });

    effect(() => this.#setTableData(this.value(), this.$teams()));

    effect(() => (this.dataSource.filter = $search() || ''));
  }

  ngOnInit() {
    this.#parentControl.control.setControl('teams', this.form);
  }

  #setTableData(
    value: Record<string, AddTeam<ISprintTeamSettingsBase>>,
    teams: ITeam[],
  ) {
    const data: ITableRow[] = [];
    Object.entries(value)?.forEach(([teamId, settings]) => {
      const teamForm = createTeamSprintSettingsForm(this.#fb);
      this.form.addControl(teamId, teamForm);
      teamForm.setValue({
        velocity: settings.velocity,
        mix: settings.mix,
      });
      this.#onMixChange(teamForm.controls.mix);
      data.push({
        team: teams.find((team) => team.id === settings.team.id)?.name || '-',
        form: teamForm,
      });
    });
    this.dataSource.data = data;
  }

  #initForm() {
    const fb = this.#fb;
    return fb.group<SprintSettingsBoardFG>({});
  }

  #onMixChange(mixForm: TeamSprintSettingsFG['mix']) {
    mixForm.markAllAsTouched();
    this.onPercentChange(mixForm.controls.bugs, (percent, { features }) => ({
      features: this.calcPriority1(percent, features),
      bugs: percent,
      other: this.calcPriority2(percent, features),
    }));
    this.onPercentChange(mixForm.controls.other, (percent, { features }) => ({
      features: this.calcPriority1(percent, features),
      other: percent,
      bugs: this.calcPriority2(percent, features),
    }));
    this.onPercentChange(mixForm.controls.features, (percent, { bugs }) => ({
      features: percent,
      bugs: this.calcPriority1(percent, bugs),
      other: this.calcPriority2(percent, bugs),
    }));
  }

  /**
   * @description this will calculate the higher priority percent.
   * The priorities are in asc order feature-temp > bug > other.
   * The value that the user has changed will remain unchanged.
   * The system will only change the other 2 values.
   *
   * @example
   * features = 60 bugs = 20 other = 20
   * bugs was changed to 50 => features = 50 bugs = 50 other = 0
   * */
  calcPriority1(changedValue: number, original: number): number {
    const sum = changedValue + original;
    return sum > 100 ? original - (sum - 100) : original;
  }

  /**
   * @description this will calculate the higher priority percent.
   * The priorities are in asc order feature-temp > bug > other.
   * The value that the user has changed will remain unchanged.
   * The system will only change the other 2 values.
   *
   * @example
   * features = 60 bugs = 20 other = 20
   * bugs was changed to 50 => features = 50 bugs = 50 other = 0
   * */
  calcPriority2(changedValue: number, originalP1: number): number {
    return this.calcPriority1(changedValue, originalP1) !== originalP1
      ? 0
      : 100 - originalP1 - changedValue;
  }

  onPercentChange(
    control: FormControl<number | null>,
    mapper: (
      percent: number,
      mix: TeamSprintSettingsFV['mix'],
    ) => TeamSprintSettingsFV['mix'],
  ) {
    const mixForm = control.parent as TeamSprintSettingsFG['mix'];
    return control.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((value) =>
          this.filterPercentInput(control.parent as FormGroup, value),
        ),
        map((percent) =>
          mapper(percent || 0, mixForm.value as TeamSprintSettingsFV['mix']),
        ),
      )
      .subscribe((mix) => mixForm.setValue(mix, { emitEvent: false }));
  }

  filterPercentInput = (
    parent: FormGroup,
    value: number | null,
  ): value is number =>
    parent.valid && value != null && value >= 0 && value <= 100;

  clearSearch() {
    this.searchControl.setValue(null);
  }
}

export function createTeamSprintSettingsForm(fb: FormBuilder) {
  return fb.group<TeamSprintSettingsFG>({
    velocity: fb.control(null, [Validators.required]),
    mix: fb.group({
      bugs: fb.control<number | null>(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      features: fb.control<number | null>(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      other: fb.control<number | null>(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
    }),
  });
}

function useCanClear(control: FormControl<string | null>) {
  const text = toSignal(control.valueChanges, { initialValue: '' });
  return computed(() => !!text());
}
