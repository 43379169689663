import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  untracked,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { DrawerRoutingService } from '../pw-drawer-routing.service';
import { PwDrawerService } from '../pw-drawer.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-drawer',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, RouterOutlet],
  templateUrl: './pw-drawer.component.html',
  styleUrl: './pw-drawer.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerComponent {
  drawer = input.required<MatSidenav>();
  $close = computed(() => this.drawer()._closedStream);

  constructor() {
    const drawerRouting = inject(DrawerRoutingService);
    inject(PwDrawerService)
      .close$()
      .pipe(takeUntilDestroyed())
      .subscribe(() => untracked(() => this.drawer().close()));
    effect(() => this.$close().subscribe(() => drawerRouting.none()));
  }

  onRouterActive($event: any) {
    this.drawer()?.open();
  }
}
