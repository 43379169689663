import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SprintGlanceViewComponent } from '../sprint-glance-view/sprint-glance-view.component';
import { RouterLink } from '@angular/router';
import { ITeam } from '@pwiz/entity/ts';
import { SprintView } from '@pwiz/sprint/ts';

@Component({
  selector: 'pw-sprints-view',
  standalone: true,
  imports: [CommonModule, SprintGlanceViewComponent, RouterLink],
  templateUrl: './sprints-view.component.html',
  styleUrls: ['./sprints-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SprintsViewComponent {
  sprints = input.required<SprintView[] | null>();
  teams = input.required<ITeam[] | undefined>();
  teamIds = computed(() => this.teams()?.map(({ id }) => id) || []);
}
