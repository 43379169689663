import { Route } from '@angular/router';
import { importEmptyLayout } from '@pwiz/infra/ui';
import { SprintItemEditDrawerComponent } from './sprint-details-page';
import SprintSettingsGlobalDrawerComponent from './sprint-settings-drawer/sprint-settings-global-drawer.component';
import SprintSettingsSpecificDrawerComponent from './sprint-settings-drawer/sprint-settings-specific-drawer.component';

export const SPRINT_DRAWER_ROUTES: Route[] = [
  {
    path: 'sprint',
    loadComponent: importEmptyLayout,
    children: [
      {
        path: ':sprintId/edit',
        loadComponent: () => SprintItemEditDrawerComponent,
      },
    ],
  },
  {
    path: 'sprint-settings/:sprintId',
    loadComponent: () => SprintSettingsSpecificDrawerComponent,
  },
  {
    path: 'sprint-settings',
    loadComponent: () => SprintSettingsGlobalDrawerComponent,
  },
];
