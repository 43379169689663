import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { useParentControlContainer } from '@pwiz/infra/form-2/ui';
import { SlackSettingsForm, SlackSettingsFormContainer } from '@pwiz/chat/ts';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { IdValuePair } from '@pwiz/infra/ts';
import {
  ClearSearchComponent,
  RectangleSkeletonComponent,
} from '@pwiz/infra/ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { useDetectChanges } from '@pwiz/infra/hooks';

@Component({
  selector: 'pw-slack-channels',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  host: { class: 'pw-flex pw-column pw-gap-inherit' },
  styleUrl: 'slack-channels.component.scss',
  templateUrl: 'slack-channels.component.html',
  imports: [
    MatFormField,
    MatInput,
    MatAutocomplete,
    MatOption,
    MatLabel,
    FormsModule,
    ClearSearchComponent,
    MatAutocompleteTrigger,
    MatSuffix,
    ReactiveFormsModule,
    RectangleSkeletonComponent,
  ],
})
export class SlackChannelsComponent implements OnInit {
  #parentControl =
    useParentControlContainer<FormGroup<SlackSettingsFormContainer>>();
  channels = input<IdValuePair[] | null>(null);
  displayWith!: Signal<(selectedId: string) => string>;
  form = this.#initForm();
  customerFacingSearch = toSignal(
    this.form.controls.customerFacingTeamsChannel.valueChanges,
    {
      initialValue: this.form.controls.customerFacingTeamsChannel.value,
    },
  );
  mgmtSearch = toSignal(this.form.controls.mgmtChannel.valueChanges, {
    initialValue: this.form.controls.mgmtChannel.value,
  });
  stakeholderSearch = toSignal(
    this.form.controls.stakeholdersChannel.valueChanges,
    { initialValue: this.form.controls.stakeholdersChannel.value },
  );

  customerFacingChannels = computed(() =>
    this.#filterChannelList(this.customerFacingSearch()),
  );
  stakeholderChannels = computed(() =>
    this.#filterChannelList(this.stakeholderSearch()),
  );
  mgmtChannels = computed(() => this.#filterChannelList(this.mgmtSearch()));

  constructor() {
    this.displayWith = computed(() => {
      const channels = this.channels();
      return (channelId) =>
        channels?.find(({ id }) => id === channelId)?.value ?? '';
    });
  }

  ngOnInit() {
    this.#parentControl.control.setControl('slackSettings', this.form);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<SlackSettingsForm>({
      customerFacingTeamsChannel: fb.control(''),
      mgmtChannel: fb.control(''),
      stakeholdersChannel: fb.control(''),
    });
  }

  #filterChannelList(searchString: string | null) {
    if (!searchString) {
      return this.channels() || [];
    }
    const search = searchString.toLocaleLowerCase();
    return (
      this.channels()?.filter(({ value }) => {
        return value.toLocaleLowerCase().includes(search);
      }) || []
    );
  }
}
