import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import { MatIcon } from '@angular/material/icon';
import { NavigationSidebarTitleComponent } from './components/title/navigation-sidebar-title.component';

@Component({
  selector: 'pw-customer-nav',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    RouterLinkActive,
    SidebarLinkComponent,
    MatIcon,
    NavigationSidebarTitleComponent,
  ],
  styleUrl: `navigation-sidebar.component.scss`,
  template: `
    <pw-navigation-sidebar-title> Customer Impact </pw-navigation-sidebar-title>
    <ul>
      <li>
        <a routerLink="dashboard" routerLinkActive="active">
          <pw-sidebar-link pageName="Preformance">
            <i slot="icon" class="tim-icons icon-spaceship"></i>
          </pw-sidebar-link>
        </a>
      </li>
      <li>
        <a routerLink="customer-lifecycle" routerLinkActive="active">
          <pw-sidebar-link pageName="Customer lifecycle">
            <mat-icon slot="icon"> support_agent </mat-icon>
          </pw-sidebar-link>
        </a>
      </li>
    </ul>
  `,
})
export class CustomerNavComponent {}
