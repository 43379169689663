import { inject, Injectable } from '@angular/core';
import { IEpic, ITicket } from '@pwiz/entity/ts';
import { debounceTime, of, Subject, switchMap } from 'rxjs';
import { toPwCache } from '@pwiz/infra/cache/ts';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { HttpClient } from '@angular/common/http';
import { $useCurrentUser$ } from '@pwiz/user/ui-data';
import { ITEM_ENDPOINT, ITEM_ENDPOINT_MAP } from '@pwiz/item/ts';

@Injectable()
export class ItemSearchService {
  #url = useBaseApiUrl(ITEM_ENDPOINT);
  #http = inject(HttpClient);
  #searchString$ = new Subject<string | null>();

  tickets$ = this.#searchString$.pipe(
    debounceTime(300),
    switchMap((searchStr) => this.#getData(searchStr)),
  );
  userPref = $useCurrentUser$()();
  #getData(search: string | null) {
    if (!search || search.length < 3) {
      return of(null);
    }

    return this.#http
      .get<(ITicket | IEpic)[]>(`${this.#url}/${ITEM_ENDPOINT_MAP.search}`, {
        params: {
          search: search,
          ...(this.userPref?.myBoards && { boards: this.userPref.myBoards }),
          ...(this.userPref?.myTeams && { teams: this.userPref?.myTeams }),
        },
      })
      .pipe(toPwCache());
  }

  onSearchChange(searchString?: string) {
    this.#searchString$.next(searchString?.toLowerCase() || null);
  }
}
