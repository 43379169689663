<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-8bits"
  [fullScreen]="true"
><p style="color: white">Loading...</p>
</ngx-spinner>

<!--@if (isAuthenticated$ | async) {-->
<router-outlet/>
<!--}-->
