import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
} from '@angular/core';
import { TableCellDirective } from '@pwiz/infra/table';
import {
  SprintEditableTableItem,
  SprintEditableTableItemAction,
} from '@pwiz/sprint/ts';
import { NgClass } from '@angular/common';
import { $useHostClass } from '@pwiz/infra/ui';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'pw-sprint-item-select-table-action',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TableCellDirective, NgClass, MatTooltip],
  template: ` {{ $text() }} `,
  hostDirectives: [MatTooltip],
})
export class SprintItemSelectTableActionComponent {
  #tooltip = inject(MatTooltip);
  item = input.required<SprintEditableTableItem>();
  #textMap: Record<SprintEditableTableItemAction, string> = {
    add: 'Add',
    remove: 'Remove',
    change: 'Change',
  };
  #classMap: Record<SprintEditableTableItemAction, string> = {
    add: 'text-color-good',
    remove: 'text-color-danger',
    change: 'text-color-highlight',
  };
  $text = computed(() => this.#textMap[this.item().action]);
  $tooltip = computed(() => {
    const item = this.item();
    if (item.action !== 'change' || !item.changeData?.oldEffort) {
      return '';
    }
    return `From ${item.changeData.oldEffort} to ${item.sprintEffort}`;
  });

  constructor() {
    $useHostClass(() => this.#classMap[this.item().action]);
    effect(() => {
      this.#tooltip.message = this.$tooltip();
    });
  }
}
