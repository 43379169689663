import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { useEnv } from '@pwiz/infra/environment';
import { take } from 'rxjs';

@Component({
  selector: 'pw-login-redirect-page',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRedirectPageComponent {
  #authService = inject(AuthService);
  #router = inject(Router);
  constructor() {
    const { clientUrl } = useEnv();
    this.#authService
      .handleRedirectCallback(clientUrl)
      .pipe(take(1))
      .subscribe({
        // next: (state) => {
        //   console.log(`login redirect to ${state.appState.target}`);
        //   this.#router.navigate(state.appState.target);
        // },
        error: (err: unknown) => {
          // console.error(err);
          if (
            err &&
            typeof err === 'object' &&
            'message' in err &&
            err.message === 'There are no query params available for parsing.'
          ) {
            console.log(`navigating to home page`);
            this.#router.navigate(['/']);
            return;
          }
          console.log(err);
          this.#router.navigate(['./login-error']);
        },
      });
  }
}
