<pw-loader-views>

  <ng-container pwStatusLoading>
    <pw-rectangle-skeleton  heightUnits="44"/>
    <pw-rectangle-skeleton  heightUnits="44"/>
    <pw-rectangle-skeleton  heightUnits="44"/>
  </ng-container>

  @if ($confidence(); as confidence) {
    <pw-priority-confidence-overview
      class="priority-overview"
      [priority]="confidence"
      [isReadOnly]="isReadOnly()"
      [users]="users()"
      [item]="item()!"
      (updatePriority)="onUpdatePriority($event)"
    />
  }
  @if ($urgency(); as urgency) {
    <pw-priority-urgency-overview
      class="priority-overview"
      [priority]="urgency"
      [isReadOnly]="isReadOnly()"
      [users]="users()"
      (updatePriority)="onUpdatePriority($event)"
    />
  }
  @if ($impact(); as impact) {
    <div class="impact-container pw-scroll-container">
      <pw-priority-impact-overview
        class="priority-overview pw-scroll"
        [priority]="impact"
        [isReadOnly]="isReadOnly()"
        [users]="users()"
        (updatePriority)="onUpdatePriority($event)"
      />
    </div>
  }
  @if ($effort(); as effect) {
    <pw-priority-effort-overview
      class="priority-overview"
      [priority]="effect"
      [isReadOnly]="isReadOnly()"
      [users]="users()"
      [itemEstimatedCost]="0"
      (updatePriority)="onUpdatePriority($event)"
    />
  }
</pw-loader-views>


