import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { FixedPipe, PwNullableValuePipe, PwPercentPipe } from '@pwiz/infra/ui';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'pw-sprint-vs-total',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span matTooltip="Effort in sprint">
      {{ inSprint() | pwFixed: 1 | pwNullableValue }}
    </span>
    /
    <span matTooltip="Effort of open tickets for item">
      {{ remaining() | pwFixed: 1 }}
    </span>
    @if (!hidePercentCompleted()) {
      <span matTooltip="% of item effor compleated until sprint"
        >( {{ done() / (done() + remaining()) | pwPercent }})</span
      >
    }
  `,
  imports: [FixedPipe, MatTooltip, PwPercentPipe, PwNullableValuePipe],
})
export class SprintVsTotalComponent {
  inSprint = input.required<number | null>();
  total = input.required<number>();
  done = input.required<number>();
  remaining = input.required<number>();
  hidePercentCompleted = input(false, { transform: booleanAttribute });
}
