import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  pwFormComponentProvider,
  PwFormComponentV2,
  ToFormGroup,
} from '@pwiz/infra/form';
import { OnBoardingSlaForm } from '@pwiz/on-boarding/ts';
import {
  SettingsEngineerFormComponent,
  SettingsSlaFooterComponent,
  SettingsSlaFormComponent,
  SettingsSlaHeaderComponent,
} from '@pwiz/settings/ui';
import { OnBoardingService } from '@pwiz/on-boarding/ui-data';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  NextStepDirective,
  PreviousStepDirective,
  StepButtonsComponent,
} from '@pwiz/on-boarding/ui';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FormGroup } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

type IForm = ToFormGroup<OnBoardingSlaForm>;

@Component({
  selector: 'pw-sla-on-boarding',
  standalone: true,
  imports: [
    CommonModule,
    SettingsSlaFormComponent,
    SettingsEngineerFormComponent,
    MatIconModule,
    MatTooltipModule,
    SettingsSlaHeaderComponent,
    SettingsSlaFooterComponent,
    StepButtonsComponent,
    MatButtonModule,
    MatStepperModule,
    NextStepDirective,
    PreviousStepDirective,
  ],
  templateUrl: './sla-on-boarding.component.html',
  styleUrls: ['./sla-on-boarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [pwFormComponentProvider(SlaOnBoardingComponent)],
})
export class SlaOnBoardingComponent
  implements PwFormComponentV2<IForm>, OnInit
{
  #onBoardingService = inject(OnBoardingService);
  form = new FormGroup<IForm>({} as IForm);
  $value = toSignal(this.#onBoardingService.getSla$());
  name = 'priority';

  @ViewChild(PwFormComponentV2, { static: true })
  formComponent!: PwFormComponentV2<IForm>;

  ngOnInit() {
    this.form = this.formComponent.form;
  }

  runCD(): void {
    this.formComponent.runCD?.();
  }

  validate(): void {
    this.formComponent.validate?.();
  }

  onNext() {
    console.log(this.form.value);
    this.#onBoardingService.saveSla(this.form.value as OnBoardingSlaForm);
  }
}
