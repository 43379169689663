import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EFFORT_ESTIMATION_TOOLTIP } from '@pwiz/settings/ts';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'pw-story-points-mapping-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './story-points-mapping-header.component.html',
  styleUrls: [
    './story-points-mapping-header.component.scss',
    '../shared/header-styles.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryPointsMappingHeaderComponent {
  effortEstimationTooltip = EFFORT_ESTIMATION_TOOLTIP;
}
