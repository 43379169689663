import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { AiIconComponent } from '@pwiz/infra/icons/ui';

/**
 * @slot: search, left, right
 */
@Component({
  selector: 'pw-sprint-edit-items-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-scroll-container',
  },
  styles: `
    :host {
      padding: var(--pw-dialg-padding-block) 0;
    }

    .subheader {
      display: flex;
      align-items: center;
      margin-bottom: var(--pw-distance-4);
      min-height: var(--pw-distance-12);
    }
  `,
  template: `
    <div class="search">
      <ng-content select="[slot=search]" />
    </div>
    <section class="pw-flex pw-gap-l" style="height: var(--pw-distance-80);">
      <div class="pw-flex-1 pw-scroll-container">
        <div class="pw-gap subheader">
          <h6>Manual</h6>
          <button mat-stroked-button (click)="addItem.emit()">Add Item</button>
          <button mat-stroked-button (click)="removeItem.emit()">
            Remove Item
          </button>
        </div>
        <ng-content select="[slot=left]" />
      </div>
      <div class="pw-flex-1 pw-scroll-container">
        <div class="subheader">
          <h6>
            Ai Sprint Completion <pw-ai-icon style="font-size: 1.5rem;" />
          </h6>
        </div>
        <ng-content select="[slot=right]" />
      </div>
    </section>
  `,
  imports: [MatButton, AiIconComponent],
})
export class SprintEditItemsLayoutComponent {
  addItem = output<void>();
  removeItem = output<void>();
}
