<mat-form-field class="pw-width-100p" subscriptSizing="dynamic">
  <mat-label>Search items by ID or Summary</mat-label>
  <input
    type="text"
    placeholder="Search by item ID or item title"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    hideSingleSelectionIndicator="true"
    panelWidth="25rem"
    (optionSelected)="onOptionSelected($event)"
  >
    @if (items(); as items) {
      <!--      Can't switch to the status-switch-case component because then the autocomplete selection isn't working well -->
      @switch (loadingStatus()) {
        @case ('none') {}
        @case ('loading') {
          <mat-option [disabled]="true">
            <pw-sprint-item-select-row loading />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-sprint-item-select-row loading />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-sprint-item-select-row loading />
          </mat-option>
          <mat-option [disabled]="true">
            <pw-sprint-item-select-row loading />
          </mat-option>
        }
        @case ('success') {
          @for (item of items; track item) {
            <mat-option [value]="item">
              <pw-sprint-item-select-row [item]="item" />
            </mat-option>
          }
        }
        @case ('error') {
          <mat-option>error</mat-option>
        }
      }
    }
    <!--    @else if ($tooShortSearch()) {-->
    <!--      <mat-option disabled>-->
    <!--        Search should be longer than 3 characters</mat-option-->
    <!--      >-->
    <!--    }-->
  </mat-autocomplete>
</mat-form-field>
